/* istanbul ignore file */
import OpenStore from './OpenCloseStore/OpenForBusiness/OpenForBusiness'
import CloseStore from './OpenCloseStore/CloseForBusiness/CloseForBusiness'
import Restocking from './Restocking/Restocking'
import TargetShelves from './TargetShelves/TargetShelves'
import Expiration from './Expiration/Expiration'
import Custom from './Custom/Custom'
import CustomWithPictures from './CustomWithPictures/CustomWithPictures'
import Audit from './Audit/Audit'
import Picking from './Picking/Picking'
import Price from './Price/Price'
import StockOut from './StockOut/StockOut'
import Return from './Return/Return'
import Test from './Test/Task.Test'
import RestockingWarehouse from './RestockingWarehouse/RestockingWarehouse'
import WarehouRestockingFromStore from './WarehouseRestockingFromStore/WarehouseRestockingFromStore'
import Question from './Question/Question'
import AuditWarehouse from './AuditWarehouse/AuditWarehouse'

import AssignmentLateOutlinedIcon from '@mui/icons-material/AssignmentLateOutlined'
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined'
import AssignmentReturnedOutlinedIcon from '@mui/icons-material/AssignmentReturnedOutlined'
import AssignmentReturnOutlinedIcon from '@mui/icons-material/AssignmentReturnOutlined'
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined'
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined'
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined'
import PriceCheckOutlinedIcon from '@mui/icons-material/PriceCheckOutlined'
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined'
import WarehouseOutlinedIcon from '@mui/icons-material/WarehouseOutlined'
import QuestionMarkOutlinedIcon from '@mui/icons-material/QuestionMarkOutlined'

export const TASKS_CONFIGURATION = {
  TEST: {
    action: 'test',
    Component: Test,
    title: 'Task title',
    subtitle: 'Task subtitle',
    ariaLabel: 'Test task',
  },

  OPEN_FOR_BUSINESS: {
    action: 'open_for_business',
    Component: OpenStore,
    title: 'OPEN_STORE_TITLE',
    subtitle: 'OPEN_STORE_SUBTITLE',
    ariaLabel: 'Open Store task',
    Icon: StoreOutlinedIcon,
  },

  CLOSE_FOR_BUSINESS: {
    action: 'close_for_business',
    Component: CloseStore,
    title: 'CLOSE_STORE_TITLE',
    subtitle: 'CLOSE_STORE_SUBTITLE',
    ariaLabel: 'Close Store task',
    Icon: StoreOutlinedIcon,
  },

  RESTOCKING: {
    action: 'restocking',
    Component: Restocking,
    title: 'RESTOCKING_TITLE',
    subtitle: 'RESTOCKING_SUBTITLE',
    ariaLabel: 'Restocking task',
    coolDownDelay:
      parseInt(process.env.REACT_APP_COOLDOWN_RESTOCKING_DELAY || '0', 10) || 0,
    Icon: AssignmentReturnedOutlinedIcon,
  },

  TARGET_SHELVES: {
    action: 'target_shelves',
    Component: TargetShelves,
    title: 'TARGET_SHELVES_TITLE',
    subtitle: 'TARGET_SHELVES_SUBTITLE',
    ariaLabel: 'Target Shelves task',
    Icon: AssignmentLateOutlinedIcon,
  },

  EXPIRATION: {
    action: 'expiration',
    Component: Expiration,
    title: 'EXPIRATION_TITLE',
    subtitle: 'EXPIRATION_SUBTITLE',
    ariaLabel: 'Expiration task',
    Icon: DateRangeOutlinedIcon,
  },

  CUSTOM: {
    action: 'custom',
    Component: Custom,
    title: '',
    ariaLabel: 'Custom task',
    Icon: AssignmentOutlinedIcon,
    coolDownDelay: 2000,
  },

  CUSTOM_WITH_PICTURES: {
    action: 'custom_with_pictures',
    Component: CustomWithPictures,
    title: 'CUSTOM_WITH_PICTURE_TITLE',
    ariaLabel: 'Custom task with pictures',
    Icon: CameraAltOutlinedIcon,
  },

  AUDIT: {
    action: 'audit',
    Component: Audit,
    title: 'AUDIT_TITLE',
    subtitle: 'AUDIT_SUBTITLE',
    ariaLabel: 'Audit task',
    Icon: AssignmentTurnedInOutlinedIcon,
  },

  PICKING: {
    action: 'picking',
    Component: Picking,
    title: 'PICKING_TITLE',
    subtitle: 'PICKING_SUBTITLE',
    ariaLabel: 'Picking task',
    Icon: WarehouseOutlinedIcon,
  },

  PRICE: {
    action: 'price',
    Component: Price,
    title: 'PRICE_TITLE',
    subtitle: 'PRICE_SUBTITLE',
    ariaLabel: 'Price task',
    Icon: PriceCheckOutlinedIcon,
  },

  STOCK_OUT: {
    action: 'stock_out',
    Component: StockOut,
    title: 'STOCK_OUT_TITLE',
    subtitle: 'STOCK_OUT_SUBTITLE',
    ariaLabel: 'Stock out task',
    Icon: AssignmentReturnedOutlinedIcon,
    coolDownDelay: 15000,
  },

  AUDIT_WAREHOUSE_SHELF: {
    action: 'audit_warehouse_shelf',
    Component: AuditWarehouse,
    title: 'AUDIT_WAREHOUSE_SHELF_TITLE',
    subtitle: 'AUDIT_WAREHOUSE_SHELF_SUBTITLE',
    ariaLabel: 'Audit warehouse shelf task',
    Icon: AssignmentTurnedInOutlinedIcon,
  },

  RETURN_TASK: {
    action: 'return_task',
    Component: Return,
    title: 'RETURN_TITLE',
    subtitle: 'RETURN_SUBTITLE',
    ariaLabel: 'Return task',
    Icon: AssignmentReturnedOutlinedIcon,
  },

  RESTOCKING_WAREHOUSE: {
    action: 'restocking_warehouse',
    Component: RestockingWarehouse,
    title: 'RESTOCKING_WAREHOUSE_TITLE',
    subtitle: 'RESTOCKING_WAREHOUSE_SUBTITLE',
    ariaLabel: 'restocking warehouse task',
    Icon: AssignmentReturnOutlinedIcon,
  },

  WAREHOUSE_RESTOCKING_FROM_STORE: {
    action: 'warehouse_restocking_from_store',
    Component: WarehouRestockingFromStore,
    title: 'WAREHOUSE_RESTOCKING_FROM_STORE_TITLE',
    subtitle: 'WAREHOUSE_RESTOCKING_FROM_STORE_SUBTITLE',
    ariaLabel: 'Restocking from store warehouse task',
    Icon: AssignmentReturnOutlinedIcon,
  },

  QUESTION: {
    action: 'question',
    Component: Question,
    title: 'QUESTION_TITLE',
    ariaLabel: 'question task',
    Icon: QuestionMarkOutlinedIcon,
  },
}

export const STANDARD_SCREENSHOT_DIMENSIONS = { width: 1920, height: 1080 }
