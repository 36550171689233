import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { Box, Button, IconButton, Dialog } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import OpenInFullOutlinedIcon from '@mui/icons-material/OpenInFullOutlined'

import Header from '../../Layout/Header'
import MarkdownViewer from '../../components/MarkdownViewer'

const HEADER_HEIGHT = '64px'

function Custom({ setToCompletable, payload: { customBody } }) {
  useEffect(() => {
    setToCompletable()
  }, [])

  const [enlarge, setEnlarge] = useState(false)

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      width="100%"
      position="relative"
    >
      <Box flex={1}>
        <IconButton
          data-testid="custom-open-fullscreen-body-button"
          sx={{
            position: 'absolute',
            right: 0,
            top: { xs: '-37px', md: '-42px' },
          }}
          onClick={() => setEnlarge(!enlarge)}
        >
          <OpenInFullOutlinedIcon />
        </IconButton>
        <MarkdownViewer data-testid="custom-body" text={customBody} />

        <Dialog fullScreen open={enlarge}>
          <Box role="app-header" height={HEADER_HEIGHT}>
            <Header
              height={HEADER_HEIGHT}
              actions={
                <Button
                  data-testid="custom-close-fullscreen-body-button"
                  sx={{
                    color: 'white',
                  }}
                  onClick={() => setEnlarge(false)}
                  endIcon={<CloseIcon />}
                >
                  Fermer
                </Button>
              }
            />
          </Box>
          <MarkdownViewer text={customBody} />
        </Dialog>
      </Box>
    </Box>
  )
}

Custom.propTypes = {
  setToCompletable: PropTypes.func,
  payload: PropTypes.object,
}

export default Custom
