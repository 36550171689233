export const test = {
  action: 'test',
  comment: 'Task comment',
  index: 0,
  state: 'created',
  payload: {},
  store_id: '0000000000000000STORE_ID',
  read_only: false,
}

export const makeTest = ({ _id, index, state } = {}) => ({
  ...test,
  _id,
  index: index || test.index,
  state: state || test.state,
})
