import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import map from 'lodash/map'
import Webcam from 'react-webcam'

import {
  Box,
  ButtonBase,
  Fab,
  TextField,
  Typography,
  InputAdornment,
  Button,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import HorizontalSplitIcon from '@mui/icons-material/HorizontalSplit'
import ArrowBack from '@mui/icons-material/ArrowBack'

import ScannedProduct from './ScannedProduct'
import { useStockOutTask } from './hooks'
import barcode from '../../assets/barcode.png'

const CONTINUOUS_SCAN_INTERVAL = 1000

const StockOut = ({
  setToCompletable,
  continuousScanInterval = CONTINUOUS_SCAN_INTERVAL,
}) => {
  const { t } = useTranslation('STOCK_OUT')
  const classes = useStyles()
  const [productCode, setProductCode] = useState('')
  const {
    webcamRef,
    products,
    removeProduct,
    addProductByBarCode,
    scanFailure,
    scanInProgress,
    authenticationInProgress,
    isScanMode,
    setScanMode,
  } = useStockOutTask({ continuousScanInterval })

  useEffect(() => {
    setProductCode('')
    if (products.length) {
      setToCompletable({ productIds: map(products, '_id') })
    } else {
      setToCompletable({ productIds: [] })
    }
  }, [products])

  if (isScanMode) {
    return (
      <section className={classes.scanModeContainer}>
        <div className={classes.webCamContainer}>
          <Webcam
            audio={false}
            width="75%"
            videoConstraints={{
              facingMode: 'environment',
              aspectRatio: 3 / 2,
            }}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            screenshotQuality={1}
          />
        </div>

        <footer className={classes.scannedProductFooter}>
          {!!products.length &&
            products.map((product) => (
              <ScannedProduct
                key={product._id}
                product={product}
                mode="card"
                removeProduct={removeProduct}
              />
            ))}
        </footer>

        <Fab
          size="large"
          data-testid="stockout-close-scan-button"
          className={classes.exitScanModeButton}
          color="primary"
          onClick={() => setScanMode(false)}
        >
          <ArrowBack />
        </Fab>
      </section>
    )
  }

  return (
    <Box display="flex" height="100%" overflow="hidden" minWidth={50}>
      <Box
        flex="1"
        display="flex"
        flexDirection="column"
        gap={2}
        overflow="hidden"
        justifyContent="center"
      >
        <ButtonBase
          data-testid="stockout-scan-button"
          role="scan-button"
          className={classes.scanButton}
          onClick={() => setScanMode(true)}
        >
          <Typography variant="h5">{t('SCAN_BUTTON_LABEL')}</Typography>
          <img src={barcode} alt="barcode" width={'50%'} />
        </ButtonBase>

        <Box display="flex" gap={1}>
          <TextField
            fullWidth
            label={t('PRODUCT_CODE_LABEL')}
            onChange={(e) => setProductCode(e.target.value)}
            helperText={scanFailure}
            error={!!scanFailure}
            margin="none"
            value={productCode}
            InputProps={{
              inputProps: {
                'data-testid': 'stockout-product-code-input',
              },
              startAdornment: (
                <InputAdornment position="start">
                  <HorizontalSplitIcon sx={{ transform: 'rotate(90deg)' }} />
                </InputAdornment>
              ),
            }}
          />
          <Button
            data-testid="stockout-add-product-button"
            variant="contained"
            color="primary"
            size="large"
            disabled={scanInProgress || authenticationInProgress}
            onClick={() => addProductByBarCode(productCode)}
          >
            {t('ADD_PRODUCT_BUTTON')}
          </Button>
        </Box>
      </Box>
      <Box flex="1" display="flex" minWidth={250}>
        <Box
          flex="1 1 0"
          display="flex"
          position="relative"
          flexDirection="column"
        >
          <Box
            sx={{
              margin: 'auto',
              maxHeight: '100%',
              maxWidth: '100%',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              overflow: 'auto',
            }}
          >
            {!!products.length &&
              products.map((product) => (
                <ScannedProduct
                  key={product._id}
                  product={product}
                  mode="line"
                  removeProduct={removeProduct}
                />
              ))}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

StockOut.propTypes = {
  storeId: PropTypes.string,
  token: PropTypes.string,
  setToCompletable: PropTypes.func,
  continuousScanInterval: PropTypes.number,
}

const useStyles = makeStyles((theme) => ({
  scanButton: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 20,
    backgroundColor: theme.palette.grey[200],
    padding: '8%',
  },
  exitScanModeButton: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
  },
  scanModeContainer: {
    position: 'absolute',
    zIndex: 100,
    backgroundColor: theme.palette.common.black,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  webCamContainer: {
    boxSizing: 'border-box',
    padding: theme.spacing(4),
    height: '70%',
    display: 'flex',
    alignItems: 'center',
  },
  scannedProductFooter: {
    boxSizing: 'border-box',
    height: '30%',
    overflowX: 'scroll',
    padding: `${theme.spacing(2)} ${theme.spacing(1)}`,
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
  },
}))

export default StockOut
