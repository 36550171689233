import React from 'react'
import PropTypes from 'prop-types'

import { SwipeableDrawer, Paper } from '@mui/material'

function TaskgroupTestCommentDrawer({ isOpen, onClose, onOpen }) {
  return (
    <SwipeableDrawer
      anchor={'bottom'}
      open={isOpen}
      onOpen={onOpen}
      onClose={onClose}
    >
      <Paper>TaskGroup Test Comment Drawer</Paper>
    </SwipeableDrawer>
  )
}

TaskgroupTestCommentDrawer.defaultProps = {
  onOpen: () => {},
}

TaskgroupTestCommentDrawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.string,
  disabled: PropTypes.bool,
}

export default TaskgroupTestCommentDrawer
