import React, { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import clsx from 'clsx'

import PropTypes from 'prop-types'

import { makeStyles } from '@mui/styles'
import MarkdownViewer from '../../components/MarkdownViewer'

import { Box, Button, IconButton } from '@mui/material'
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera'
import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'

import { postImage } from '../../TaskGroup/taskGroup.service'
import WebCamera from '../../components/WebCamera/WebCamera'
import shutterActionSound from '../../assets/sounds/shutter-01.wav'

export const TAKE_PICTURE_AUDIO_SOUND = new Audio(shutterActionSound)

function CustomWithPictures({
  setToCompletable,
  payload: { customBody, imageUrls },
}) {
  const { taskgroupId, secret } = useParams()
  const [pictureUrls, setPictureUrls] = useState(imageUrls)
  const [isCameraActive, setCameraVisibility] = useState(false)
  const imageCaptureRef = useRef(null)
  const classes = useStyles()

  useEffect(() => {
    if (pictureUrls.length) {
      setToCompletable({ image_urls: pictureUrls })
    }
  }, [pictureUrls])

  // T-2114 no pictures need to be taken to do next because some browser struggle to take pictures (should be removed when taken picture are fixed)
  useEffect(() => {
    setToCompletable({ image_urls: pictureUrls })
  }, [])

  const capture = async () => {
    const base64Image = await imageCaptureRef?.current?.takeBase64Photo()
    await TAKE_PICTURE_AUDIO_SOUND.play()

    if (base64Image) {
      const { image_url: imageUrl } = await postImage(
        taskgroupId,
        base64Image,
        `custom_with_pictures_${Date.now()}.jpg`,
        secret,
      )

      setPictureUrls((pictureUrls) => [...pictureUrls, imageUrl])
    }
  }

  const deletePicture = (index) => {
    setPictureUrls((pictureUrls) => {
      const _pictureUrls = [...pictureUrls]

      _pictureUrls.splice(index, 1)

      return _pictureUrls
    })
  }

  if (isCameraActive) {
    return (
      <div className={classes.cameraContainer}>
        <div className={classes.webcamContainer}>
          <WebCamera height="100%" imageCaptureRef={imageCaptureRef} />
          <div className={classes.takePictureButton}>
            <div
              className={classes.captureButton}
              data-testid="custom-picture-capture_image_button"
              onClick={() => capture()}
            />
          </div>
        </div>
        <div className={classes.previewContainer}>
          <div className={classes.previewPictureContainer}>
            <div className={classes.previewVerticalScrollContainer}>
              {pictureUrls.map((pictureUrl, index) => (
                <div className={classes.imageContainer} key={index}>
                  <div>
                    <img height={85} src={pictureUrl} />
                  </div>
                  <div className={classes.deleteContainer}>
                    <Button
                      variant="outlined"
                      color="secondary"
                      role="deletion"
                      className={classes.deletePictureButton}
                      onClick={() => deletePicture(index)}
                    >
                      <ClearIcon />
                    </Button>
                  </div>
                </div>
              ))}
            </div>

            <div
              className={clsx([classes.actionContainer, classes.checkButton])}
            >
              <Button
                className={classes.button}
                data-testid="custom-picture-close_camera_button"
                color="primary"
                variant="contained"
                onClick={() => setCameraVisibility(false)}
              >
                <CheckIcon />
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <Box display="flex" height="100%" overflow="hidden" flexDirection="column">
      {customBody ? <MarkdownViewer text={customBody} /> : null}

      <Box display="flex" justifyContent="center">
        <IconButton
          data-testid="custom-picture-open-camera-button"
          className={classes.takePictureButton}
          onClick={() => setCameraVisibility(true)}
        >
          <PhotoCameraIcon className={classes.icon} />
        </IconButton>
      </Box>
      <div className={classes.verticalScrollableContainer}>
        {pictureUrls.map((pictureUrl, index) => (
          <div key={index} className={classes.pictureGridItem}>
            <img className={classes.pictureElement} src={pictureUrl} />
            <Button
              data-testid="custom-picture-delete-button"
              variant="contained"
              color="secondary"
              role="deletion"
              classes={{
                root: classes.pictureGridItemCross,
                disabled: 'disabled',
              }}
              onClick={() => deletePicture(index)}
            >
              <ClearIcon />
            </Button>
          </div>
        ))}
      </div>
    </Box>
  )
}

const useStyles = makeStyles(() => ({
  pictureElement: {
    height: '100%',
  },
  webcamContainer: {
    display: 'flex',
    padding: '20px',
    position: 'relative',
  },
  homePicturesContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  previewContainer: {
    width: '99%',
    overflow: 'scroll',
    overflowX: 'auto',
  },
  previewPictureContainer: {
    display: 'flex',
    gap: '15px',
    alignItems: 'center',
  },
  previewVerticalScrollContainer: {
    display: 'flex',
    flex: '1',
    width: '100%',
    gap: '5px',
    padding: '20px',
    overflowX: 'auto',
    height: '140px',
  },
  actionContainer: {
    flex: '0 0 25px',
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    height: '100px',
  },
  imageContainer: {
    height: '85px',
  },
  deleteContainer: {
    textAlign: 'center',
    marginTop: '5px',
  },
  verticalScrollableContainer: {
    display: 'flex',
    flex: '1 1 auto',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '15px',
    padding: '20px',
    overflowY: 'scroll',
    height: 0,
  },
  pictureGridItem: {
    height: '150px',
    position: 'relative',
  },
  pictureGridItemCross: {
    position: 'absolute',
    right: '-8px',
    top: '-8px',
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 'auto',
  },
  takePictureButton: {
    width: 'fit-content',
    height: 'fit-content',
    margin: 'auto',
    padding: '10px 15px',
    borderRadius: '10px',
  },
  captureButton: {
    height: '75px',
    width: '75px',
    backgroundColor: 'white',
    borderRadius: '50px',
    position: 'relative',
    '&::before': {
      content: `''`,
      position: 'absolute',
      left: '5px',
      top: '5px',
      width: '61px',
      height: '61px',
      border: '2px solid transparent',
      borderColor: 'black',
      borderRadius: '50%',
    },
  },
  validateButton: { flex: '0 0 25px' },
  deletePictureButton: {
    width: 'fit-content',
    height: 'fit-content',
    margin: 'auto',
    borderRadius: '10px',
    marginRight: '10px',
  },
  icon: {
    width: '150px',
    height: '150px',
    color: 'dimgray',
  },
  cameraContainer: {
    display: 'grid',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 10,
    bottom: 0,
    background: 'black',
  },
  md: {
    gridArea: '1 / 1 / span 2 / 1',

    '& p': {
      marginBlockStart: '0.5em',
      marginBlockEnd: '0.5em',
      marginInlineStart: '0px',
      marginInlineEnd: '0px',
    },
  },
}))

CustomWithPictures.propTypes = {
  setToCompletable: PropTypes.func,
  payload: PropTypes.object,
}

export default CustomWithPictures
