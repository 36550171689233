/* istanbul ignore file */
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

//We should use a json files to store translations ressources
export const resources = {
  fr: {
    HOME: {
      GREETING: 'Select a taskgroup',
    },
    HEADER: {
      TOURSETS_TITLE: 'TOURNÉE {{stores}}',
      WAREHOUSE_TASKS_TITLE: 'Audit entrepôt',
      MANUFACTURING_TITLE: 'Production {{stores}}',
      WAREHOUSE_RESTOCKING_TITLE: 'Réception commande fournisseur',
      WAREHOUSE_RESTOCKING_FROM_STORE_TITLE: 'Réception produits des magains',
    },
    DOOR_STATUS: {
      DOOR_STATUS: 'Accès maintenance',
    },
    MENU: {
      CLOSE_MENU: 'Fermer le menu',
      TO_DO: 'A faire',
      TERMINATED: 'Terminé',
      VALIDATED: 'Validé',
      QUANTITY_CONFIRMED: `Qté confirmé: {{quantity}}`,
      QUANTITY_EXPECTED: 'Qté attendu: {{quantity}}',
      QUANTITY_CONFIRMED_EXPECTED: `Qté confirmé: {{confirmedQuantity}} - Qté attendu: {{expectedQuantity}}`,
      QUANTITY_CONFIRMED_INITIAL: `Qté confirmé: {{confirmedQuantity}} - Qté initial: {{expectedQuantity}}`,
      WITH_DELTA: 'Avec écart',
      WITHOUT_DELTA: 'Sans écart',
    },
    NAVIGATION: {
      COMPLETE_BUTTON: 'Terminer',
      NEXT_BUTTON: 'Suivant',
      PREVIOUS_BUTTON: 'Précédent',
      COMPLETE_TASKGROUP_AUDIT_CONFIRM_TITLE: `Vous allez clôturer l'audit`,
      COMPLETE_TASKGROUP_RECEPTION_CONFIRM_TITLE:
        'Vous allez clôturer la réception',
      COMPLETE_TASKGROUP_CONFIRM_MESSAGE: `Etes-vous sûr d’avoir terminé ?`,
      CANCEL_TASKGROUP_BUTTON: 'Sauvegarder et quitter',
    },
    TASK_GROUP_ERROR: {
      HIDE_DETAILS: 'Moins de détails',
      RETRY: 'Réessayez',
      SHOW_DETAILS: 'Plus de détails',
      TITLE: 'Ouups',
      ARCHIVED: `Ce lien est archivé, Tu ne peux plus effectuer d'action`,
      CONFLICT:
        'La tâche précédente n\'a pas été validée (tu as probablement ouvert la tournée sur plusieurs onglets).\nClique "RÉESSAYEZ" pour revenir sur la dernière tâche puis `PRÉCÉDENT".\nEnfin assure toi de bien valider la tâche avant de continuer avec "SUIVANT".',
    },
    TASKGROUP_HELPER_DRAWER: {
      HELPER_TEXT_ONE:
        'Tu souhaites faire remonter un problème d’approvisionnement, d’étiquettes, de prix ou d’affichage écran? Accède au ',
      CHAT_SUPPORT: 'chat de support.',
      HELPER_TEXT_TWO:
        'Tu souhaites faire remonter un problème urgent, tu ne comprends pas ce qu’il faut faire, le magasin ne s’ouvre ou ne se ferme pas? Appelle le ',
      HELP_TEXT_THREE: ' pour obtenir immédiatement de l’aide',
    },
    TASKGROUP_COMMENT_DRAWER: {
      TEXTFIELD_LABEL: 'Commentaire',
      COMMENT: 'Commentaire',
      ADD_PHOTO: 'Ajouter photo',
      QUANTITY: 'Quantité',
      IN_EXCESS_QUANTITY: 'Nombre d’unités en trop',
      FULL_SHELF_PRODUCT_COUNT: 'Nombre d’unités dans l’étagère pleine',
      EXPIRATION_DATE: 'Expiration',
      TEXTFIELD_PLACEHOLDER: 'N’hésite pas à fournir des détails',
    },
    TASK: {
      AUDIT_SUBTITLE: 'Vérifie et confirme la quantité totale en rayon',
      AUDIT_TITLE: 'Inventaire',
      AUDIT_WAREHOUSE_SHELF_TITLE: 'Audit étagère',
      AUDIT_WAREHOUSE_SHELF_SUBTITLE: 'Vérification des quantités en stock',
      CLOSE_STORE_SUBTITLE: 'Ouvrir le magasin pour maintenance',
      CLOSE_STORE_TITLE: 'Ouvrir',
      EXPIRATION_SUBTITLE:
        'Confirme les quantités expirées et retire les produits des rayons',
      EXPIRATION_TITLE: 'Produit périmé',
      CUSTOM_WITH_PICTURE_TITLE: 'Safari Photos',
      CUSTOM_WITH_PICTURE_SUBTITLE:
        'Assure toi de prendre en photo chaque section du magasin de gauche à droite',
      NO_TASK_ERROR_MESSAGE: 'Aucune tache',
      OPEN_STORE_SUBTITLE: 'Referme le magasin',
      OPEN_STORE_TITLE: 'Fermer',
      RESTOCKING_SUBTITLE: `Pense à mettre les nouveaux produits derrière \n Aide toi des étiquettes prix pour t’assurer de l’emplacement d’un produit`,
      RESTOCKING_TITLE: 'Mise en rayon',
      UNKNOWN_TASK_ACTION_ERROR_MESSAGE: 'Tache inconnue',
      PICKING_TITLE: 'Picking',
      PICKING_SUBTITLE: 'Préparation des commandes magasin',
      PRICE_TITLE: 'Étiquettes prix',
      PRICE_SUBTITLE: 'Assure toi de bien avoir changé les étiquettes prix',
      TARGET_SHELVES_TITLE: 'CHANGEMENT DE MERCHANDISING',
      TARGET_SHELVES_SUBTITLE:
        "CHANGEMENT DE MERCHANDISING TERMINÉ ? N'HÉSITE PAS À VÉRIFIER ! SINON PASSE À LA TÂCHE SUIVANTE !",
      STOCK_OUT_TITLE: 'Rupture visuelle',
      STOCK_OUT_SUBTITLE:
        'Scanne les étiquettes produits de toutes les étagères vides',
      RETURN_SUBTITLE: "Retour vers l'entrepôt",
      RETURN_TITLE: 'Retire les produits du magasin',
      RESTOCKING_WAREHOUSE_TITLE: 'Réception article',
      RESTOCKING_WAREHOUSE_SUBTITLE: 'Enregistrement des commandes reçues',
      WAREHOUSE_RESTOCKING_FROM_STORE_TITLE: 'Réception article',
      WAREHOUSE_RESTOCKING_FROM_STORE_SUBTITLE:
        'Enregistrement des retours de magasin',
    },
    TASK_ERROR: {
      HIDE_DETAILS: 'Moins de détails',
      RETRY: 'Réessayez',
      SHOW_DETAILS: 'Plus de détails',
    },
    OPEN_STORE: {
      CLOSED_FOR_BUSINESS_CHIP_STATUS: 'OUVERT',
      DOORS_LOCKED_CHIP_STATUS: 'VERROUILLÉES',
      DOORS_UNLOCKED_CHIP_STATUS: 'DÉVERROUILLÉES',
      DOORS: 'Portes',
      LAST_UPDATED: 'Dernière mise à jour',
      OPEN_FOR_BUSINESS_ACTION: 'Fermer le magasin',
      OPEN_FOR_BUSINESS_CHIP_STATUS: 'FERMÉ',
      STORE: 'Magasin',
    },
    CLOSE_STORE: {
      CLOSE_FOR_BUSINESS_ACTION: 'Ouvrir le magasin',
      CLOSED_FOR_BUSINESS_CHIP_STATUS: 'OUVERT',
      DOORS_LOCKED_CHIP_STATUS: 'VERROUILLÉES',
      DOORS_UNLOCKED_CHIP_STATUS: 'DÉVERROUILLÉES',
      DOORS: 'Portes',
      LAST_UPDATED: 'Dernière mise à jour',
      OPEN_FOR_BUSINESS_CHIP_STATUS: 'FERMÉ',
      STORE: 'Magasin',
      ISSUE_WITH_STORE: '😵 Oups il y a un problème avec le magasin',
      ASK_CONFIRMATION_FOR_STORE_EMPTINESS:
        'Peux-tu confirmer qu’il n’y a aucun client dans le magasin?',
      CONFIRM_STORE_EMPTINESS: '👍 Je confirme, le magasin est vide',
    },
    RESTOCKING: {
      DONE: 'Terminer le restocking',
      RESTOCKING: 'Réapprovisionnement',
    },
    EXPIRATION: {
      DONE: 'Valider',
      COUNTER_LABEL: 'Quantité expirée',
      NOTHING_TO_DO:
        "Il n'y a rien à faire sur cette tâche, tu peux passer directement à la tâche suivante.",
    },
    CUSTOM: {
      DONE: 'Valider',
    },
    PICKING: {
      COUNTER_LABEL: 'Quantité prise',
      DLC_FIRST_PICK_DATE_LABEL:
        'A commencer par la DLC du {{firstProductExpirationDate}}',
    },
    AUDIT: {
      DONE: 'Valider',
      COUNTER_LABEL: 'Quantité',
      NOTHING_TO_DO:
        "Il n'y a rien à faire sur cette tâche, tu peux passer directement à la tâche suivante.",
    },
    RETURN: {
      DONE: 'Valider',
      COUNTER_LABEL: 'Quantité',
      NOTHING_TO_DO:
        "Il n'y a rien à faire sur cette tâche, tu peux passer directement à la tâche suivante.",
    },
    CONGRATULATION: {
      CONGRATULATION: '🌟 Terminé 🌟',
      DONE: 'Terminer',
      TITLE: 'Operator Tasks',
    },
    STOCK_OUT: {
      NO_PRODUCT_FOUND: 'Aucun produit trouvé',
      PRODUCT_ALREADY_LISTED: 'Produit déjà listé',
      SCAN_BUTTON_LABEL: 'Scan',
      ADD_PRODUCT_BUTTON: 'Valider',
      PRODUCT_CODE_LABEL: 'Code produit',
    },
    AUDIT_WAREHOUSE_SHELF: {
      ADD_EXPIRATION_LABEL: 'Ajouter',
      MIN_DATE_MESSAGE: 'La date ne peut pas être antérieure au {{date}}',
      QUANTITY: 'Quantité',
      INITIAL_QUANTITY_LABEL: 'Qté initial: {{quantity}}',
      RESERVE_QUANTITY_LABEL: 'Qté en reserve : ',
      COUNTER_LABEL: 'Nouvelle qté',
      FULL_SHELF_BUTTON: 'Étagere pleine',
      EXPIRATION_SECTION_TITLE: 'Expirations',
      NO_EXPIRATION_SECTION_TITLE: "Le produit n'a pas d'expirations",
      CLOSE_WAREHOUSE_MAP: 'Fermer la carte',
    },
    RESTOCKING_WAREHOUSE: {
      MIN_DATE_MESSAGE: 'La date ne peut etre antérieur au {{date}}',
      QUANTITY_PER_PACKAGE_LABEL: 'Quantité par colis: {{quantity}}',
      EXPIRATION_SECTION_TITLE: 'Expirations',
      NO_EXPIRATION_SECTION_TITLE: "Le produit n'a pas d'expirations",
      ADD_EXPIRATION_LABEL: 'Ajouter',
      QUANTITY_PACKAGE: 'Nombre de colis',
      QUANTITY_PRODUCTS: 'Nombre de produits',
      VALIDATE_DATE_BUTTON_LABEL: 'Confirmer la date',
      VALIDATED_DATE_BUTTON_LABEL: 'Date confirmée',
      CLOSE_WAREHOUSE_MAP: 'Fermer la carte',
    },
    QUESTION: {
      OK_LABEL: 'OK',
      NOK_LABEL: 'NOK',
      CANCEL_LABEL: 'Annuler',
      REASON_LABEL: 'Pourquoi ?',
      COMMENT_LABEL: 'Commentaire',
      SOLVED_LABEL: 'Problème résolu sur place',
    },
  },
}

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: 'fr',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
