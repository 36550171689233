import { httpClient } from '../../App/httpClient'
import { RESSOURCE_ID } from '../task.service'

export const getStoreStatus = async (taskId, secret) => {
  const {
    data: {
      is_entry_door_unlocked: isEntryDoorUnlocked,
      is_open_for_business: isOpenForBusiness,
      last_status: lastStatus,
      next_status: nextStatus,
      display: { last_update: lastUpdate },
    },
  } = await httpClient.get(`${RESSOURCE_ID}/${taskId}/store_status`, {
    headers: {
      Authorization: `Bearer ${secret}`,
    },
  })

  return {
    isEntryDoorUnlocked,
    isOpenForBusiness,
    lastStatus,
    nextStatus,
    lastUpdate,
  }
}
