import { datatype } from 'faker'

export const expiration = {
  _id: datatype.uuid(),
  action: 'expiration',
  comment: 'Comment from admin',
  agent_comment: 'Comment from agent',
  index: 0,
  state: 'created',
  payload: {
    product: {
      product_id: '5de8c77945f5a4587280e81f',
      name: 'PastaBox - Tortellini Ricotta Épinards sauce au Parmesan',
      shelf_id: 'S3-C1-L2',
      store_shelf_group_id: '0000000000SHELF_GROUP_ID',
      section: 'Tout pour le dej',
      image_url:
        'https://storebox-api-public-production.s3.amazonaws.com/product_images/5de8c77945f5a4587280e81f.jpeg',
    },
    product_count: 5,
  },
  store_id: '0000000000000000STORE_ID',
  read_only: false,
}

export const makeExpiration = ({ _id, index, state } = {}) => ({
  ...expiration,
  _id: _id || expiration._id,
  index: index || expiration.index,
  state: state || expiration.state,
})
