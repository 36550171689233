import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { SwipeableDrawer, Paper, Typography } from '@mui/material'
import { Alert } from '@mui/material'
function TaskGroupHelperDrawer({ isOpen, onOpen, onClose }) {
  const { t } = useTranslation('TASKGROUP_HELPER_DRAWER')

  const PHONE_NUMBER = {
    label: '06 68 98 37 72',
    href: 'tel:+33668983772',
  }

  const SUPPORT_CHAR_LINK = 'https://storelift.slack.com/archives/C01K2G8LURZ'

  return (
    <SwipeableDrawer
      anchor={'bottom'}
      open={isOpen}
      onOpen={onOpen}
      onClose={onClose}
    >
      <Paper>
        <Alert severity="warning" aria-label="taskGroup helper drawer">
          <Typography component="div">
            {t('HELPER_TEXT_ONE')}
            <a target="blank" href={SUPPORT_CHAR_LINK}>
              {t('CHAT_SUPPORT')}
            </a>
            <p>
              {t('HELPER_TEXT_TWO')}
              <a href={PHONE_NUMBER.href}>{PHONE_NUMBER.label}</a>
              {t('HELP_TEXT_THREE')}
            </p>
          </Typography>
        </Alert>
      </Paper>
    </SwipeableDrawer>
  )
}

TaskGroupHelperDrawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
}

TaskGroupHelperDrawer.defaultProps = {
  onOpen: () => {},
}

export default TaskGroupHelperDrawer
