import React, { useMemo, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { useForm, useFieldArray, Controller, useWatch } from 'react-hook-form'

import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Fab,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from '@mui/material'

import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'

import { MobileDatePicker } from '@mui/x-date-pickers'

import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import EventAvailableIcon from '@mui/icons-material/EventAvailable'

import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import AddIcon from '@mui/icons-material/Add'
import CheckIcon from '@mui/icons-material/Check'

import Counter from '../../components/Counter'

import Product from '../../Products/Product'

function WarehouseRestockingFromStoreTask({
  setToCompletable,
  setToUncompletable,
  payload: {
    expirations,
    product,
    initialProductCount,
    productCount,
    warehouseShelf,
  },
}) {
  const { t } = useTranslation('RESTOCKING_WAREHOUSE')
  const [deletedExpirations, setDeletedExpirations] = useState([])

  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      count: productCount,
      expirations: expirations ?? [],
    },
  })

  const {
    fields: expirationsFields,
    append: appendExpiration,
    remove: removeExpiration,
  } = useFieldArray({ name: 'expirations', control: form.control })

  const count = useWatch({
    control: form.control,
    name: 'count',
  })

  const watchedExpirations = useWatch({
    control: form.control,
    name: 'expirations',
  })

  const totalProductCountExpirations = useMemo(
    () =>
      watchedExpirations.reduce(
        (_totalProductCountExpirations, expiration) =>
          _totalProductCountExpirations + expiration.productCount,
        0,
      ),
    [watchedExpirations],
  )

  const isAllExpirationDateConfirmed = useMemo(() => {
    return watchedExpirations.every(({ confirmed }) => confirmed)
  }, [watchedExpirations])

  useEffect(() => {
    const payload = {
      productCount: count,
      expirations: [...watchedExpirations],
    }

    if (deletedExpirations.length) {
      payload.expirations = payload.expirations.concat(
        deletedExpirations.map((deletedExpiration) => ({
          ...deletedExpiration,
          productCount: 0,
        })),
      )
    }

    setToCompletable(payload)

    if (product.hasExpiration) {
      if (
        totalProductCountExpirations === count &&
        watchedExpirations.filter(
          ({ expirationDate }) => expirationDate !== null,
        ).length === watchedExpirations.length &&
        isAllExpirationDateConfirmed
      ) {
        setToCompletable(payload)
      } else {
        setToUncompletable(payload)
      }
    } else {
      setToCompletable({ productCount: payload.productCount, expirations: [] })
    }
  }, [
    count,
    isAllExpirationDateConfirmed,
    totalProductCountExpirations,
    watchedExpirations,
  ])

  return (
    <Box display="flex" height="100%" gap={1}>
      <Box flex="1" display="flex" flexDirection="column" minWidth={250}>
        <Box flex="1">
          <Product {...product} />
        </Box>
        <Box>
          <Typography aria-label="product name" variant="h6">
            <code>{warehouseShelf.id}</code>
          </Typography>
        </Box>

        <Box>
          <Card variant="outlined" sx={{ marginTop: 1 }}>
            <CardContent sx={{ p: 1 }}>
              <Box display="flex" alignItems="center">
                <Typography variant="subtitle2" style={{ width: '80%' }}>
                  {t('QUANTITY_PER_PACKAGE_LABEL', {
                    quantity: 0,
                  })}
                </Typography>
                <ToggleButtonGroup
                  exclusive
                  aria-label="text alignment"
                  size="large"
                  color="primary"
                  fullWidth
                  sx={{ minWidth: 170 }}
                >
                  <ToggleButton value={true} aria-label="left aligned">
                    1️⃣ &nbsp;
                    <Typography>{count}</Typography>
                  </ToggleButton>
                  <ToggleButton value={false} aria-label="centered" disabled>
                    📦 &nbsp;
                    <Typography>0</Typography>
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
            </CardContent>
            <CardActions>
              <Controller
                control={form.control}
                name="count"
                render={({ field: { onChange, value, error } }) => (
                  <Counter
                    dataTestid="product"
                    initialValue={initialProductCount}
                    label={t('QUANTITY_PRODUCTS')}
                    keyboardInput
                    value={value}
                    error={error}
                    onChange={onChange}
                  />
                )}
              />
            </CardActions>
          </Card>
        </Box>
      </Box>

      <Box flex="1" display="flex" minWidth={250}>
        <Box
          flex="1 1 0"
          display="flex"
          position="relative"
          flexDirection="column"
        >
          <Card variant="outlined" sx={{ height: '100%' }}>
            <CardHeader
              title={
                <div data-testid={`expiration-section-header`}>
                  {product.hasExpiration
                    ? t('EXPIRATION_SECTION_TITLE')
                    : t('NO_EXPIRATION_SECTION_TITLE')}
                  {product.hasExpiration ? (
                    <Fab
                      data-testid="expiration-add-button"
                      variant="extended"
                      style={{
                        float: 'right',
                        paddingRight: 15,
                      }}
                      size="small"
                      disabled={!product.hasExpiration}
                      onClick={() =>
                        appendExpiration({
                          productCount: count - totalProductCountExpirations,
                          initialProductCount:
                            count - totalProductCountExpirations,
                          expirationDate: null,
                        })
                      }
                      color="primary"
                    >
                      <AddIcon style={{ marginRight: 5 }} />
                      {t('ADD_EXPIRATION_LABEL')}
                    </Fab>
                  ) : (
                    <></>
                  )}
                </div>
              }
              titleTypographyProps={{
                variant: 'subtitle1',
              }}
            />

            <CardContent
              sx={{
                margin: 'auto',
                maxHeight: '100%',
                maxWidth: '100%',
                position: 'absolute',
                top: 66,
                left: 0,
                right: 0,
                bottom: 0,
                overflow: 'auto',
              }}
            >
              {product.hasExpiration
                ? expirationsFields.map((field, index) => (
                    <Paper
                      key={field.id}
                      elevation={0}
                      style={{ marginBottom: 25 }}
                    >
                      <Controller
                        control={form.control}
                        name={`expirations.${index}.productCount`}
                        render={({ field: { onChange, value } }) => (
                          <Counter
                            dataTestid={`expiration-${index}`}
                            validate={({ value }) => value >= 0}
                            label={t('QUANTITY_PRODUCTS')}
                            onChange={onChange}
                            value={parseFloat(value.toFixed(2))}
                            error={totalProductCountExpirations !== count}
                            keyboardInput
                          />
                        )}
                      />

                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          width: '100%',
                        }}
                      >
                        <Button
                          data-testid={`expiration-${index}-delete-button`}
                          color="secondary"
                          variant="contained"
                          onClick={() => {
                            setDeletedExpirations([
                              ...deletedExpirations,
                              watchedExpirations[index],
                            ])
                            removeExpiration(index)
                          }}
                          style={{
                            marginTop: 8,
                            height: 38,
                            width: 38,
                            alignSelf: 'start',
                          }}
                        >
                          <DeleteForeverIcon />
                        </Button>
                        <Controller
                          control={form.control}
                          name={`expirations.${index}.expirationDate`}
                          render={({ field: { onChange, value } }) => {
                            return (
                              <MobileDatePicker
                                showToolbar={false}
                                value={value}
                                onChange={(value) => {
                                  form.setValue(
                                    `expirations.${index}.confirmed`,
                                    true,
                                  )
                                  onChange(value)
                                }}
                                views={['year', 'month', 'day']}
                                openTo="day"
                                renderInput={(props) => {
                                  return (
                                    <TextField
                                      fullWidth
                                      {...props}
                                      sx={{ marginLeft: 1, marginRight: 1 }}
                                      margin="dense"
                                      required
                                      size="small"
                                      InputProps={{
                                        'data-testid': `expiration-${index}-date-input`,
                                        readOnly: true,
                                        endAdornment: watchedExpirations[index]
                                          ?.confirmed ? (
                                          <InputAdornment position="end">
                                            <EventAvailableIcon fontSize="default" />
                                          </InputAdornment>
                                        ) : (
                                          <InputAdornment position="end">
                                            <CalendarTodayIcon fontSize="default" />
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                  )
                                }}
                              />
                            )
                          }}
                        />
                        <Controller
                          control={form.control}
                          name={`expirations.${index}.confirmed`}
                          render={({ field: { onChange, value } }) => (
                            <Button
                              data-testid={`expiration-${index}-confirm-button`}
                              fullWidth
                              color="primary"
                              variant="contained"
                              onClick={() => onChange(true)}
                              disabled={value}
                              style={{
                                marginTop: 8,
                                height: 38,
                                width: 38,
                                alignSelf: 'start',
                              }}
                            >
                              <CheckIcon />
                            </Button>
                          )}
                        />
                      </Box>
                    </Paper>
                  ))
                : null}
            </CardContent>
          </Card>
        </Box>
      </Box>
    </Box>
  )
}

WarehouseRestockingFromStoreTask.propTypes = {
  disabled: PropTypes.bool,
  setToCompletable: PropTypes.func,
  setToUncompletable: PropTypes.func,
  storeId: PropTypes.string,
  payload: PropTypes.object,
  isCompletable: PropTypes.bool,
  price: PropTypes.string,
}

export default WarehouseRestockingFromStoreTask
