import React, { createContext } from 'react'
import PropTypes from 'prop-types'

export const TaskGroupAPI = {
  dispatch: () => {},
}

export const TaskGroupState = {
  state: () => {},
}

export const TaskGroupAPIContext = createContext(undefined)
export const TaskGroupStateContext = createContext(undefined)

export default function TaskGroupContext({ children, api, state }) {
  return (
    <TaskGroupAPIContext.Provider value={api}>
      <TaskGroupStateContext.Provider value={state}>
        {children}
      </TaskGroupStateContext.Provider>
    </TaskGroupAPIContext.Provider>
  )
}
TaskGroupContext.propTypes = {
  children: PropTypes.node,
  api: PropTypes.func,
  state: PropTypes.object,
}
