import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { LoadingButton } from '@mui/lab'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'

import { useTaskGroup } from '../taskGroup.context'
import { useTask } from '../../Task/task.context'

const NavigationPreviousButton = ({
  label = 'Précédent',
  onClick,
  ...props
}) => {
  const { t } = useTranslation('NAVIGATION')

  const {
    gotToPreviousTask,
    tasksHistory,
    CustomPreviousButton,
  } = useTaskGroup()

  const { isLoading } = useTask()

  const handlePreviousButtonClick = async () => {
    const previous = gotToPreviousTask

    if (typeof onClick === 'function') {
      return onClick(previous)
    }

    await previous()
  }

  const disabled = !tasksHistory.length

  if (CustomPreviousButton && React.isValidElement(CustomPreviousButton)) {
    return React.cloneElement(CustomPreviousButton, {
      isLoading,
      disabled,
      label,
      previous: handlePreviousButtonClick,
    })
  }

  return (
    <LoadingButton
      style={{ flexGrow: '2' }}
      loading={isLoading}
      disabled={disabled}
      variant="outlined"
      color="primary"
      onClick={handlePreviousButtonClick}
      data-testid="navigation-previous-button"
      {...props}
    >
      <NavigateBeforeIcon /> {t('PREVIOUS_BUTTON')}
    </LoadingButton>
  )
}

NavigationPreviousButton.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
}

export default NavigationPreviousButton
