import React, { useEffect } from 'react'
import { useErrorHandler } from 'react-error-boundary'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import {
  Table,
  TableContainer,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  Chip,
  Box,
} from '@mui/material'

import useAsyncActionInterval from '../../../hooks/useAsyncActionInterval'
import LoadingButton from '../../../components/LoadingButton'

import { getStoreStatus } from '../openCloseStore.service'

import { openStoreForBusiness } from './openForBusiness.service'

const FETCH_STORE_STATUS_INTERVAL = 5000

function OpenStore({ id, setToCompletable }) {
  const { t } = useTranslation('OPEN_STORE')
  const { secret } = useParams()
  const handleError = useErrorHandler()

  const [
    { isEntryDoorUnlocked, isOpenForBusiness, lastUpdate } = {
      isEntryDoorUnlocked: true,
      isOpenForBusiness: false,
    },
    isFetchingStoreStatus,
    { startInterval, stopInterval },
  ] = useAsyncActionInterval(async () => await getStoreStatus(id, secret), {
    delay: FETCH_STORE_STATUS_INTERVAL,
    immediate: true,
    onError: (error) => handleError(error),
  })

  useEffect(() => {
    if (!isEntryDoorUnlocked && isOpenForBusiness) {
      stopInterval()
      setToCompletable()
    }
  }, [isEntryDoorUnlocked, isOpenForBusiness])

  const onOpenStoreForBusiness = async () => {
    try {
      stopInterval()

      await openStoreForBusiness(id, secret)

      startInterval()
    } catch (error) {
      handleError(error)
    }
  }

  const canOpenStoreForBusiness = !isOpenForBusiness || isEntryDoorUnlocked

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Box flex="1">
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell variant="head">{t('STORE')}</TableCell>
                <TableCell>
                  {isOpenForBusiness ? (
                    <Chip
                      color="primary"
                      label={t('OPEN_FOR_BUSINESS_CHIP_STATUS')}
                      size="small"
                    />
                  ) : (
                    <Chip
                      color="secondary"
                      label={t('CLOSED_FOR_BUSINESS_CHIP_STATUS')}
                      size="small"
                    />
                  )}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell variant="head">{t('DOORS')}</TableCell>
                <TableCell>
                  {isEntryDoorUnlocked ? (
                    <Chip
                      color="secondary"
                      label={t('DOORS_UNLOCKED_CHIP_STATUS')}
                      size="small"
                    />
                  ) : (
                    <Chip
                      color="primary"
                      label={t('DOORS_LOCKED_CHIP_STATUS')}
                      size="small"
                    />
                  )}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell variant="head">{t('LAST_UPDATED')}</TableCell>
                <TableCell>
                  <Tooltip title={'title'}>
                    <span>{lastUpdate}</span>
                  </Tooltip>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Box role="task-actions">
        <LoadingButton
          data-testid="open-for-business-button"
          sx={{ height: '100%', width: '270px' }}
          isLoading={isFetchingStoreStatus}
          variant="contained"
          color="primary"
          onClick={onOpenStoreForBusiness}
          disabled={!canOpenStoreForBusiness || isFetchingStoreStatus}
        >
          {t('OPEN_FOR_BUSINESS_ACTION')}
        </LoadingButton>
      </Box>
    </Box>
  )
}

OpenStore.propTypes = {
  id: PropTypes.string,
  comment: PropTypes.string,
  index: PropTypes.number,
  store: PropTypes.string,
  state: PropTypes.string,
  taskGroup: PropTypes.string,
  setToCompletable: PropTypes.func,
  isCompletable: PropTypes.bool,
}

export default OpenStore
