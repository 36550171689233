import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'

import {
  Box,
  FormControl,
  InputLabel,
  Select,
  TextField,
  Button,
  Alert,
  FormControlLabel,
  Checkbox,
} from '@mui/material'

import MarkdownViewer from '../../components/MarkdownViewer'
import ImageInputUpload from '../../components/ImageInputUpload'

import { useTaskGroup } from '../../TaskGroup/taskGroup.context'

const CustomNextButton = ({ setResponse, next, isLoading, isCompletable }) => {
  const { t } = useTranslation('QUESTION')
  const islandscape = useMediaQuery({ query: '(orientation: landscape)' })

  return (
    <Box
      display="flex"
      flexDirection={islandscape ? 'column' : 'row-reverse'}
      gap={1}
      sx={{ flexGrow: '6' }}
    >
      <Button
        disabled={isLoading || !isCompletable}
        fullWidth
        data-testid="navigation-next-ok-button"
        variant="contained"
        color="success"
        sx={{ flexGrow: '3' }}
        onClick={() => {
          setResponse(true)
          next()
        }}
      >
        {t('OK_LABEL')}
      </Button>
      <Button
        disabled={isLoading || !isCompletable}
        fullWidth
        data-testid="navigation-not-ok-button"
        variant="contained"
        color="error"
        sx={{ flexGrow: '3' }}
        onClick={() => setResponse(false)}
      >
        {t('NOK_LABEL')}
      </Button>
    </Box>
  )
}

CustomNextButton.propTypes = {
  setResponse: PropTypes.func,
  next: PropTypes.func,
  isLoading: PropTypes.bool,
  isCompletable: PropTypes.bool,
}

function Question({ setToCompletable, setToUncompletable, payload }) {
  const { t } = useTranslation('QUESTION')

  const { setCustomNextButton } = useTaskGroup()

  const [response, setResponse] = useState(payload.response)

  const [reason, setReason] = useState(payload.reason || '')

  const [isSolved, setSolved] = useState(payload.isSolved || false)

  const [reasonComment, setReasonComment] = useState(
    payload.reasonComment || '',
  )

  const [imageUrl, setImgUrl] = useState(payload.imageUrl || '')

  const [isUploadingImg, setIsUploadingImg] = useState(false)

  useEffect(() => {
    if (response === true) {
      setCustomNextButton(<CustomNextButton setResponse={setResponse} />)
    } else {
      setCustomNextButton(null)
    }
  }, [response])

  useEffect(() => {
    if (isUploadingImg) {
      return setToUncompletable()
    }

    if (response === true) {
      return setToCompletable({ response })
    }

    if (response === false && reason !== '') {
      return setToCompletable({
        response,
        reason,
        isSolved,
        reasonComment,
        imageUrl,
      })
    }

    setToUncompletable()
  }, [response, reason, isSolved, reasonComment, imageUrl, isUploadingImg])

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={2}
      height="100%"
      width="100%"
    >
      <Box flex="1">
        {response === true ? (
          <MarkdownViewer text={payload.customBody} />
        ) : (
          <Box display="flex" flexDirection="column" gap={2} height="100%">
            <Alert
              severity="error"
              variant="filled"
              action={
                <Button
                  data-testid={`question-cancel-button`}
                  sx={{ color: 'white', borderColor: 'white' }}
                  variant="outlined"
                  onClick={() => setResponse(true)}
                  size="small"
                >
                  {t('CANCEL_LABEL')}
                </Button>
              }
            >
              {t('NOK_LABEL')}
            </Alert>
            <FormControl fullWidth required>
              <InputLabel id="reason">{t('REASON_LABEL')}</InputLabel>
              <Select
                native
                inputProps={{
                  id: 'reason',
                  'data-testid': 'question-reason-select-input',
                }}
                label={t('REASON_LABEL')}
                value={reason}
                onChange={(e) => setReason(e.target.value)}
              >
                <option disabled />
                {payload.reasons.map((reason, index) => (
                  <option
                    key={reason}
                    value={reason}
                    data-testid={`question-reason-select-item-${index}`}
                  >
                    {reason}
                  </option>
                ))}
              </Select>
            </FormControl>

            <FormControlLabel
              control={
                <Checkbox
                  checked={isSolved}
                  value={isSolved}
                  onChange={(event) => {
                    setSolved(event.target.checked)
                  }}
                />
              }
              label={t('SOLVED_LABEL')}
            />

            <TextField
              inputProps={{
                'data-testid': 'question-reason-comment-input',
              }}
              fullWidth
              label={t('COMMENT_LABEL')}
              multiline
              rows={3}
              value={reasonComment}
              onChange={(e) => setReasonComment(e.target.value)}
            />

            <Box flex="1 1 0" minHeight="100px">
              <ImageInputUpload
                value={imageUrl}
                onChange={(value) => setImgUrl(value)}
                onUpload={() => setIsUploadingImg(true)}
                onSettled={() => setIsUploadingImg(false)}
              />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  )
}

Question.propTypes = {
  setToCompletable: PropTypes.func,
  setToUncompletable: PropTypes.func,
  complete: PropTypes.func,
  payload: PropTypes.object,
}

export default Question
