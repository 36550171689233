import { date } from 'faker'

import { makeCustom } from '../../Task/Custom/custom.mock'
import { makeQuestion } from '../../Task/Question/question.mock'
import { makeOpenForBusiness } from '../../Task/OpenCloseStore/OpenForBusiness/openForBusiness.mock'
import { makeCloseForBusiness } from '../../Task/OpenCloseStore/CloseForBusiness/closeForBusiness.mock'

export const tasks = [
  makeCustom({ _id: 'B00000000000000000000001' }),
  makeCloseForBusiness({ _id: 'B00000000000000000000002' }),
  makeQuestion({ _id: 'B00000000000000000000003' }),
  makeCustom({ _id: 'B00000000000000000000004' }),
  makeCustom({ _id: 'B00000000000000000000005' }),
  makeOpenForBusiness({ _id: 'B00000000000000000000006' }),
]

export const manufacturing = {
  _id: 'A00000000000000000000001',
  share_url: 'share/url',
  state: 'created',
  type: 'manufacturing_tasks',
  stores: [
    {
      _id: '0000000000000000STORE_ID',
      address: '9 quai Stalingrad 76350 Oissel',
      name: 'Boxy #42 Oissel',
      short_code: 'OIS',
      warehouse_id: '000000000000WAREHOUSE_ID',
    },
    {
      _id: '1000000000000000STORE_ID',
      address: '47B rue Ernest Renan, Ivry-sur-Seine',
      name: 'Boxy # Ivry',
      short_code: 'IVR',
      warehouse_id: '000000000000WAREHOUSE_ID',
    },
  ],
  created_at: date.past(),
  task_ids: tasks.map((task) => task._id),
  current_task_index: 0,
}

export const makeManufacturing = ({ _id, current_task_index, state } = {}) => ({
  ...manufacturing,
  _id: _id || manufacturing._id,
  current_task_index: current_task_index || manufacturing.current_task_index,
  state: state || manufacturing.state,
})
