import { date } from 'faker'

import { makeTest } from '../../Task/Test/task.test.mock'

export const tasks = [
  makeTest({ _id: 'B000000000000000000000001' }),
  makeTest({ _id: 'B000000000000000000000002' }),
  makeTest({ _id: 'B000000000000000000000003' }),
]

export const test = {
  _id: 'A00000000000000000000001',
  share_url: 'share/url',
  state: 'created',
  type: 'test',
  created_at: date.past(),
  task_ids: tasks.map((task) => task._id),
  current_task_index: 0,
}

export const makeTaskgroupTest = ({
  _id,
  current_task_index,
  state,
  task_ids,
} = {}) => ({
  ...test,
  _id: _id || test._id,
  current_task_index: current_task_index || test.current_task_index,
  state: state || test.state,
  task_ids: task_ids || test.task_ids,
})
