export const parsePayload = (payload) => {
  const {
    product_count,
    initial_product_count,
    product,
    warehouse_shelf,
    expirations,
  } = payload

  return {
    productCount: product_count,
    initialProductCount: initial_product_count,
    product: {
      id: product.product_id || product._id,
      name: product.full_name,
      imageUrl: product.image_url || product.product_image_url,
      capacity: product.capacity,
      capacityUnit: product.capacity_unit,
      hasExpiration: product.has_expiration,
      barcode: product.barcode,
    },
    warehouseShelf: {
      id: warehouse_shelf?.id,
    },
    expirations: expirations?.map(
      ({ expiration_date, product_count, _id }) => ({
        expirationDate: expiration_date,
        productCount: product_count,
        id: _id,
      }),
    ),
  }
}

export const parseMenu = (payload) => {
  const { product_count, initial_product_count, product } = payload

  return {
    productCount: product_count,
    initialProductCount: initial_product_count,
    product: {
      id: product.product_id || product._id,
      name: product.name,
      imageUrl: product.image_url || product.product_image_url,
      barcode: product.barcode,
    },
  }
}

export const parseCompletePayload = (payload) => {
  return {
    product_count: payload.productCount,
    expirations: payload.expirations.map(
      ({ expirationDate, productCount }) => ({
        expiration_date: expirationDate,
        product_count: productCount,
      }),
    ),
  }
}
