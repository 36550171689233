/* istanbul ignore file */
import { handlers as taskGroupHandlers } from '../TaskGroup/taskGroup.mocks'
import { handlers as taskHandlers } from '../Task/task.mocks'
import { handlers as storeHandlers } from '../Stores/stores.mocks'
import { handlers as appHandlers } from '../App/app.mock'
import { handlers as productsHandlers } from '../Products/products.mock'

import { handlers as closeStoreForBusinessHandlers } from '../Task/OpenCloseStore/openCloseStore.mock'

export default [
  ...appHandlers,
  ...productsHandlers,
  ...taskGroupHandlers,
  ...taskHandlers,
  ...storeHandlers,
  ...closeStoreForBusinessHandlers,
]
