/* eslint-disable react/prop-types */
import React, { useMemo, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'

import { useForm, useFieldArray, Controller, useWatch } from 'react-hook-form'

import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Dialog,
  Fab,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from '@mui/material'

import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'

import { MobileDatePicker } from '@mui/x-date-pickers'

import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import EventAvailableIcon from '@mui/icons-material/EventAvailable'

import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import AddIcon from '@mui/icons-material/Add'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'

import Counter from '../../components/Counter'
import Header from '../../Layout/Header'

import Product from '../../Products/Product'
import WarehouseMap from '../../Products/WarehouseMap'

function ReceptionWarehouse({
  setToCompletable,
  setToUncompletable,
  payload: {
    expirations,
    product,
    packageCount,
    initialPackageCount,
    purchaseOrder,
    quantityPerPackage,
    warehouseShelf,
  },
}) {
  const { t } = useTranslation('RESTOCKING_WAREHOUSE')
  const [deletedExpirations, setDeletedExpirations] = useState([])
  const [showHasPackingCount, setShowHasPackingCount] = useState(true)

  const [warehouseMapOpen, setWarehouseMapOpen] = useState(false)

  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      count: packageCount,
      expirations: expirations || [
        {
          productCount: packageCount,
          expirationDate: DateTime.local().plus({
            days: purchaseOrder.guaranteedShelfLife,
          }),
        },
      ],
    },
  })

  const {
    fields: expirationsFields,
    append: appendExpiration,
    remove: removeExpiration,
    update: updateExpiration,
  } = useFieldArray({ name: 'expirations', control: form.control })

  const count = useWatch({
    control: form.control,
    name: 'count',
  })

  const watchedExpirations = useWatch({
    control: form.control,
    name: 'expirations',
  })

  const totalProductCountExpirations = useMemo(
    () =>
      watchedExpirations.reduce(
        (_totalProductCountExpirations, expiration) =>
          _totalProductCountExpirations + expiration.productCount,
        0,
      ),
    [watchedExpirations],
  )

  const isAllExpirationDateConfirmed = useMemo(() => {
    return watchedExpirations.every(({ confirmed }) => confirmed)
  }, [watchedExpirations])

  useEffect(() => {
    let payload

    if (showHasPackingCount) {
      payload = {
        productCount: count * quantityPerPackage,
        expirations: watchedExpirations.map(
          ({ productCount, ...expiration }) => ({
            productCount: Math.ceil(productCount * quantityPerPackage),
            ...expiration,
          }),
        ),
      }
    } else {
      payload = {
        productCount: count,
        expirations: [...watchedExpirations],
      }
    }

    if (deletedExpirations.length) {
      payload.expirations = payload.expirations.concat(
        deletedExpirations.map((deletedExpiration) => ({
          ...deletedExpiration,
          productCount: 0,
        })),
      )
    }

    if (product.hasExpiration) {
      if (
        totalProductCountExpirations === count &&
        watchedExpirations.filter(
          ({ expirationDate }) => expirationDate !== null,
        ).length === watchedExpirations.length &&
        isAllExpirationDateConfirmed
      ) {
        setToCompletable(payload)
      } else {
        setToUncompletable(payload)
      }
    } else {
      setToCompletable({ productCount: payload.productCount, expirations: [] })
    }
  }, [
    count,
    isAllExpirationDateConfirmed,
    totalProductCountExpirations,
    watchedExpirations,
  ])

  const setCountAsPackingCount = () => {
    form.setValue('count', Math.round(count / quantityPerPackage))

    watchedExpirations.forEach(({ productCount, ...expiration }, index) => {
      return updateExpiration(index, {
        productCount: productCount / quantityPerPackage,
        ...expiration,
      })
    })
  }

  const setCountAsProductCount = () => {
    form.setValue('count', Math.round(count * quantityPerPackage))

    watchedExpirations.forEach(({ productCount, ...expiration }, index) => {
      return updateExpiration(index, {
        productCount: Math.ceil(productCount * quantityPerPackage),
        ...expiration,
      })
    })
  }

  const switchProductPackingCount = () => {
    const updatedShowHasPackingCount = !showHasPackingCount

    if (updatedShowHasPackingCount) {
      setCountAsPackingCount()
    }

    if (!updatedShowHasPackingCount) {
      setCountAsProductCount()
    }

    setShowHasPackingCount(updatedShowHasPackingCount)
  }

  return (
    <Box display="flex" height="100%" gap={1}>
      <Dialog fullScreen open={warehouseMapOpen}>
        <Box role="app-header" height={64}>
          <Header
            height={64}
            actions={
              <Button
                data-testid="warehouse-map-close-button"
                sx={{
                  color: 'white',
                }}
                onClick={() => setWarehouseMapOpen(false)}
                endIcon={<CloseIcon />}
              >
                {t('CLOSE_WAREHOUSE_MAP')}
              </Button>
            }
          />
        </Box>
        <Box
          padding={'10px'}
          display="flex"
          height="calc(100% - 84px)"
          onClick={() => setWarehouseMapOpen(false)}
        >
          <WarehouseMap shelfId={warehouseShelf.id} />
        </Box>
      </Dialog>
      <Box flex="1" display="flex" flexDirection="column" minWidth={250}>
        <Box flex="1">
          <Product {...product} />
        </Box>
        <Box onClick={() => setWarehouseMapOpen(true)}>
          <Typography
            color="textSecondary"
            variant="button"
            data-testid={`product-warehouse-shelf`}
          >
            <code>{warehouseShelf.id}</code>
          </Typography>
        </Box>

        <Box>
          <Card variant="outlined" sx={{ marginTop: 1 }}>
            <CardContent sx={{ p: 1 }}>
              <Box display="flex" alignItems="center">
                <Typography variant="subtitle2" style={{ width: '80%' }}>
                  {t('QUANTITY_PER_PACKAGE_LABEL', {
                    quantity: quantityPerPackage,
                  })}
                </Typography>
                <ToggleButtonGroup
                  value={showHasPackingCount}
                  exclusive
                  onChange={switchProductPackingCount}
                  aria-label="text alignment"
                  size="large"
                  color="primary"
                  fullWidth
                  sx={{ minWidth: 170 }}
                >
                  <ToggleButton
                    value={false}
                    aria-label="left aligned"
                    data-testid="toggle-unit-button"
                  >
                    1️⃣ &nbsp;
                    <Typography>
                      {showHasPackingCount ? count * quantityPerPackage : count}
                    </Typography>
                  </ToggleButton>
                  <ToggleButton
                    value={true}
                    aria-label="centered"
                    data-testid="toggle-packing-button"
                  >
                    📦 &nbsp;
                    <Typography>
                      {showHasPackingCount
                        ? count
                        : parseFloat((count / quantityPerPackage).toFixed(2))}
                    </Typography>
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
            </CardContent>
            <CardActions>
              <Controller
                control={form.control}
                name={`count`}
                render={({ field: { onChange, value, error } }) => (
                  <Counter
                    dataTestid="product"
                    initialValue={
                      showHasPackingCount
                        ? initialPackageCount
                        : Math.ceil(initialPackageCount * quantityPerPackage)
                    }
                    error={error}
                    label={
                      showHasPackingCount
                        ? t('QUANTITY_PACKAGE')
                        : t('QUANTITY_PRODUCTS')
                    }
                    onChange={onChange}
                    value={value}
                    keyboardInput
                  />
                )}
              />
            </CardActions>
          </Card>
        </Box>
      </Box>

      <Box flex="1" display="flex" minWidth={250}>
        <Box
          flex="1 1 0"
          display="flex"
          position="relative"
          flexDirection="column"
        >
          <Card variant="outlined" sx={{ height: '100%' }}>
            <CardHeader
              title={
                <div data-testid={`expiration-section-header`}>
                  {product.hasExpiration
                    ? t('EXPIRATION_SECTION_TITLE')
                    : t('NO_EXPIRATION_SECTION_TITLE')}
                  {product.hasExpiration ? (
                    <Fab
                      data-testid="expiration-add-button"
                      variant="extended"
                      style={{
                        float: 'right',
                        paddingRight: 15,
                      }}
                      size="small"
                      disabled={!product.hasExpiration}
                      onClick={() =>
                        appendExpiration({
                          productCount: count - totalProductCountExpirations,
                          initialProductCount:
                            count - totalProductCountExpirations,
                          expirationDate: DateTime.now().plus({
                            days: purchaseOrder.guaranteedShelfLife,
                          }),
                        })
                      }
                      color="primary"
                    >
                      <AddIcon style={{ marginRight: 5 }} />
                      {t('ADD_EXPIRATION_LABEL')}
                    </Fab>
                  ) : (
                    <></>
                  )}
                </div>
              }
              titleTypographyProps={{
                variant: 'subtitle1',
              }}
            />

            <CardContent
              sx={{
                margin: 'auto',
                maxHeight: '100%',
                maxWidth: '100%',
                position: 'absolute',
                top: 66,
                left: 0,
                right: 0,
                bottom: 0,
                overflow: 'auto',
              }}
            >
              {product.hasExpiration
                ? expirationsFields.map((field, index) => (
                    <Paper
                      key={field.id}
                      elevation={0}
                      style={{ marginBottom: 25 }}
                    >
                      <Controller
                        control={form.control}
                        name={`expirations.${index}.productCount`}
                        render={({ field: { onChange, value } }) => (
                          <Counter
                            dataTestid={`expiration-${index}`}
                            validate={({ value }) => value >= 0}
                            label={
                              showHasPackingCount
                                ? t('QUANTITY_PACKAGE')
                                : t('QUANTITY_PRODUCTS')
                            }
                            onChange={onChange}
                            value={parseFloat(value.toFixed(2))}
                            error={totalProductCountExpirations !== count}
                            keyboardInput
                          />
                        )}
                      />

                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          width: '100%',
                        }}
                      >
                        <Button
                          color="secondary"
                          variant="contained"
                          data-testid={`expiration-${index}-delete-button`}
                          onClick={() => {
                            setDeletedExpirations([
                              ...deletedExpirations,
                              watchedExpirations[index],
                            ])
                            removeExpiration(index)
                          }}
                          style={{
                            marginTop: 8,
                            height: 38,
                            width: 38,
                            alignSelf: 'start',
                          }}
                        >
                          <DeleteForeverIcon />
                        </Button>
                        <Controller
                          control={form.control}
                          name={`expirations.${index}.expirationDate`}
                          render={({ field: { onChange, value } }) => {
                            return (
                              <MobileDatePicker
                                showToolbar={false}
                                value={value}
                                onChange={(value) => {
                                  form.setValue(
                                    `expirations.${index}.confirmed`,
                                    true,
                                  )
                                  onChange(value)
                                }}
                                minDate={DateTime.now()}
                                views={['year', 'month', 'day']}
                                openTo="day"
                                renderInput={(props) => {
                                  return (
                                    <TextField
                                      fullWidth
                                      {...props}
                                      sx={{ marginLeft: 1, marginRight: 1 }}
                                      margin="dense"
                                      required
                                      size="small"
                                      InputProps={{
                                        'data-testid': `expiration-${index}-date-input`,
                                        readOnly: true,
                                        endAdornment: watchedExpirations[index]
                                          ?.confirmed ? (
                                          <InputAdornment position="end">
                                            <EventAvailableIcon fontSize="default" />
                                          </InputAdornment>
                                        ) : (
                                          <InputAdornment position="end">
                                            <CalendarTodayIcon fontSize="default" />
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                  )
                                }}
                              />
                            )
                          }}
                        />
                        <Controller
                          control={form.control}
                          name={`expirations.${index}.confirmed`}
                          render={({ field: { onChange, value } }) => (
                            <Button
                              data-testid={`expiration-${index}-confirm-button`}
                              fullWidth
                              color="primary"
                              variant="contained"
                              onClick={() => onChange(true)}
                              disabled={value}
                              style={{
                                marginTop: 8,
                                height: 38,
                                width: 38,
                                alignSelf: 'start',
                              }}
                            >
                              <CheckIcon />
                            </Button>
                          )}
                        />
                      </Box>
                    </Paper>
                  ))
                : null}
            </CardContent>
          </Card>
        </Box>
      </Box>
    </Box>
  )
}

ReceptionWarehouse.propTypes = {
  disabled: PropTypes.bool,
  setToCompletable: PropTypes.func,
  setToUncompletable: PropTypes.func,
  storeId: PropTypes.string,
  payload: PropTypes.object,
  isCompletable: PropTypes.bool,
  price: PropTypes.string,
}

export default ReceptionWarehouse
