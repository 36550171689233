import { httpClient } from '../../../App/httpClient'
import { RESSOURCE_ID } from '../../task.service'

export const parsePayload = () => {
  return {}
}

export const parseMenu = parsePayload

export const closeStoreForBusiness = async (taskId, secret) => {
  await httpClient.post(
    `${RESSOURCE_ID}/${taskId}/store_status`,
    {
      is_open_for_business: false,
      is_entry_door_unlocked: true,
      store_state: 'maintenance',
    },
    {
      headers: {
        Authorization: `Bearer ${secret}`,
      },
    },
  )
}
