import React from 'react'
import PropTypes from 'prop-types'

import { SwipeableDrawer, Paper, Typography } from '@mui/material'
import { Alert } from '@mui/material'

function TaskGroupTestHelperDrawer({ isOpen, onOpen, onClose }) {
  return (
    <SwipeableDrawer
      anchor={'bottom'}
      open={isOpen}
      onOpen={onOpen}
      onClose={onClose}
    >
      <Paper>
        <Alert severity="warning" aria-label="taskGroup helper drawer">
          <Typography component="div">TASKGROUP TEST HELPER DRAWER</Typography>
        </Alert>
      </Paper>
    </SwipeableDrawer>
  )
}

TaskGroupTestHelperDrawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
}

TaskGroupTestHelperDrawer.defaultProps = {
  onOpen: () => {},
}

export default TaskGroupTestHelperDrawer
