import { DateTime } from 'luxon'

export const parsePayload = (payload) => {
  const {
    product,
    product_count,
    initial_product_count,
    warehouse_shelf,
    expirations = [],
  } = payload

  return {
    product: {
      id: product.product_id,
      name: product.full_name,
      imageUrl: product.image_url || product.product_image_url,
      capacity: product.capacity,
      capacityUnit: product.capacity_unit,
      warehouseShelfCapacity: product.warehouse_shelf_capacity,
      hasExpiration: product.has_expiration,
    },
    warehouseShelf: {
      capacity: warehouse_shelf.capacity || 0,
      id: warehouse_shelf.id,
    },
    expirations: expirations.map(
      ({ expiration_date, product_count, initial_product_count, _id }) => ({
        expirationDate: DateTime.fromISO(expiration_date).toLocal(),
        productCount: product_count,
        initialProductCount: initial_product_count,
        id: _id,
      }),
    ),
    productCount: product_count || 0,
    initialProductCount: initial_product_count || 0,
  }
}

export const parseMenu = (payload) => {
  const { product, product_count, initial_product_count } = payload

  return {
    product: {
      id: product.product_id || product._id,
      name: product.name,
      imageUrl: product.product_image_url,
      barcode: product.barcode,
    },
    productCount: product_count || 0,
    initialProductCount: initial_product_count || 0,
  }
}

export const parseCompletePayload = (payload) => {
  return {
    product_count: payload.productCount,
    expirations: payload.expirations.map(
      ({ expirationDate, id, initialProductCount, productCount }) => ({
        _id: id,
        expiration_date: expirationDate.toISO(),
        initial_product_count: initialProductCount,
        product_count: productCount,
      }),
    ),
  }
}
