import React, { useEffect } from 'react'
import { makeStyles } from '@mui/styles'
import { useErrorHandler } from 'react-error-boundary'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import {
  Table,
  TableContainer,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  Chip,
  Drawer,
  Typography,
  Button,
  Box,
} from '@mui/material'

import useAsyncActionInterval from '../../../hooks/useAsyncActionInterval'
import useForceExitAllCustomers from '../../../hooks/useForceExitAllCustomers'
import LoadingButton from '../../../components/LoadingButton'

import { getStoreStatus } from '../openCloseStore.service'

import { closeStoreForBusiness } from './closeForBusiness.service'

const FETCH_STORE_STATUS_INTERVAL = 5000
const CANNOT_CLOSE_STORE_REGEX = /cannot close store.*customers.*/i

function CloseStore({ id, storeId, setToCompletable }) {
  const classes = useStyles()
  const { t } = useTranslation('CLOSE_STORE')

  const { secret } = useParams()
  const handleError = useErrorHandler()
  const {
    forceExitCalled,
    forceExitDisabled,
    forceExitAllCustomers,
  } = useForceExitAllCustomers(storeId)
  const [
    { isEntryDoorUnlocked, isOpenForBusiness, lastUpdate, lastStatus } = {
      isEntryDoorUnlocked: false,
      isOpenForBusiness: true,
    },
    isFetchingStoreStatus,
    { startInterval, stopInterval },
  ] = useAsyncActionInterval(async () => await getStoreStatus(id, secret), {
    delay: FETCH_STORE_STATUS_INTERVAL,
    immediate: true,
    onError: (error) => handleError(error),
  })
  useEffect(() => {
    if (isEntryDoorUnlocked && !isOpenForBusiness) {
      stopInterval()
      setToCompletable()
    }
  }, [isEntryDoorUnlocked, isOpenForBusiness])

  const onCloseStoreForBusiness = async () => {
    try {
      stopInterval()
      await closeStoreForBusiness(id, secret)
      startInterval()
    } catch (error) {
      handleError(error)
    }
  }

  const canCloseStoreForBusiness = !isEntryDoorUnlocked || isOpenForBusiness

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Box flex={1} role="task-content">
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell variant="head">{t('STORE')}</TableCell>
                <TableCell>
                  {isOpenForBusiness ? (
                    <Chip
                      color="secondary"
                      label={t('OPEN_FOR_BUSINESS_CHIP_STATUS')}
                    />
                  ) : (
                    <Chip
                      color="primary"
                      label={t('CLOSED_FOR_BUSINESS_CHIP_STATUS')}
                    />
                  )}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell variant="head">{t('DOORS')}</TableCell>
                <TableCell>
                  {isEntryDoorUnlocked ? (
                    <Chip
                      color="primary"
                      label={t('DOORS_UNLOCKED_CHIP_STATUS')}
                    />
                  ) : (
                    <Chip
                      color="secondary"
                      label={t('DOORS_LOCKED_CHIP_STATUS')}
                    />
                  )}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell variant="head">{t('LAST_UPDATED')}</TableCell>
                <TableCell>
                  <Tooltip title={'title'}>
                    <span>{lastUpdate}</span>
                  </Tooltip>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Box role="task-actions">
        <LoadingButton
          data-testid="close-for-business-button"
          sx={{ height: '100%', width: '170px' }}
          isLoading={isFetchingStoreStatus}
          color="primary"
          onClick={onCloseStoreForBusiness}
          disabled={!canCloseStoreForBusiness || isFetchingStoreStatus}
          variant="contained"
        >
          {t('CLOSE_FOR_BUSINESS_ACTION')}
        </LoadingButton>
      </Box>

      <Drawer
        anchor="bottom"
        role="force-exit-customers-drawer"
        open={
          !forceExitCalled &&
          CANNOT_CLOSE_STORE_REGEX.test(lastStatus?.state_message)
        }
      >
        <div className={classes.bottomDrawer}>
          <Typography variant="h6">{t('ISSUE_WITH_STORE')}</Typography>
          <Typography variant="h6">
            {t('ASK_CONFIRMATION_FOR_STORE_EMPTINESS')}
          </Typography>
          <Button
            className={classes.confirmStoreEmptinessButton}
            color="primary"
            variant="contained"
            disabled={forceExitDisabled}
            onClick={forceExitAllCustomers}
          >
            {t('CONFIRM_STORE_EMPTINESS')}
          </Button>
        </div>
      </Drawer>
    </Box>
  )
}

CloseStore.propTypes = {
  id: PropTypes.string,
  storeId: PropTypes.string,
  comment: PropTypes.string,
  index: PropTypes.number,
  store: PropTypes.string,
  state: PropTypes.string,
  taskGroup: PropTypes.string,
  setToCompletable: PropTypes.func,
  isCompletable: PropTypes.bool,
}

const useStyles = makeStyles((theme) => ({
  bottomDrawer: {
    padding: `${theme.spacing(12)} 0`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  confirmStoreEmptinessButton: {
    marginTop: theme.spacing(4),
  },
}))

export default CloseStore
