import { httpClient } from '../App/httpClient'

import { parseTaskMenu } from '../Task/task.service'

import { parseTestTaskGroup } from './Test/taskgroupTest.service'
import { parseTourSetsTaskGroup } from './TourSets/toursets.service'
import { parseWarehouseAuditTaskGroup } from './WarehouseAudit/warehouseAudit.service'
import { parseWarehouseRestockingTaskGroup } from './WarehouseRestocking/warehouseRestocking.service'
import { parseWarehouseRestockingFromStoreTaskGroup } from './WarehouseRestockingFromStore/warehouseRestockingFromStore.service'
import { parseManufacturingTaskGroup } from './Manufacturing/manufacturing.service'

export const RESSOURCE_ID = 'task_groups'

export const getTaskGroupbyId = async ({ queryKey }) => {
  const { id, secret } = queryKey[1]
  const { data: taskgroup } = await httpClient.get(`${RESSOURCE_ID}/${id}`, {
    headers: {
      Authorization: `Bearer ${secret}`,
    },
  })

  if (taskgroup.type === 'test') {
    return parseTestTaskGroup(taskgroup)
  }

  if (taskgroup.type === 'tour_sets') {
    return parseTourSetsTaskGroup(taskgroup)
  }

  if (taskgroup.type === 'warehouse_audit') {
    return parseWarehouseAuditTaskGroup(taskgroup)
  }

  if (taskgroup.type === 'manufacturing_tasks') {
    return parseManufacturingTaskGroup(taskgroup)
  }

  if (taskgroup.type === 'warehouse_restocking') {
    return parseWarehouseRestockingTaskGroup(taskgroup)
  }

  if (taskgroup.type === 'warehouse_restocking_from_store') {
    return parseWarehouseRestockingFromStoreTaskGroup(taskgroup)
  }
}

export const getMenu = async ({ queryKey }) => {
  const { id, secret } = queryKey[1]
  let response
  response = await httpClient.get(`${RESSOURCE_ID}/${id}/menu`, {
    headers: {
      Authorization: `Bearer ${secret}`,
    },
  })

  return response.data.map((t) => parseTaskMenu(t))
}

export const completeTaskGroup = async ({ id, secret }) => {
  await httpClient.post(
    `${RESSOURCE_ID}/${id}/complete`,
    {},
    {
      headers: {
        Authorization: `Bearer ${secret}`,
      },
    },
  )
}

export const postImage = async (taskgroupId, base64Image, fileName, secret) => {
  const dataURLtoFile = (dataurl) => {
    const arr = dataurl.split(',')
    const mime = arr[0].match(/:(.*?);/)[1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)
    while (n) {
      u8arr[n - 1] = bstr.charCodeAt(n - 1)
      n -= 1 // to make eslint happy
    }
    const filename = (Math.random() + 1).toString(36).substring(10)
    return new File([u8arr], filename, { type: mime })
  }

  const image = dataURLtoFile(base64Image)

  var bodyFormData = new FormData()
  bodyFormData.append('image', image, fileName)

  const { data } = await httpClient.post(
    `/${RESSOURCE_ID}/${taskgroupId}/upload`,
    bodyFormData,
    {
      headers: {
        Authorization: `Bearer ${secret}`,
        'Content-Type': 'multipart/form-data',
      },
    },
  )

  return data
}
