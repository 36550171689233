/* eslint-disable react/prop-types */
import React, { useEffect, useReducer, useState } from 'react'
import PropTypes from 'prop-types'

import { SwipeableDrawer, Paper } from '@mui/material'

import { makeStyles } from '@mui/styles'

import { reducer, defaultState, stepEnums } from './toursets.reducer'

import { useTask } from '../../Task/task.context'

import Step0 from './steps/Step0'
import Step1 from './steps/Step1'
import Step2 from './steps/Step2'
import Step3 from './steps/Step3'
import Step4 from './steps/Step4'

import TaskGroupContext from './toursets.context'
import { authenticate } from '../../App/app.service'

function RenderStep({ step, token, task }) {
  switch (step) {
    case stepEnums.initialStep:
      return <Step0 />
    case stepEnums.barCode:
      return <Step1 token={token} />
    case stepEnums.reasonStep:
      return <Step2 />
    case stepEnums.feedback:
      return <Step3 task={task} />
    case stepEnums.taskCompleted:
      return <Step4 />
    default:
      return <Step0 />
  }
}

function TaskGroupCommentDrawer({ isOpen, onClose, onOpen }) {
  const { task } = useTask()
  const classes = useStyles()

  const [state, dispatch] = useReducer(reducer, defaultState)
  const [token, setToken] = useState(null)

  useEffect(async () => {
    try {
      const { token: authToken } = await authenticate()
      setToken(authToken)
      dispatch({ action: 'authenticate', payload: authToken })
    } catch (err) {
      console.error(err)
    }
  }, [])

  const handleOnClose = () => {
    dispatch({ action: 'reset' })
    onClose()
  }

  return (
    <SwipeableDrawer
      anchor={'bottom'}
      open={isOpen}
      onOpen={onOpen}
      onClose={handleOnClose}
    >
      <TaskGroupContext api={dispatch} state={state}>
        <Paper>
          <div className={classes.cardContainer}>
            <RenderStep step={state.currentStep} token={token} task={task} />
          </div>
        </Paper>
      </TaskGroupContext>
    </SwipeableDrawer>
  )
}

const useStyles = makeStyles(() => ({
  cardStyle: {
    height: '250px',
    width: '150px',
    cursor: 'pointer',
    flex: '1',
    margin: '30px',
    textAlign: 'center',
    fontSize: '20px',
    borderRadius: '30px',
  },
  cardContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    margin: '30px',
  },
}))

TaskGroupCommentDrawer.defaultProps = {
  onOpen: () => {},
}

RenderStep.propTypes = {
  step: PropTypes.node,
  token: PropTypes.string,
}

TaskGroupCommentDrawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.string,
  disabled: PropTypes.bool,
}

export default TaskGroupCommentDrawer
