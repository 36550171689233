import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@mui/material'

function Congratulation() {
  const { t } = useTranslation('CONGRATULATION')

  return (
    <Box
      role="main"
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100%"
    >
      <Typography variant="h6">{t('CONGRATULATION')}</Typography>
    </Box>
  )
}

export default Congratulation
