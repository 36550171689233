import { datatype } from 'faker'

export const stockout = {
  action: 'stock_out',
  comment: 'Comment from admin',
  agent_comment: 'Comment from agent',
  index: 0,
  state: 'created',
  payload: {},
  store_id: '0000000000000000STORE_ID',
  read_only: false,
}

export const makeStockout = ({ _id, index, state } = {}) => ({
  ...stockout,
  _id: _id || datatype.uuid(),
  index: index || stockout.index,
  state: state || stockout.state,
})
