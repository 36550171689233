import { datatype } from 'faker'

export const audit = {
  action: 'audit',
  comment: 'Comment from admin',
  agent_comment: 'Comment from agent',
  index: 0,
  state: 'created',
  payload: {
    product: {
      product_id: '5d0794866885630014681bb1',
      name: 'Coca-Cola 50cL',
      shelf_id: 'S1-C1-L7',
      store_shelf_group_id: '0000000000SHELF_GROUP_ID',
      section: 'Beverages',
      image_url:
        'https://storebox-api-public-production.s3.eu-west-3.amazonaws.com/product_images/5d0794866885630014681bb1.jpg',
      capacity: 1250,
      capacity_unit: 'ml',
    },
    product_count: 5,
  },
  store_id: '0000000000000000STORE_ID',
  read_only: false,
  //TODO: price should be in audit payload
  display: {
    price: '€ 2.80',
  },
}

export const makeAudit = ({ _id, index, state } = {}) => ({
  ...audit,
  _id: _id || datatype.uuid(),
  index: index || audit.index,
  state: state || audit.state,
})
