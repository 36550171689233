import { date } from 'faker'

import { makeRestockingWarehouse } from '../../Task/RestockingWarehouse/restockingWarehouse.mock'

export const tasks = [
  makeRestockingWarehouse({ _id: 'B00000000000000000000001' }),
  makeRestockingWarehouse({ _id: 'B00000000000000000000002' }),
  makeRestockingWarehouse({ _id: 'B00000000000000000000003' }),
  makeRestockingWarehouse({ _id: 'B00000000000000000000004' }),
  makeRestockingWarehouse({ _id: 'B00000000000000000000005' }),
  makeRestockingWarehouse({ _id: 'B00000000000000000000006' }),
  makeRestockingWarehouse({ _id: 'B00000000000000000000007' }),
  makeRestockingWarehouse({ _id: 'B00000000000000000000008' }),
]

export const warehouseRestocking = {
  _id: 'A00000000000000000000001',
  share_url: 'share/url',
  state: 'created',
  type: 'warehouse_restocking',
  warehouse: {
    _id: '000000000000WAREHOUSE_ID',
    address: '47B rue Ernest Renan',
    map_url: 'http://warehouse_map.url',
    name: 'Ivry',
  },
  created_at: date.past(),
  task_ids: tasks.map((task) => task._id),
  current_task_index: 0,
}

export const makeWarehouseRestocking = ({
  _id,
  current_task_index,
  state,
} = {}) => ({
  ...warehouseRestocking,
  _id: _id || warehouseRestocking._id,
  current_task_index:
    current_task_index || warehouseRestocking.current_task_index,
  state: state || warehouseRestocking.state,
})
