import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Box, Typography } from '@mui/material'
import ApartmentIcon from '@mui/icons-material/Apartment'

const WarehouseTasksTitle = ({ taskgroup: { warehouse } }) => {
  const { t } = useTranslation('HEADER')

  return (
    <Box>
      <Typography variant="h6" data-testid="taskgroup-title">
        {t('WAREHOUSE_RESTOCKING_FROM_STORE_TITLE')}
      </Typography>

      <Box sx={{ gap: 1, display: { xs: 'none', md: 'flex' } }}>
        <ApartmentIcon />
        <Typography variant="subtitle2" data-testid="taskgroup-subtitle">
          {`${warehouse.name} - ${warehouse.address}`}
        </Typography>
      </Box>
    </Box>
  )
}

WarehouseTasksTitle.propTypes = {
  taskgroup: PropTypes.object,
}

export default WarehouseTasksTitle
