import { httpClient } from './httpClient'

const SERVICE_EMAIL = process.env.REACT_APP_SERVICE_EMAIL
const SERVICE_PASSWORD = process.env.REACT_APP_SERVICE_PASSWORD

export const authenticate = async () => {
  const { data, status } = await httpClient.post(`/auth/email`, {
    email: SERVICE_EMAIL,
    password: SERVICE_PASSWORD,
  })

  if (status === 200 || status === 201) {
    return data
  }

  return { token: null }
}
