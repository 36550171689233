import TestMenu from './Test/test.Menu'
import TestTitle from './Test/test.Title'
import TaskgroupTestCommentDrawer from './Test/taskgroupTest.CommentDrawer'
import TaskGroupTestHelperDrawer from './Test/taskgroupTest.HelpDrawer'
import TestNavigation from './Test/test.Navigation'

import TourSetsTitle from './TourSets/toursets.Title'
import TourSetsHelperDrawer from './TourSets/toursets.HelpDrawer'
import TourSetsFeedbackDrawer from './TourSets/toursets.CommentDrawer'
import TourSetsNavigation from './TourSets/toursets.Navigation'
import TourSetsMenu from './TourSets/toursets.Menu'

import WarehouseAuditTitle from './WarehouseAudit/warehouseAudit.Title'
import WarehouseAuditNavigation from './WarehouseAudit/warehouseAudit.Navigation'
import WarehouseAuditMenu from './WarehouseAudit/warehouseAudit.Menu'

import WarehouseRestockingTitle from './WarehouseRestocking/warehouseRestocking.Title'
import WarehouseRestockingNavigation from './WarehouseRestocking/warehouseRestocking.Navigation'
import WarehouseRestockingMenu from './WarehouseRestocking/warehouseRestocking.Menu'

import WarehouseRestockingFromStoreTitle from './WarehouseRestockingFromStore/warehouseRestockingFromStore.Title'
import WarehouseRestockingFromStoreNavigation from './WarehouseRestockingFromStore/warehouseRestockingFromStore.Navigation'
import WarehouseRestockingFromStoreMenu from './WarehouseRestockingFromStore/warehouseRestockingFromStore.Menu'

import ManufacturingTitle from './Manufacturing/manufacturing.Title'
import ManufacturingNavigation from './Manufacturing/manufacturing.Navigation'

export const TASKGROUP_CONFIGURATION = {
  TEST: {
    FeedbackDrawer: TaskgroupTestCommentDrawer,
    HelperDrawer: TaskGroupTestHelperDrawer,
    Menu: TestMenu,
    Navigation: TestNavigation,
    scanner: false,
    Title: TestTitle,
    type: 'test',
  },
  TOUR_SETS: {
    type: 'tour_sets',
    HelperDrawer: TourSetsHelperDrawer,
    FeedbackDrawer: TourSetsFeedbackDrawer,
    Navigation: TourSetsNavigation,
    Title: TourSetsTitle,
    Menu: TourSetsMenu,
    scanner: false,
  },
  WAREHOUSE_AUDIT: {
    type: 'warehouse_audit',
    Navigation: WarehouseAuditNavigation,
    Title: WarehouseAuditTitle,
    Menu: WarehouseAuditMenu,
    scanner: true,
  },
  WAREHOUSE_RESTOCKING: {
    type: 'warehouse_restocking',
    Navigation: WarehouseRestockingNavigation,
    Title: WarehouseRestockingTitle,
    Menu: WarehouseRestockingMenu,
    scanner: true,
  },
  WAREHOUSE_RESTOCKING_FROM_STORE: {
    type: 'warehouse_restocking_from_store',
    Navigation: WarehouseRestockingFromStoreNavigation,
    Title: WarehouseRestockingFromStoreTitle,
    Menu: WarehouseRestockingFromStoreMenu,
    scanner: true,
  },
  MANUFACTURING: {
    type: 'manufacturing_tasks',
    Navigation: ManufacturingNavigation,
    // manufacturing should have his own feedback drawer see T-2267 Perrine's comment
    FeedbackDrawer: TourSetsFeedbackDrawer,
    Title: ManufacturingTitle,
    // manufacturing should have his own menu
    Menu: TourSetsMenu,
    scanner: false,
  },
}
