import React, { useContext, useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import Webcam from 'react-webcam'

import SendIcon from '@mui/icons-material/Send'

import { makeStyles } from '@mui/styles'
import { Button, Card, CardContent, TextField, Typography } from '@mui/material'

import { TaskGroupAPIContext } from '../toursets.context'
import { getProductsFromBarcode } from '../../../Products/products.service'

const BarcodeScannerComponent = ({
  token,
  product,
  setProduct,
  setErrorMsg,
}) => {
  const classes = useStyles()
  const webcamRef = useRef(null)

  const formats = ['code_39', 'ean_8', 'ean_13', 'qr_code']
  let barcodeDetector
  try {
    // eslint-disable-next-line
    barcodeDetector = new window.BarcodeDetector({ formats })
  } catch {
    console.error('Barcode detector not available')
  }
  const detectBarcode = async (img) => {
    try {
      const detectedObjs = await barcodeDetector.detect(img)
      if (!detectedObjs.length) {
        return { value: null }
      }

      const obj = detectedObjs[0]
      if (obj.format === 'qr_code') {
        return { format: obj.format, value: obj.rawValue }
      }

      return { value: obj.rawValue }
    } catch (err) {
      return { value: null }
    }
  }

  const capture = async () => {
    const imageSrc = webcamRef?.current?.getScreenshot()

    if (imageSrc) {
      const img = new Image()
      img.src = imageSrc
      img.onload = async function () {
        const { value } = await detectBarcode(img)
        if (value) {
          const products = await getProductsFromBarcode(value, token)
          if (products && products.length === 1) {
            setProduct(products[0])
          } else {
            setErrorMsg("Le produit n'existe pas")
          }
        }
      }
    }
  }

  useEffect(() => {
    let interval

    if (barcodeDetector) {
      interval = setInterval(capture, 100)
    }

    return () => clearInterval(interval)
  }, [])

  return (
    <>
      {!product && (
        <div>
          {barcodeDetector && (
            <Webcam
              audio={false}
              width={300}
              height={150}
              videoConstraints={{
                facingMode: 'environment',
                aspectRatio: 2,
              }}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              screenshotQuality={1}
            />
          )}
          {!barcodeDetector && (
            <Typography>Barcode detection unavailable</Typography>
          )}
        </div>
      )}
      {product && (
        <div>
          <Typography>{product.name}</Typography>
          <img src={product.product_image_url} width="100" />
          <div className={classes.actionContainer}></div>
          <Button
            color="primary"
            variant="contained"
            className={classes.mtb}
            onClick={() => {
              setProduct(null)
            }}
          >
            Recommencer
          </Button>
        </div>
      )}
    </>
  )
}

BarcodeScannerComponent.propTypes = {
  token: PropTypes.string,
  product: PropTypes.object,
  setProduct: PropTypes.func,
  setErrorMsg: PropTypes.func,
}

function Step1({ token }) {
  const classes = useStyles()
  const [product, setProduct] = useState(null)
  const [productEAN, setProductEAN] = useState('')
  const [errorMsg, setErrorMsg] = useState('')
  const dispatch = useContext(TaskGroupAPIContext)

  const fetchProduct = async () => {
    try {
      const products = await getProductsFromBarcode(productEAN, token)
      if (products && products.length === 1) {
        setProduct(products[0])
      } else {
        setErrorMsg("Le produit n'existe pas")
      }
    } catch (err) {
      console.error(err)
      setErrorMsg(`Une erreur s'est produite.`)
    }
  }

  useEffect(() => {
    setErrorMsg('')
  }, [product, productEAN])

  return (
    <>
      <Card elevation={0} className={classes.cardStyle}>
        <CardContent>
          <Typography>Scannez votre Code/QR</Typography>
        </CardContent>
        <BarcodeScannerComponent
          token={token}
          product={product}
          setProduct={setProduct}
          setErrorMsg={setErrorMsg}
        />
        {!product && (
          <div>
            <div className={classes.mtb}>
              <Typography>ou entrez-le à la main</Typography>
            </div>
            <div>
              <TextField
                variant="standard"
                autoComplete="off"
                inputProps={{
                  'data-testid': 'feedback-barcode-input',
                  style: { width: '250px', textAlign: 'center' },
                }}
                id="standard-basic"
                placeholder="Numero EAN"
                value={productEAN}
                onChange={(text) => setProductEAN(text.target.value)}
              />
              <Button
                data-testid="feedback-validate-barcode-button"
                aria-label="taskGroup code validation button"
                color="primary"
                variant="contained"
                className={classes.validateButton}
                onClick={() => fetchProduct()}
              >
                Valider
              </Button>
            </div>
          </div>
        )}
        {!product && errorMsg && (
          <Typography className={(classes.mtb, classes.errorMessage)}>
            {errorMsg}
          </Typography>
        )}
      </Card>
      <div className={classes.actionContainer}>
        <Button
          data-testId="feedback-next-step-button"
          disabled={!product}
          aria-label="taskGroup validate product button"
          className={classes.button}
          color="primary"
          variant="contained"
          onClick={() => dispatch({ action: 'setProduct', payload: product })}
        >
          <SendIcon />
        </Button>
      </div>
    </>
  )
}

const useStyles = makeStyles(() => ({
  cardStyle: {
    height: '350px',
    width: '150px',
    cursor: 'pointer',
    flex: '1',
    margin: '30px',
    textAlign: 'center',
    fontSize: '20px',
    borderRadius: '30px',
    border: '2px solid #808080',
  },
  validateButton: {
    marginLeft: '15px',
  },
  mtb: {
    marginTop: '10px',
    marginBottom: '10px',
    fontWeight: 'bold',
  },
  errorMessage: {
    color: 'red',
    fontSize: '20px',
    marginTop: '10px',
    fontWeight: 'bold',
  },
  cardContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  actionContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
  },
  button: {
    height: '60px',
    width: '125px',
  },
}))

Step1.propTypes = {
  token: PropTypes.string,
}

export default Step1
