import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Alert } from '@mui/material'
import { Box, Typography } from '@mui/material'
import { differenceInWeeks, parseISO } from 'date-fns'

import Counter from '../../components/Counter'
import Product from '../../Products/Product'
import WarehouseMap from '../../Products/WarehouseMap'

const ERROR_TRESHOLD_WEEK_DIFF = 1

function Picking({
  disabled,
  setToCompletable,
  payload: {
    product,
    productCount: initialProductCount,
    firstProductExpirationDate,
  },
  firstProductExpirationDisplayDate,
}) {
  const { t } = useTranslation('PICKING')

  const [productCount, setProductCount] = useState(initialProductCount)

  useEffect(() => {
    setToCompletable({ productCount })
  }, [productCount])

  const expirationDateAlertSeverity =
    differenceInWeeks(parseISO(firstProductExpirationDate), new Date()) <
    ERROR_TRESHOLD_WEEK_DIFF
      ? 'error'
      : 'warning'

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Box flex={1}>
        <Product
          {...product}
          map={<WarehouseMap shelfId={product.shelfId} />}
        />
      </Box>

      <Box role="task-actions">
        {firstProductExpirationDisplayDate && (
          <Alert
            sx={{ marginTop: 2 }}
            icon={false}
            role="first-product-expiration-date-alert"
            severity={expirationDateAlertSeverity}
          >
            <Typography variant="h5">
              {t('DLC_FIRST_PICK_DATE_LABEL', {
                firstProductExpirationDate: firstProductExpirationDisplayDate,
              })}
            </Typography>
          </Alert>
        )}

        <Counter
          dataTestid="product"
          sx={{ marginTop: 2 }}
          disabled={disabled}
          value={productCount}
          label={t('COUNTER_LABEL')}
          validate={({ value }) => value >= 0}
          onChange={setProductCount}
        />
      </Box>
    </Box>
  )
}

Picking.propTypes = {
  disabled: PropTypes.bool,
  setToCompletable: PropTypes.func,
  payload: PropTypes.object,
  isCompletable: PropTypes.bool,
  firstProductExpirationDisplayDate: PropTypes.string,
}

export default Picking
