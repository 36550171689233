import useAsyncAction from './useAsyncAction'
import { authenticate } from '../App/app.service'
import * as storeService from '../Stores/stores.service'
import { useErrorHandler } from 'react-error-boundary'
import { useState } from 'react'

function useForceExitAllCustomers(storeId) {
  const [forceExitCalled, setForceExitCalled] = useState(false)
  const handleError = useErrorHandler()

  const [
    authenticationData,
    authenticationInProgress,
  ] = useAsyncAction(authenticate, { immediate: true, onError: handleError })

  const [, forceExitInProgress, forceExitAllCustomers] = useAsyncAction(
    () =>
      storeService.forceExitAllCustomers(storeId, authenticationData?.token),
    {
      immediate: false,
      onError: handleError,
    },
  )

  return {
    forceExitCalled,
    forceExitDisabled:
      authenticationInProgress || forceExitInProgress || forceExitCalled,
    forceExitAllCustomers() {
      forceExitAllCustomers()
      setForceExitCalled(true)
    },
  }
}

export default useForceExitAllCustomers
