import { rest } from 'msw'

const products = [
  {
    _id: '5d0794866885630014681bb1',
    barcode: '3392460511200',
    name: 'Coca-Cola 50cL',
    product_image_url:
      'https://storebox-api-public-production.s3.eu-west-3.amazonaws.com/product_images/5d0794866885630014681bb1.jpg',
  },
  {
    _id: '5de8c77945f5a4587280e81f',
    barcode: '3242272250050',
    name: 'PastaBox - Tortellini Ricotta Épinards sauce au Parmesan',
    product_image_url:
      'https://storebox-api-public-production.s3.amazonaws.com/product_images/5de8c77945f5a4587280e81f.jpeg',
  },
  {
    _id: '5ed0bd65495f8b00042ab480',
    barcode: '8000500310427',
    name: 'Nutella - Biscuits fourrés',
    product_image_url:
      'https://storebox-api-public-production.s3.eu-west-3.amazonaws.com/product_images/5ed0bd65495f8b00042ab480.jpeg',
  },
]
const getProducts = (filter) => {
  return products.filter((p) => p._id === filter || p.barcode === filter)
}

export const handlers = [
  rest.get(`http://localhost:3000/products`, (req, res, ctx) => {
    const filter = req.url.searchParams.get('filter')
    return res(ctx.status(200), ctx.json(getProducts(filter)))
  }),
]
