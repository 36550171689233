/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import { useTranslation } from 'react-i18next'

import Header from '../../Layout/Header'

import {
  Avatar,
  Box,
  Button,
  Dialog,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  ListSubheader,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

const TaskItemMenu = ({ task, selected, onClick, dataTestid }) => {
  const { t } = useTranslation('MENU')
  const { state, payload } = task
  const { productCount, initialProductCount, product } = payload

  const quantity = useMemo(() => {
    if (state !== 'completed') {
      return t('QUANTITY_EXPECTED', { quantity: initialProductCount })
    }

    if (productCount !== initialProductCount) {
      return t('QUANTITY_CONFIRMED_EXPECTED', {
        confirmedQuantity: productCount,
        expectedQuantity: initialProductCount,
      })
    }

    if (productCount === initialProductCount) {
      return t('QUANTITY_CONFIRMED', { quantity: productCount })
    }
  }, [productCount, initialProductCount, state])

  return (
    <ListItemButton
      sx={{ borderRadius: 4, minHeight: 97 }}
      onClick={onClick}
      selected={selected}
      data-testid={dataTestid}
    >
      <ListItemAvatar>
        <Avatar
          sx={{ width: 56, height: 56, marginRight: 5 }}
          src={product.imageUrl}
        />
      </ListItemAvatar>
      <ListItemText primary={product.name} secondary={quantity} />
    </ListItemButton>
  )
}

const Menu = ({ open, tasks, onClose, onSelect }) => {
  const { t } = useTranslation('MENU')
  const { taskId: currentTaskId } = useParams()
  const islandscape = useMediaQuery({ query: '(orientation: landscape)' })

  const uncompletedTasks = useMemo(
    () =>
      tasks.filter(
        ({ state }) => state === 'created' || state === 'in_progress',
      ),
    [tasks],
  )

  const completedWithDeltaTasks = useMemo(
    () =>
      tasks.filter(
        ({ state, payload }) =>
          state === 'completed' &&
          payload.productCount !== payload.initialProductCount,
      ),
    [tasks],
  )

  const completedTasks = useMemo(
    () =>
      tasks.filter(
        ({ state, payload }) =>
          state === 'completed' &&
          payload.productCount === payload.initialProductCount,
      ),
    [tasks],
  )

  const HEADER_HEIGHT = '64px'

  return (
    <Dialog fullScreen open={open} onClose={onClose}>
      <Box role="app-header" height={HEADER_HEIGHT}>
        <Header
          height={HEADER_HEIGHT}
          actions={
            <Button
              sx={{
                color: 'white',
              }}
              onClick={onClose}
              endIcon={<CloseIcon />}
            >
              {t('CLOSE_MENU')}
            </Button>
          }
        />
      </Box>

      <Box
        display="flex"
        height={`calc(100% - ${HEADER_HEIGHT})`}
        flexDirection={islandscape ? 'row' : 'column'}
      >
        <List
          subheader={
            <ListSubheader color="primary">{t('TO_DO')}</ListSubheader>
          }
          sx={{
            flex: '1 1 0',
            overflowY: 'scroll',
            overflowX: 'hidden',
            paddingLeft: { xs: 1, lg: 2, xl: 3 },
            paddingRight: { xs: 1, lg: 2, xl: 3 },
          }}
        >
          {uncompletedTasks.map((t, index) => (
            <TaskItemMenu
              dataTestid={`menu-todo-item-${index}`}
              key={t.id}
              task={t}
              selected={t.id === currentTaskId}
              onClick={() => onSelect(t.id)}
            />
          ))}
        </List>

        <List
          subheader={
            <ListSubheader color="primary">{t('VALIDATED')}</ListSubheader>
          }
          sx={{
            flex: '1 1 0',
            overflowY: 'scroll',
            overflowX: 'hidden',
            paddingLeft: { xs: 1, lg: 2, xl: 3 },
            paddingRight: { xs: 1, lg: 2, xl: 3 },
          }}
        >
          <ListSubheader>{t('WITH_DELTA')}</ListSubheader>
          {completedWithDeltaTasks.map((t, index) => (
            <TaskItemMenu
              dataTestid={`menu-validated-with-delata-item-${index}`}
              key={t.id}
              task={t}
              selected={t.id === currentTaskId}
              onClick={() => onSelect(t.id)}
            />
          ))}
          <ListSubheader>{t('WITHOUT_DELTA')}</ListSubheader>
          {completedTasks.map((t, index) => (
            <TaskItemMenu
              dataTestid={`menu-validated-without-delta-item-${index}`}
              key={t.id}
              task={t}
              selected={t.id === currentTaskId}
              onClick={() => onSelect(t.id)}
            />
          ))}
        </List>
      </Box>
    </Dialog>
  )
}

export default Menu
