import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Typography, Box } from '@mui/material'
import { Alert } from '@mui/material'
import { makeStyles } from '@mui/styles'

import Counter from '../../components/Counter'
import Product from '../../Products/Product'
import StoreSectionMap from '../../Stores/StoreSectionMap'

function Return({
  disabled,
  setToCompletable,
  setToUncompletable,
  storeId,
  payload: { product, initialProductCountToReturn },
  price,
}) {
  const classes = useStyles()
  const { t } = useTranslation('RETURN')
  const [productCountToReturn, setProductCountToReturn] = useState(
    initialProductCountToReturn,
  )

  useEffect(() => {
    if (productCountToReturn > -1) {
      setToCompletable({ productCountToReturn })
    } else {
      setToUncompletable()
    }
  }, [productCountToReturn])

  if (!product.shelfGroupId) {
    return (
      <>
        <section
          className={classes.noProductHeading}
          role="main"
          aria-label="no product"
        >
          <Typography variant="h5" color="textPrimary">
            {t('NOTHING_TO_DO')}
          </Typography>
        </section>
        <Alert severity="info">{t('NOTHING_TO_DO')}</Alert>
      </>
    )
  }

  const section = product.shelfId.split('-')[0]

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Box flex={1}>
        <Product
          {...product}
          map={
            <StoreSectionMap
              storeId={storeId}
              section={section}
              shelfGroupId={product.shelfGroupId}
            />
          }
        />
      </Box>

      <Box role="task-actions" display="flex" gap={1}>
        <Counter
          dataTestid="product"
          disabled={disabled}
          value={productCountToReturn}
          label={t('COUNTER_LABEL')}
          onChange={setProductCountToReturn}
        />

        <Alert role="product-price-alert" severity="warning" icon={false}>
          <Typography variant="body1">{price}</Typography>
        </Alert>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles(() => ({
  actions: {
    gridArea: 'actions',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  content: {
    width: '100%',
    gridArea: 'content',
    position: 'relative',
    top: 0,
    left: 0,
  },
  noProductHeading: { display: 'flex', alignItems: 'center' },
}))

Return.propTypes = {
  disabled: PropTypes.bool,
  setToCompletable: PropTypes.func,
  setToUncompletable: PropTypes.func,
  storeId: PropTypes.string,
  payload: PropTypes.object,
  isCompletable: PropTypes.bool,
  price: PropTypes.string,
}

export default Return
