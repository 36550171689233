import { useCallback, useMemo } from 'react'
import { useLocation, useParams, useHistory } from 'react-router-dom'

const useUrlParamsState = (paramName, options) => {
  const { basePath } = options || { replace: false, basePath: '' }

  const urlParams = useParams()
  const history = useHistory()
  const location = useLocation()

  const value = useMemo(() => {
    return urlParams[paramName] || null
  }, [urlParams, paramName])

  const syncedSetState = useCallback(
    (newValue, { replace } = { replace: false }) => {
      let path = ``
      if (value) {
        path = location.pathname.replace(value, newValue)
      } else {
        path = basePath.replace(`:${paramName}`, newValue)
      }

      if (replace) {
        history.replace(path)
      } else {
        history.push(path)
      }
    },
    [urlParams, location, value],
  )

  return [value, syncedSetState]
}

export default useUrlParamsState
