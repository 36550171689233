import React, { useContext } from 'react'

import { makeStyles } from '@mui/styles'
import { Card, CardContent, Typography } from '@mui/material'

import { TaskGroupAPIContext, TaskGroupStateContext } from '../toursets.context'

function Step2() {
  const classes = useStyles()
  const dispatch = useContext(TaskGroupAPIContext)
  const state = useContext(TaskGroupStateContext)

  return (
    <>
      {state.product && (
        <Card className={classes.cardStyle}>
          <CardContent>
            <div>
              <Typography className={classes.productText}>
                {state.product.name}
              </Typography>
              <img src={state.product.product_image_url} width="100" />
            </div>
          </CardContent>
        </Card>
      )}
      <div className={classes.taskContainer}>
        <div className={classes.taskLine}>
          <Card
            role="feedback-reason-not_in_shelf"
            data-testId="feedback-reason-not-in-shelf-button"
            onClick={() =>
              dispatch({ action: 'setReason', payload: 'not_in_shelf' })
            }
            className={classes.cardStyle}
          >
            <CardContent>
              <Typography className={classes.cardContent}>
                Aucun emplacement
              </Typography>
            </CardContent>
          </Card>
          <Card
            role="feedback-reason-full_shelf"
            data-testId="feedback-reason-full-shelf-button"
            onClick={() =>
              dispatch({ action: 'setReason', payload: 'full_shelf' })
            }
            className={classes.cardStyle}
          >
            <CardContent>
              <Typography className={classes.cardContent}>
                Étagère pleine
              </Typography>
            </CardContent>
          </Card>
          <Card
            role="feedback-reason-missing_price_tag"
            data-testId="feedback-reason-missing-price-button"
            onClick={() =>
              dispatch({ action: 'setReason', payload: 'missing_price_tag' })
            }
            className={classes.cardStyle}
          >
            <CardContent>
              <Typography className={classes.cardContent}>
                Étiquette manquante
              </Typography>
            </CardContent>
          </Card>
        </div>
        <div className={classes.taskLine}>
          <Card
            role="feedback-reason-expired"
            data-testId="feedback-reason-expired-button"
            onClick={() =>
              dispatch({ action: 'setReason', payload: 'expired' })
            }
            className={classes.cardStyle}
          >
            <CardContent>
              <Typography className={classes.cardContent}>
                Produit périmé
              </Typography>
            </CardContent>
          </Card>
          <Card
            data-testId="feedback-reason-damaged-button"
            role="feedback-reason-damaged"
            onClick={() =>
              dispatch({ action: 'setReason', payload: 'damaged' })
            }
            className={classes.cardStyle}
          >
            <CardContent>
              <Typography className={classes.cardContent}>
                Produit abîmé
              </Typography>
            </CardContent>
          </Card>
          <Card
            role="feedback-reason-other"
            data-testId="feedback-reason-other-button"
            onClick={() => dispatch({ action: 'setReason', payload: 'other' })}
            className={classes.cardStyle}
          >
            <CardContent>
              <Typography className={classes.cardContent}>Autres</Typography>
            </CardContent>
          </Card>
        </div>
      </div>
    </>
  )
}

const useStyles = makeStyles(() => ({
  cardStyle: {
    height: '160px',
    flexBasis: '275px',
    cursor: 'pointer',
    flex: '1',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '25px',
    borderRadius: '30px',
    backgroundColor: '#E6E6E6',
    boxShadow: 'none',
    flexGrow: 0,
    flexShrink: 0,
    marginTop: '25px',
    marginRight: '30px',
  },
  cardContent: {
    height: '115px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  taskContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  taskLine: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-evenly;',
  },
  productText: {
    fontSize: '16px',
  },
}))

export default Step2
