import Compressor from 'compressorjs'

let imageCaptureSupported = 'ImageCapture' in window

const IMAGE_QUALITY = 0.6

const ImageCapture = imageCaptureSupported
  ? window.ImageCapture
  : class MockedImageCapture {
      takePhoto() {
        return new Blob([])
      }
    }

class CustomImageCapture extends ImageCapture {
  constructor(...params) {
    super(...params)
  }

  async takeBase64Photo(constraints) {
    const blob = await this.takePhoto(constraints)
    const reader = new FileReader()

    new Compressor(blob, {
      quality: IMAGE_QUALITY,
      success: (blob) => {
        reader.readAsDataURL(blob)
      },
    })

    return new Promise((resolve) => {
      reader.onloadend = () => resolve(reader.result)
    })
  }
}

export default CustomImageCapture
