import { datatype } from 'faker'

export const targetShelves = {
  _id: datatype.uuid(),
  action: 'target_shelves',
  comment: 'Comment from admin',
  agent_comment: 'Comment from agent',
  index: 0,
  state: 'created',
  payload: {},
  store_id: '0000000000000000STORE_ID',
  read_only: false,
}

export const makeTargetShelves = ({ _id, index, state } = {}) => ({
  ...targetShelves,
  _id: _id || targetShelves._id,
  index: index || targetShelves.index,
  state: state || targetShelves.state,
})
