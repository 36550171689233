import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { withErrorBoundary } from 'react-error-boundary'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

import { Alert, Typography, CircularProgress, Box } from '@mui/material'

import TaskErrorCard, { TaskError } from './TaskError'
import { useTask } from './task.context'

const markdownAllowedElements = ['p', 'a', 'em', 'strong']

function Task() {
  const { t } = useTranslation('TASK')

  const {
    task = { config: {}, payload: {} },
    setToCompletable,
    setToUncompletable,
    isCompletable,
    isLoading,
  } = useTask()

  const TaskComponent = task?.config?.Component

  if (!task.id && !isLoading) {
    throw new TaskError(task, t('NO_TASK_ERROR_MESSAGE'))
  } else if (!TaskComponent && !isLoading) {
    throw new TaskError(task, t('UNKNOWN_TASK_ACTION_ERROR_MESSAGE'))
  }

  let title
  if (task.payload.customTitle) {
    title = task.payload.customTitle
  } else {
    title = t(task.config.title)
  }

  const subtitle = task.config.subtitle || ''
  const ariaLabel = task.config.ariaLabel || ''

  if (isLoading) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100%"
      >
        <CircularProgress
          role="progressbar"
          aria-busy="true"
          data-testid="task-progressbar"
        />
      </Box>
    )
  }

  return (
    <Box
      role="task"
      display="flex"
      flexDirection="column"
      gap={1}
      height="100%"
    >
      <Box role="task-header">
        <ReactMarkdown
          linkTarget="_blank"
          sx={{
            '& p': {
              fontSize: '1rem',
              margin: 0,
            },
          }}
          allowedElements={markdownAllowedElements}
          remarkPlugins={[remarkGfm]}
          components={{
            // eslint-disable-next-line react/prop-types
            p({ children }) {
              return (
                <Typography
                  aria-label="task title"
                  variant="h6"
                  data-testid="task-title"
                >
                  {children}
                </Typography>
              )
            },
          }}
        >
          {title}
        </ReactMarkdown>

        <Typography
          aria-label="product section"
          variant="h6"
          color="textSecondary"
          data-testid="task-subtitle"
        >
          {t(subtitle)}
        </Typography>
      </Box>

      <Box
        role="task-content"
        aria-label={ariaLabel}
        flex="1 1 0"
        overflow="auto"
        height="100%"
      >
        <TaskComponent
          {...task}
          disabled={task.readOnly}
          setToCompletable={setToCompletable}
          setToUncompletable={setToUncompletable}
          isCompletable={isCompletable}
        />
      </Box>

      {task.comment && (
        <Box>
          <Alert
            role="task-comment"
            severity="info"
            data-testid="task-admin-comment"
          >
            <ReactMarkdown
              linkTarget="_blank"
              components={{
                // eslint-disable-next-line react/prop-types
                p({ children }) {
                  return <p style={{ margin: 0 }}>{children}</p>
                },
              }}
              allowedElements={markdownAllowedElements}
            >
              {task.comment}
            </ReactMarkdown>
          </Alert>
        </Box>
      )}
    </Box>
  )
}

Task.propTypes = {
  action: PropTypes.string,
  isCompletable: PropTypes.bool,
  setToCompletable: PropTypes.func,
  setToUncompletable: PropTypes.func,
}

export default withErrorBoundary(Task, {
  FallbackComponent: TaskErrorCard,
})
