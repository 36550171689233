import React from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@mui/styles'
import { useMediaQuery } from 'react-responsive'
import clsx from 'clsx'

function Page({ header, navigation, children, ...props }) {
  const classes = useStyles()
  const islandscape = useMediaQuery({ query: '(orientation: landscape)' })

  return (
    <main
      className={clsx({
        [classes.root]: true,
        [classes.landscape]: islandscape,
        [classes.portrait]: !islandscape,
        [classes.noNavigation]: !navigation,
      })}
      {...props}
    >
      <section role="app-header" className={classes.header}>
        {header}
      </section>
      <section role="app-content" className={classes.content}>
        {children}
      </section>
      {navigation && (
        <section
          role="app-navigation"
          className={clsx({
            [classes.navigation]: true,
            [classes.navigationLandscape]: islandscape,
          })}
        >
          {navigation}
        </section>
      )}
    </main>
  )
}

Page.propTypes = {
  header: PropTypes.element,
  navigation: PropTypes.element,
  children: PropTypes.arrayOf(PropTypes.element),
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    height: '100%',
    minHeight: '-webkit-fill-available',
  },
  landscape: {
    gridTemplateColumns: '80% 20%',
    gridTemplateRows: 'min-content auto',
    gridTemplateAreas: "'header header' 'content navigation'",
  },
  noNavigation: {
    gridTemplateColumns: '100%',
    gridTemplateRows: 'min-content auto',
    gridTemplateAreas: "'header' 'content'",
    paddingRight: 0,
  },
  portrait: {
    gridTemplateColumns: '100%',
    gridTemplateRows: 'min-content auto 15%',
    gridTemplateAreas: "'header' 'content' 'navigation'",
  },
  header: {
    gridArea: 'header',
  },
  content: {
    gridArea: 'content',

    minHeight: '0',
    minWidth: '0',
  },
  navigation: {
    gridArea: 'navigation',

    display: 'flex',
    flexDirection: 'row',

    paddingTop: theme.spacing(0),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  navigationLandscape: {
    flexDirection: 'column',
    paddingTop: theme.spacing(3),
  },
}))

export default Page
