/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useReducer } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { isNil, toNumber } from 'lodash'

import { makeStyles } from '@mui/styles'
import { Button, TextField, Badge, Box } from '@mui/material'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded'

function counterReducer(state, action) {
  switch (action.type) {
    case 'INCREMENT': {
      return {
        ...state,
        count: Number.isInteger(state.count)
          ? state.count + 1
          : Math.ceil(state.count),
      }
    }
    case 'DECREMENT': {
      return {
        ...state,
        count: Number.isInteger(state.count)
          ? state.count - 1
          : Math.floor(state.count),
      }
    }
    case 'SET': {
      return { ...state, count: action.payload }
    }
    default: {
      throw new Error(`Counter: Unhandled action type: ${action.type}`)
    }
  }
}

function Counter({
  disabled,
  onChange,
  validate,
  value,
  initialValue,
  label,
  max,
  error,
  keyboardInput,
  sx,
  dataTestid,
}) {
  const classes = useStyles()
  const [state, dispatch] = useReducer(counterReducer, {
    count: value,
  })
  const { count } = state

  const increment = () => {
    if (validate({ value: count + 1, previousValue: count })) {
      dispatch({ type: 'INCREMENT' })
    }
  }
  const decrement = () => {
    if (validate({ value: count - 1, previousValue: count })) {
      dispatch({ type: 'DECREMENT' })
    }
  }

  useEffect(() => {
    onChange(count)
  }, [count])

  useEffect(() => {
    if (value !== count) {
      dispatch({ type: 'SET', payload: value })
    }
  }, [value])

  const delta = useMemo(() => {
    if (isNil(initialValue)) {
      return null
    }

    const _delta = value - initialValue

    if (_delta === 0) {
      return null
    }

    return _delta
  }, [value, initialValue])

  const inputValue = useMemo(() => {
    if (max) {
      return `${count} / ${max}`
    }

    return count.toString()
  }, [count, max])

  return (
    <Box className={classes.controls} sx={sx}>
      <Button
        className={classes.iconButton}
        data-testid={`${dataTestid}-decrement-counter-button`}
        variant="outlined"
        aria-label="remove"
        disabled={
          disabled || !validate({ value: count - 1, previousValue: count })
        }
        onClick={decrement}
      >
        <RemoveRoundedIcon className={classes.icon} />
      </Button>
      <Badge
        style={{ flex: 1 }}
        badgeContent={delta < 0 || delta === null ? delta : `+${delta}`}
        color={delta < 0 ? 'secondary' : 'primary'}
        anchorOrigin={{
          vertical: 'top',
          horizontal: delta < 0 ? 'left' : 'right',
        }}
      >
        <TextField
          fullWidth
          className={classes.inputContent}
          onChange={(e) => {
            return dispatch({
              type: 'SET',
              payload: toNumber(e.target.value),
            })
          }}
          InputProps={{
            inputProps: {
              'data-testid': `${dataTestid}-counter-input`,
            },
            type: keyboardInput ? 'number' : 'text',
            role: 'textbox',
            readOnly: !keyboardInput,
            className: clsx({
              [classes.inputContentEnabled]: !disabled,
              [classes.inputContentDisabled]: disabled,
            }),
          }}
          error={error}
          label={label}
          size="small"
          variant="outlined"
          value={inputValue}
        />
      </Badge>

      <Button
        className={classes.iconButton}
        variant="outlined"
        aria-label="add"
        data-testid={`${dataTestid}-increment-counter-button`}
        disabled={
          disabled || !validate({ value: count + 1, previousValue: count })
        }
        onClick={increment}
      >
        <AddRoundedIcon className={classes.icon} />
      </Button>
    </Box>
  )
}

Counter.defaultProps = {
  validate: () => true,
}

Counter.propTypes = {
  value: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  validate: PropTypes.func,
  label: PropTypes.string,
  initialValue: PropTypes.number,
  error: PropTypes.bool,
  keyboardInput: PropTypes.bool,
  dataTestid: PropTypes.string,
}

const useStyles = makeStyles({
  inputContentEnabled: {
    color: '#000000 !important',
  },
  inputContentDisabled: {
    color: '#bdbdbd !important',
  },
  inputContent: {
    flex: 1,
    marginLeft: 10,
    marginRight: 10,
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    flex: '1',
  },
  iconButton: {
    padding: 0,
  },
  icon: {
    height: 38,
    width: 38,
  },
})

export default Counter
