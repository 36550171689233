import { date } from 'faker'

export const auditWarehouse = {
  action: 'audit_warehouse_shelf',
  comment: 'Comment from admin',
  agent_comment: 'Comment from agent',
  index: 0,
  state: 'created',
  payload: {
    expirations: [
      {
        _id: '00000000000EXPIRATION_ID',
        expiration_date: date.future(),
        initial_product_count: 12,
        product_count: 12,
      },
      {
        _id: '10000000000EXPIRATION_ID',
        expiration_date: date.past(),
        initial_product_count: 12,
        product_count: 12,
      },
    ],
    product: {
      capacity: 304,
      capacity_unit: 'g',
      full_name: 'FERRERO Nutella - Biscuits fourrés',
      name: 'FERRERO Nutella - Biscuits fourrés',
      has_expiration: true,
      image_url:
        'https://storebox-api-public-production.s3.eu-west-3.amazonaws.com/product_images/5ed0bd65495f8b00042ab480.jpg',
      product_image_url:
        'https://storebox-api-public-production.s3.eu-west-3.amazonaws.com/product_images/5ed0bd65495f8b00042ab480.jpeg',
    },
    product_count: 24,
    warehouse_shelf: {
      capacity: 42,
      id: 'Z01-A02-S01-L04-C01',
    },
  },
  warehouse_id: '000000000000WAREHOUSE_ID',
  read_only: false,
}

export const makeAuditWarehouse = ({ _id, index, state } = {}) => ({
  ...auditWarehouse,
  _id: _id || auditWarehouse._id,
  index: index || auditWarehouse.index,
  state: state || auditWarehouse.state,
})
