/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'

import { styled } from '@mui/material/styles'

import HorizontalSplitIcon from '@mui/icons-material/HorizontalSplit'
import QrCodeIcon from '@mui/icons-material/QrCode'

import { IdMaskedInput, BarcodeMaskedInput } from '../components/MaskedInputs'

import BarcodeScanner from './BarcodeScanner'

import { Dialog, TextField, InputAdornment, Alert } from '@mui/material'
import { Stack } from '@mui/system'

const TextFieldWithoutBorder = styled(TextField)({
  '& label.Mui-focused': {
    border: 'none',
  },
  '& .MuiInput-underline:after': {
    border: 'none',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: 'none',
    },
    '&:hover fieldset': {
      border: 'none',
    },
    '&.Mui-focused fieldset': {
      border: 'none',
    },
  },
})

const BarcodeScannerDialog = ({
  error,
  formats,
  onClose,
  onScan: onScanProps,
  open,
}) => {
  const [inputBarcode, setInputBarcode] = useState('')
  const [inputProductId, setInputProductId] = useState('')

  const onScan = ({ value, format }) => {
    if (!value) {
      return
    }

    if (format === 'ean_13') {
      setInputProductId('')
      setInputBarcode(value.replace(/ /g, ''))
    }

    if (format === 'qr_code') {
      setInputBarcode('')
      setInputProductId(value.replace(/ /g, ''))
    }

    onScanProps({ value, format })
  }

  useEffect(() => {
    onScan({ value: inputBarcode.replace(/ /g, ''), format: 'ean_13' })
  }, [inputBarcode, inputProductId])

  useEffect(() => {
    onScan({ value: inputProductId.trim(), format: 'qr_code' })
  }, [inputProductId])

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiDialog-container': {
          width: 615,
          height: !error ? 395 : 395 + 48,
          position: 'absolute',
          top: 0,
          left: 0,
        },
      }}
    >
      <BarcodeScanner
        width={550}
        height={275}
        formats={formats}
        onScan={onScan}
      />

      <Stack direction="row" sx={{ width: 550 }}>
        <TextFieldWithoutBorder
          fullWidth
          margin="none"
          type="tel"
          value={inputBarcode}
          onChange={setInputBarcode}
          InputProps={{
            inputProps: { 'data-testid': 'scanner-barcode-input' },
            inputComponent: BarcodeMaskedInput,
            startAdornment: (
              <InputAdornment position="start">
                <HorizontalSplitIcon sx={{ transform: 'rotate(90deg)' }} />
              </InputAdornment>
            ),
          }}
        />
        <TextFieldWithoutBorder
          fullWidth
          margin="none"
          value={inputProductId}
          onChange={setInputProductId}
          InputProps={{
            inputProps: { 'data-testid': 'scanner-qr-input' },
            inputComponent: IdMaskedInput,
            startAdornment: (
              <InputAdornment position="start">
                <QrCodeIcon />
              </InputAdornment>
            ),
          }}
        />
      </Stack>
      {!!error && <Alert severity="error">{error}</Alert>}
    </Dialog>
  )
}

export default BarcodeScannerDialog
