import { httpClient } from '../../App/httpClient'
import { TASKGROUP_CONFIGURATION } from '../taskgroup.config'

import { completeTaskGroup } from '../taskGroup.service'

export const parseWarehouseAuditTaskGroup = (taskgroup) => ({
  id: taskgroup._id,
  shareUrl: taskgroup.share_url,
  state: taskgroup.state,
  type: taskgroup.type,
  createdAt: taskgroup.created_at,
  tasks: taskgroup.task_ids,
  currentTaskIndex: taskgroup.current_task_index,
  config: TASKGROUP_CONFIGURATION.WAREHOUSE_AUDIT,
  warehouse: {
    address: taskgroup.warehouse.address,
    id: taskgroup.warehouse._id,
    mapUrl: taskgroup.warehouse.map_url,
    name: taskgroup.warehouse.name,
  },
})

export const cancelAuditWarehouse = async (taskgroupId, secret) => {
  await httpClient.post(
    `/warehouse_audit_taskgroups/${taskgroupId}/restore_audit_requests`,
    {},
    {
      headers: {
        Authorization: `Bearer ${secret}`,
      },
    },
  )

  await completeTaskGroup({ id: taskgroupId, secret })
}
