import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { useQuery, useMutation } from '@tanstack/react-query'

import useSearchParamsState from '../hooks/useUrlParamsState'

import { getTask, completeTask } from './task.service'

const TaskContext = React.createContext()

function TaskProvider({ children }) {
  const { taskgroupId, secret } = useParams()

  const [id, setId] = useSearchParamsState('taskId', {
    basePath: `/tasks/${taskgroupId}/${secret}/t/:taskId`,
  })

  const [completable, setCompletable] = useState(false)
  const [payload, setPayload] = useState(null)

  const { data: task, isLoading } = useQuery(
    ['task', { id, taskgroupId, secret }],
    getTask,
    {
      cacheTime: 0,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    },
  )

  useEffect(() => {
    if (!id && task?.id) {
      setId(task.id)
    }
  }, [id, task, setId])

  useEffect(() => {
    setCompletable(false)
    setPayload(undefined)
  }, [id])

  const { mutateAsync: complete, isLoading: isCompleting } = useMutation(
    (_payload) =>
      completeTask(
        {
          id: task.id,
          action: task.action,
          payload: { ...payload, ..._payload },
        },
        secret,
      ),
  )

  const value = {
    task,
    complete,
    isLoading: isLoading || isCompleting,
    isCompletable: completable,
    setToCompletable: (payload) => {
      setPayload(payload)
      setCompletable(true)
    },
    setToUncompletable: () => setCompletable(false),
  }

  return (
    <TaskContext.Provider value={value}>
      {typeof children === 'function' ? children({ ...value }) : children}
    </TaskContext.Provider>
  )
}

TaskProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.func,
  ]),
}

function useTask() {
  const context = useContext(TaskContext)
  if (context === undefined) {
    throw new Error('useTask must be used within a TaskProvider')
  }
  return context
}

export { TaskProvider, useTask }

export default TaskProvider
