/* eslint-disable react/prop-types */
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import LazyLoad from 'react-lazy-load'

import Header from '../../Layout/Header'

import {
  Box,
  Button,
  List,
  ListItemButton,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListSubheader,
  Dialog,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

const TaskItemMenu = ({ task, selected, onClick, dataTestid, ...props }) => {
  const { t } = useTranslation('TASK')

  const { payload, config } = task
  const { product } = payload

  const primary = payload.customTitle ? payload.customTitle : t(config.title)
  const secondary = product ? product.name : t(config.subtitle)

  return (
    <ListItemButton
      {...props}
      sx={{ borderRadius: 4, minHeight: 97 }}
      onClick={onClick}
      selected={selected}
      data-testid={dataTestid}
    >
      {product ? (
        <ListItemAvatar>
          <LazyLoad>
            <Avatar src={product?.imageUrl} />
          </LazyLoad>
        </ListItemAvatar>
      ) : (
        <ListItemAvatar>
          <LazyLoad>
            <Avatar>
              <config.Icon />
            </Avatar>
          </LazyLoad>
        </ListItemAvatar>
      )}

      <ListItemText primary={primary} secondary={secondary} />
    </ListItemButton>
  )
}

const Menu = ({ open, tasks, onClose, onSelect }) => {
  const { t } = useTranslation('MENU')
  const { taskId: currentTaskId } = useParams()
  const islandscape = useMediaQuery({ query: '(orientation: landscape)' })

  const uncompletedTasks = useMemo(
    () =>
      tasks.filter(
        ({ state }) => state === 'created' || state === 'in_progress',
      ),
    [tasks],
  )

  const completedTasks = useMemo(
    () => tasks.filter(({ state }) => state === 'completed'),
    [tasks],
  )
  const HEADER_HEIGHT = '64px'

  return (
    <Dialog fullScreen open={open} onClose={onClose}>
      <Box role="app-header" height={HEADER_HEIGHT}>
        <Header
          height={HEADER_HEIGHT}
          actions={
            <Button
              data-testid="menu-close-button"
              sx={{
                color: 'white',
              }}
              onClick={onClose}
              endIcon={<CloseIcon />}
            >
              {t('CLOSE_MENU')}
            </Button>
          }
        />
      </Box>

      <Box
        display="flex"
        height={`calc(100% - ${HEADER_HEIGHT})`}
        flexDirection={islandscape ? 'row' : 'column'}
      >
        <List
          subheader={<ListSubheader>{t('TERMINATED')}</ListSubheader>}
          sx={{
            flex: '1 1 0',
            overflowY: 'scroll',
            overflowX: 'hidden',
            paddingLeft: { xs: 1, lg: 2, xl: 3 },
            paddingRight: { xs: 1, lg: 2, xl: 3 },
          }}
        >
          {completedTasks.map((t, index) => (
            <TaskItemMenu
              dataTestid={`menu-terminated-item-${index}`}
              key={t.id}
              task={t}
              selected={t.id === currentTaskId}
              onClick={() => onSelect(t.id)}
              autoFocus={index === completedTasks.length - 1}
            />
          ))}
        </List>

        <List
          subheader={<ListSubheader>{t('TO_DO')}</ListSubheader>}
          sx={{
            flex: '1 1 0',
            overflowY: 'scroll',
            overflowX: 'hidden',
            paddingLeft: { xs: 1, lg: 2, xl: 3 },
            paddingRight: { xs: 1, lg: 2, xl: 3 },
          }}
        >
          {uncompletedTasks.map((t, index) => (
            <TaskItemMenu
              dataTestid={`menu-todo-item-${index}`}
              disabled={t.id !== currentTaskId}
              key={t.id}
              task={t}
              selected={t.id === currentTaskId}
              onClick={() => onSelect(t.id)}
            />
          ))}
        </List>
      </Box>
    </Dialog>
  )
}

export default Menu
