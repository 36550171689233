export const parsePayload = (payload) => {
  const { initial_product_count_to_return, product } = payload

  return {
    initialProductCountToReturn: initial_product_count_to_return,
    product: {
      id: product.product_id,
      name: product.name,
      shelfId: product.shelf_id,
      shelfGroupId: product.store_shelf_group_id,
      section: product.section,
      imageUrl: product.image_url || product.product_image_url,
      capacity: product.capacity,
      capacityUnit: product.capacity_unit,
    },
  }
}

export const parseMenu = (payload) => {
  const { initial_product_count_to_return, product } = payload

  return {
    initialProductCount: initial_product_count_to_return,
    product: {
      id: product.product_id,
      name: product.name,
      imageUrl: product.image_url || product.product_image_url,
      capacity: product.capacity,
      capacityUnit: product.capacity_unit,
    },
  }
}

export const parseCompletePayload = (payload) => {
  return { product_count_to_return: payload.productCountToReturn }
}
