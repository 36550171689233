/* eslint-disable react/prop-types */
import React, { createContext, useContext, useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material/'

const ConfirmationDialog = ({
  open,
  title,
  content,
  message,
  onConfirm,
  onCancel,
}) => {
  return (
    <Dialog fullWidth open={open} onClose={onCancel}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {content}
        {message ? <DialogContentText>{message}</DialogContentText> : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} data-testid="confirm-dialog-cancel-button">
          Annuler
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={onConfirm}
          data-testid="confirm-dialog-confirm-button"
        >
          Confirmer
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const ConfirmationDialogContext = createContext({})

const ConfirmationDialogProvider = ({ children }) => {
  const [open, setOpen] = useState(false)
  const [config, setConfig] = useState(null)

  const openDialog = ({ title, content, message, resolve }) => {
    setOpen(true)
    setConfig({ title, content, message, resolve })
  }

  const confirm = () => {
    if (config.resolve) {
      config.resolve(true)
    }

    setOpen(false)
  }

  const cancel = () => {
    if (config.resolve) {
      config.resolve(false)
    }

    setOpen(false)
  }

  return (
    <ConfirmationDialogContext.Provider value={{ openDialog }}>
      <ConfirmationDialog
        open={open}
        title={config?.title}
        message={config?.message}
        content={config?.content}
        onConfirm={confirm}
        onCancel={cancel}
      />
      {children}
    </ConfirmationDialogContext.Provider>
  )
}

const useConfirmationDialog = () => {
  const { openDialog } = useContext(ConfirmationDialogContext)

  const getConfirmation = ({ ...options }) =>
    new Promise((resolve) => {
      openDialog({ resolve, ...options })
    })

  return getConfirmation
}

export default ConfirmationDialog
export { ConfirmationDialogProvider, useConfirmationDialog }
