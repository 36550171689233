import { TASKGROUP_CONFIGURATION } from '../taskgroup.config'

export const parseWarehouseRestockingFromStoreTaskGroup = (taskgroup) => ({
  id: taskgroup._id,
  shareUrl: taskgroup.share_url,
  state: taskgroup.state,
  type: taskgroup.type,
  createdAt: taskgroup.created_at,
  tasks: taskgroup.task_ids,
  currentTaskIndex: taskgroup.current_task_index,
  config: TASKGROUP_CONFIGURATION.WAREHOUSE_RESTOCKING_FROM_STORE,
  warehouse: {
    address: taskgroup.warehouse.address,
    id: taskgroup.warehouse._id,
    mapUrl: taskgroup.warehouse.map_url,
    name: taskgroup.warehouse.name,
  },
})
