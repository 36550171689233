import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'
import { useForm, useWatch, useFieldArray, Controller } from 'react-hook-form'

import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Dialog,
  Fab,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from '@mui/material'

import { MobileDatePicker } from '@mui/x-date-pickers'
import EventAvailableIcon from '@mui/icons-material/EventAvailable'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'

import Counter from '../../components/Counter'
import Header from '../../Layout/Header'

import Product from '../../Products/Product'
import WarehouseMap from '../../Products/WarehouseMap'

function AuditWarehouse({
  disabled,
  setToCompletable,
  setToUncompletable,
  payload: {
    product,
    initialProductCount,
    productCount: _productCount,
    warehouseShelf,
    expirations,
  },
}) {
  const { t } = useTranslation('AUDIT_WAREHOUSE_SHELF')
  const [removedExpirations, setRemovedExpiration] = useState([])

  const [warehouseMapOpen, setWarehouseMapOpen] = useState(false)

  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      expirations,
      productCount: _productCount || initialProductCount,
    },
  })

  const productCount = useWatch({
    control: form.control,
    name: 'productCount',
  })

  const watchedExpirations = useWatch({
    control: form.control,
    name: 'expirations',
  })

  const totalProductCountExpirations = useMemo(
    () =>
      watchedExpirations.reduce(
        (_totalProductCountExpirations, expiration) =>
          _totalProductCountExpirations + expiration.productCount,
        0,
      ),
    [watchedExpirations],
  )

  const {
    fields: expirationsFields,
    append: appendExpiration,
    remove: removeExpirationField,
  } = useFieldArray({ name: 'expirations', control: form.control })

  const removeExpiration = (index) => {
    setRemovedExpiration([
      ...removedExpirations,
      { ...watchedExpirations[index], productCount: 0 },
    ])
    removeExpirationField(index)
  }

  useEffect(() => {
    const payload = {
      productCount,
      expirations: [...watchedExpirations, ...removedExpirations],
    }

    if (product.hasExpiration) {
      if (
        totalProductCountExpirations === productCount &&
        watchedExpirations.filter(({ expirationDate }) => {
          return expirationDate !== null && expirationDate >= DateTime.now()
        }).length === watchedExpirations.length
      ) {
        setToCompletable(payload)
      } else {
        setToUncompletable(payload)
      }
    } else {
      setToCompletable(payload)
    }
  }, [productCount, totalProductCountExpirations, watchedExpirations])

  const pickedDates = useMemo(
    () =>
      watchedExpirations
        .filter(({ expirationDate }) => expirationDate !== null)
        .map(({ expirationDate }) => expirationDate.startOf('day').toMillis()),
    [watchedExpirations],
  )

  return (
    <Box display="flex" height="100%" gap={1}>
      <Dialog fullScreen open={warehouseMapOpen}>
        <Box role="app-header" height={64}>
          <Header
            height={64}
            actions={
              <Button
                data-testid="warehouse-map-close-button"
                sx={{
                  color: 'white',
                }}
                onClick={() => setWarehouseMapOpen(false)}
                endIcon={<CloseIcon />}
              >
                {t('CLOSE_WAREHOUSE_MAP')}
              </Button>
            }
          />
        </Box>
        <Box
          padding={'10px'}
          display="flex"
          height="calc(100% - 84px)"
          onClick={() => setWarehouseMapOpen(false)}
        >
          <WarehouseMap shelfId={warehouseShelf.id} />
        </Box>
      </Dialog>
      <Box flex="1" display="flex" flexDirection="column" minWidth={250}>
        <Box flex="1">
          <Product {...product} />
        </Box>
        <Box onClick={() => setWarehouseMapOpen(true)}>
          <Typography
            color="textSecondary"
            variant="button"
            data-testid={`product-warehouse-shelf`}
          >
            <code>{warehouseShelf.id}</code>
          </Typography>
        </Box>

        <Box>
          <Card variant="outlined" sx={{ marginTop: 1 }}>
            <CardContent sx={{ p: 1 }}>
              <Box
                sx={{
                  marginBottom: 1,
                }}
              >
                <Typography>
                  {t('INITIAL_QUANTITY_LABEL', {
                    quantity: initialProductCount,
                  })}
                </Typography>
              </Box>

              <Controller
                control={form.control}
                name={`productCount`}
                render={({ field: { onChange, value, error } }) => (
                  <Counter
                    dataTestid="product"
                    error={error}
                    disabled={disabled}
                    initialValue={initialProductCount}
                    label={t('COUNTER_LABEL')}
                    onChange={onChange}
                    value={value}
                    max={warehouseShelf.capacity}
                  />
                )}
              />
            </CardContent>
            <CardActions>
              <Button
                data-testid="audit-warehouse-full-shelf-button"
                fullWidth
                disabled={!warehouseShelf.capacity}
                onClick={() =>
                  form.setValue('productCount', warehouseShelf.capacity)
                }
                color="primary"
                variant="contained"
              >
                {t('FULL_SHELF_BUTTON')}
              </Button>
            </CardActions>
          </Card>
        </Box>
      </Box>

      <Box flex="1" display="flex" minWidth={250}>
        <Box
          flex="1 1 0"
          display="flex"
          position="relative"
          flexDirection="column"
        >
          <Card variant="outlined" sx={{ height: '100%' }}>
            <CardHeader
              title={
                <div data-testid={`expiration-section-header`}>
                  {product.hasExpiration
                    ? t('EXPIRATION_SECTION_TITLE')
                    : t('NO_EXPIRATION_SECTION_TITLE')}
                  {product.hasExpiration ? (
                    <Fab
                      data-testid="expiration-add-button"
                      variant="extended"
                      style={{
                        float: 'right',
                        paddingRight: 15,
                      }}
                      size="small"
                      onClick={() =>
                        appendExpiration({
                          productCount:
                            productCount - totalProductCountExpirations,
                          initialProductCount: 0,
                          expirationDate: null,
                        })
                      }
                      color="primary"
                    >
                      <AddIcon style={{ marginRight: 5 }} />
                      {t('ADD_EXPIRATION_LABEL')}
                    </Fab>
                  ) : null}
                </div>
              }
              titleTypographyProps={{
                variant: 'subtitle1',
              }}
            />

            <CardContent
              sx={{
                margin: 'auto',
                maxHeight: '100%',
                maxWidth: '100%',
                position: 'absolute',
                top: 66,
                left: 0,
                right: 0,
                bottom: 0,
                overflow: 'auto',
              }}
            >
              {expirationsFields.map((field, index) => (
                <Paper key={field.id} elevation={0} sx={{ marginBottom: 2 }}>
                  <Controller
                    control={form.control}
                    name={`expirations.${index}.productCount`}
                    render={({ field: { onChange, value } }) => (
                      <Counter
                        dataTestid={`expiration-${index}`}
                        validate={({ value }) => value >= 0}
                        error={totalProductCountExpirations !== productCount}
                        label={t('QUANTITY')}
                        onChange={onChange}
                        value={value}
                        initialValue={field.initialProductCount || 0}
                        keyboardInput
                      />
                    )}
                  />
                  <Box display="flex" alignItems="center" width="100%">
                    <Button
                      data-testid={`expiration-${index}-delete-button`}
                      color="secondary"
                      style={{
                        marginTop: 8,
                        height: 38,
                        width: 38,
                        alignSelf: 'start',
                      }}
                      size="medium"
                      variant="contained"
                      onClick={() => removeExpiration(index)}
                    >
                      <DeleteForeverIcon />
                    </Button>

                    <Controller
                      control={form.control}
                      name={`expirations.${index}.expirationDate`}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <MobileDatePicker
                            value={value}
                            onChange={onChange}
                            showToolbar={false}
                            minDate={DateTime.now().plus({ days: 1 })}
                            views={['year', 'month', 'day']}
                            openTo="day"
                            shouldDisableDate={(date) =>
                              value !== date &&
                              pickedDates.includes(date.toMillis())
                            }
                            renderInput={(props) => (
                              <TextField
                                fullWidth
                                {...props}
                                sx={{ marginLeft: 1 }}
                                margin="dense"
                                required
                                size="small"
                                InputProps={{
                                  'data-testid': `expiration-${index}-date-input`,
                                  readOnly: true,
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <EventAvailableIcon />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            )}
                          />
                        )
                      }}
                    />
                  </Box>
                </Paper>
              ))}
            </CardContent>
          </Card>
        </Box>
      </Box>
    </Box>
  )
}

AuditWarehouse.propTypes = {
  disabled: PropTypes.bool,
  setToCompletable: PropTypes.func,
  setToUncompletable: PropTypes.func,
  storeId: PropTypes.string,
  payload: PropTypes.object,
  isCompletable: PropTypes.bool,
  price: PropTypes.string,
}

export default AuditWarehouse
