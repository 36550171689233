import { date } from 'faker'
import { rest } from 'msw'

import { RESSOURCE_ID } from '../task.service'

export let storeStatus = {
  last_status: {
    is_entry_door_unlocked: false,
    is_open_for_business: true,
    store_state: 'open',
  },
  next_status: {
    is_entry_door_unlocked: false,
    is_open_for_business: true,
    store_state: 'open',
  },
  is_entry_door_unlocked: false,
  is_open_for_business: true,
  display: {
    last_update: date.recent(),
  },
}

export const setNextStoreStatus = (newStatus) => {
  storeStatus = {
    last_status: {
      is_entry_door_unlocked: storeStatus.is_entry_door_unlocked,
      is_open_for_business: storeStatus.is_open_for_business,
      store_state: storeStatus.store_state,
    },
    next_status: {
      is_entry_door_unlocked:
        newStatus.is_entry_door_unlocked !== undefined
          ? newStatus.is_entry_door_unlocked
          : storeStatus.is_entry_door_unlocked,
      is_open_for_business:
        newStatus.is_open_for_business !== undefined
          ? newStatus.is_open_for_business
          : storeStatus.is_open_for_business,
      store_state:
        newStatus.store_state !== undefined
          ? newStatus.store_state
          : storeStatus.store_state,
    },
    is_entry_door_unlocked:
      newStatus.is_entry_door_unlocked !== undefined
        ? newStatus.is_entry_door_unlocked
        : storeStatus.is_entry_door_unlocked,
    is_open_for_business:
      newStatus.is_open_for_business !== undefined
        ? newStatus.is_open_for_business
        : storeStatus.is_open_for_business,
    store_state:
      newStatus.store_state !== undefined
        ? newStatus.store_state
        : storeStatus.store_state,
    display: {
      last_update: date.recent(),
    },
  }
}

export const handlers = [
  rest.get(
    `http://localhost:3000/${RESSOURCE_ID}/:id/store_status`,
    (req, res, ctx) => {
      return res(ctx.status(200), ctx.json(storeStatus))
    },
  ),
  rest.post(
    `http://localhost:3000/${RESSOURCE_ID}/:id/store_status`,
    (req, res, ctx) => {
      setNextStoreStatus({ ...req.body })
      return res(ctx.status(200))
    },
  ),
]
