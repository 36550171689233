import { date } from 'faker'

import { makeAuditWarehouse } from '../../Task/AuditWarehouse/auditWarehouse.mock'

export const tasks = [
  makeAuditWarehouse({ _id: 'B00000000000000000000001' }),
  makeAuditWarehouse({ _id: 'B00000000000000000000002' }),
  makeAuditWarehouse({ _id: 'B00000000000000000000003' }),
]

export const warehouseAudit = {
  _id: 'A00000000000000000000001',
  share_url: 'share/url',
  state: 'created',
  type: 'warehouse_audit',
  warehouse: {
    _id: '000000000000WAREHOUSE_ID',
    address: '47B rue Ernest Renan',
    map_url: 'http://warehouse_map.url',
    name: 'Ivry',
  },
  created_at: date.past(),
  task_ids: tasks.map((task) => task._id),
  current_task_index: 0,
}

export const makeWarehouseAudit = ({
  _id,
  current_task_index,
  state,
} = {}) => ({
  ...warehouseAudit,
  _id: _id || warehouseAudit._id,
  current_task_index: current_task_index || warehouseAudit.current_task_index,
  state: state || warehouseAudit.state,
})
