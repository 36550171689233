export const parsePayload = (payload) => {
  const {
    product_count,
    initial_product_count,
    product,
    warehouse_shelf,
    expirations,
    purchase_order,
  } = payload

  const quantityPerPackage =
    purchase_order?.package_count * purchase_order?.packing_count

  return {
    quantityPerPackage,
    packageCount: Math.round(product_count / quantityPerPackage),
    initialPackageCount: Math.round(initial_product_count / quantityPerPackage),
    productCount: product_count,
    initialProductCount: initial_product_count,
    product: {
      id: product.product_id || product._id,
      name: product.full_name,
      imageUrl: product.image_url || product.product_image_url,
      capacity: product.capacity,
      capacityUnit: product.capacity_unit,
      warehouseShelfCapacity: product.warehouse_shelf_capacity,
      hasExpiration: product.has_expiration,
      barcode: product.barcode,
    },
    warehouseShelf: {
      capacity: warehouse_shelf?.capacity,
      id: warehouse_shelf?.id,
    },
    expirations: expirations?.map(
      ({ expiration_date, product_count, _id }) => ({
        expirationDate: expiration_date,
        productCount: product_count / quantityPerPackage,
        id: _id,
      }),
    ),
    purchaseOrder: {
      guaranteedShelfLife: purchase_order?.guaranteed_shelf_life,
      packingCount: purchase_order?.packing_count,
      packageCount: purchase_order?.package_count,
    },
  }
}

export const parseMenu = (payload) => {
  const { product_count, initial_product_count, product } = payload

  return {
    productCount: product_count,
    initialProductCount: initial_product_count,
    product: {
      id: product.product_id || product._id,
      name: product.name,
      imageUrl: product.image_url || product.product_image_url,
      barcode: product.barcode,
    },
  }
}

export const parseCompletePayload = (payload) => {
  return {
    product_count: payload.productCount,
    expirations: payload.expirations.map(
      ({ expirationDate, productCount }) => ({
        expiration_date: expirationDate,
        product_count: productCount,
      }),
    ),
  }
}
