import { datatype } from 'faker'

export const picking = {
  action: 'picking',
  comment: 'Comment from admin',
  agent_comment: 'Comment from agent',
  index: 0,
  state: 'created',
  payload: {
    product: {
      product_id: '5d0794866885630014681bb1',
      name: 'Coca-Cola 50cL',
      shelf_id: 'Z01-A01-S01-L01-C01',
      section: 'Beverages',
      image_url:
        'https://storebox-api-public-production.s3.eu-west-3.amazonaws.com/product_images/5d0794866885630014681bb1.jpg',
    },
    product_count: 5,
    warehouse_map_url: 'http://warehouse_map.svg',
  },
  store_id: '0000000000000000STORE_ID',
  read_only: false,
}

export const makePicking = ({ _id, index, state } = {}) => ({
  ...picking,
  _id: _id || datatype.uuid(),
  index: index || picking.index,
  state: state || picking.state,
})
