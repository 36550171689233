/* istanbul ignore file */
import { rest } from 'msw'

import { RESSOURCE_ID } from './taskGroup.service'

import { makeTaskgroupTest } from './Test/test.mock'
import { makeTourSets } from './TourSets/toursets.mock'
import { makeWarehouseAudit } from './WarehouseAudit/warehouseAudit.mock'
import { makeWarehouseRestocking } from './WarehouseRestocking/warehouseRestocking.mock'
import { makeManufacturing } from './Manufacturing/manufacturing.mock'
import { makeWarehouseRestockingFromStore } from './WarehouseRestockingFromStore/warehouseRestockingFromStore.mock'

import { tasks } from '../Task/task.mocks'

const { REACT_APP_MOCKED_TASKGROUP_TYPE } = process.env

let taskGroup

switch (REACT_APP_MOCKED_TASKGROUP_TYPE) {
  case 'tour_sets':
    taskGroup = makeTourSets()
    break

  case 'warehouse_audit':
    taskGroup = makeWarehouseAudit()
    break

  case 'warehouse_restocking':
    taskGroup = makeWarehouseRestocking()
    break

  case 'manufacturing_tasks':
    taskGroup = makeManufacturing()
    break

  case 'warehouse_restocking_from_store':
    taskGroup = makeWarehouseRestockingFromStore()
    break

  case 'test':
    taskGroup = makeTaskgroupTest()
    break
}
let imageUploads = {}
const uploadImage = async () => {
  const key = (Math.random() + 1).toString(36).substring(10)
  imageUploads[key] =
    'https://storebox-api-public-production.s3.eu-west-3.amazonaws.com/product_images/5d0794866885630014681ba6.jpg'

  return `http://localhost:3000/task_app_feedback_images/${key}`
}

export const handlers = [
  rest.get(
    `http://localhost:3000/${RESSOURCE_ID}/:taskgroupId`,
    (req, res, ctx) => {
      return res(
        ctx.status(200),
        ctx.json({ ...taskGroup, _id: req.params.taskgroupId }),
      )
    },
  ),
  rest.get(
    `http://localhost:3000/${RESSOURCE_ID}/:taskgroupId/menu`,
    (req, res, ctx) => {
      return res(ctx.status(200), ctx.json(tasks))
    },
  ),
  rest.post(
    `http://localhost:3000/${RESSOURCE_ID}/:id/complete`,
    (req, res, ctx) => {
      return res(ctx.status(200))
    },
  ),
  rest.post(
    `http://localhost:3000/${RESSOURCE_ID}/:id/upload`,
    async (req, res, ctx) => {
      if (!req.body.image) {
        return res(ctx.status(400))
      }

      const url = await uploadImage()
      return res(ctx.status(200), ctx.json({ image_url: url }))
    },
  ),
  rest.get(
    `http://localhost:3000/task_app_feedback_images/:imageId`,
    async (req, res, ctx) => {
      const image = imageUploads[req.params.imageId]

      return res(ctx.status(302), ctx.set('Location', image))
    },
  ),
]
