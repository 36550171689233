export const parsePayload = (payload) => {
  const { product, product_count } = payload

  return {
    product: {
      id: product.product_id,
      name: product.name,
      shelfId: product.shelf_id,
      section: product.section,
      imageUrl: product.image_url,
    },
    productCount: product_count,
  }
}

export const parseMenu = (payload) => {
  const { product, product_count } = payload

  return {
    product: {
      id: product.product_id,
      name: product.name,
      imageUrl: product.product_image_url,
    },
    productCount: product_count,
  }
}

export const parseCompletePayload = (payload) => {
  return { product_count: payload.productCount }
}
