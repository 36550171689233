import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Typography } from '@mui/material'
import { Alert, Box } from '@mui/material'

import Counter from '../../components/Counter'
import Product from '../../Products/Product'
import StoreSectionMap from '../../Stores/StoreSectionMap'

function Audit({
  disabled,
  setToCompletable,
  setToUncompletable,
  storeId,
  payload: { product, productCount: initialProductCount },
  price,
}) {
  const { t } = useTranslation('AUDIT')
  const [productCount, setProductCount] = useState(initialProductCount)

  useEffect(() => {
    if (productCount > -1) {
      setToCompletable({ productCount })
    } else {
      setToUncompletable()
    }
  }, [productCount])

  if (!product.shelfGroupId) {
    return (
      <Box display="flex" flexDirection="column" height="100%">
        <Box role="main" flex={1} aria-label="no product">
          <Typography variant="h5" color="textPrimary">
            {t('NOTHING_TO_DO')}
          </Typography>
        </Box>
        <Alert severity="info">{t('NOTHING_TO_DO')}</Alert>
      </Box>
    )
  }

  const section = product.shelfId.split('-')[0]

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Box flex={1}>
        <Product
          {...product}
          map={
            <StoreSectionMap
              storeId={storeId}
              section={section}
              shelfGroupId={product.shelfGroupId}
            />
          }
        />
      </Box>
      <Box role="task-actions" display="flex" gap={1}>
        <Counter
          dataTestid="product"
          disabled={disabled}
          value={productCount}
          label={t('COUNTER_LABEL')}
          onChange={setProductCount}
        />
        <Alert role="product-price-alert" severity="warning" icon={false}>
          <Typography variant="body1" data-testid="audit-price">
            {price}
          </Typography>
        </Alert>
      </Box>
    </Box>
  )
}

Audit.propTypes = {
  disabled: PropTypes.bool,
  setToCompletable: PropTypes.func,
  setToUncompletable: PropTypes.func,
  storeId: PropTypes.string,
  payload: PropTypes.object,
  isCompletable: PropTypes.bool,
  price: PropTypes.string,
}

export default Audit
