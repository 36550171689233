import React from 'react'
import PropTypes from 'prop-types'

import { AppBar, Box, Toolbar } from '@mui/material'

import { useTaskGroup } from '../TaskGroup/taskGroup.context'
import { useTask } from '../Task/task.context'

function Header({ actions, progression, progressBar }) {
  const { taskgroup } = useTaskGroup()
  const { task = { config: {} } } = useTask()

  return (
    <Box>
      <AppBar elevation={0} position="static">
        <Toolbar
          sx={{
            paddingLeft: { xs: 1, lg: 2, xl: 3 },
            paddingRight: { xs: 1, lg: 2, xl: 3 },
          }}
        >
          <taskgroup.config.Title taskgroup={taskgroup} task={task} />
          <Box flex="1" />
          {actions}
          <Box>{progression}</Box>
        </Toolbar>
      </AppBar>
      {progressBar}
    </Box>
  )
}

Header.propTypes = {
  task: PropTypes.object,
  actions: PropTypes.element,
  progressBar: PropTypes.element,
  progression: PropTypes.element,
}

Header.defaultProps = {
  stores: [],
}

export default Header
