import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { find } from 'lodash'
import { useTranslation } from 'react-i18next'

import { Box, Typography } from '@mui/material'
import StoreIcon from '@mui/icons-material/Store'

const ManufacturingTitle = ({ task, taskgroup: { stores } }) => {
  const { t } = useTranslation('HEADER')

  const currentStore = useMemo(() => {
    return find(stores, ({ id }) => {
      return id === task.storeId
    })
  }, [task.storeId])

  const storesShortCodes = stores.map((store) => store.shortCode).join(' - ')

  return (
    <Box>
      <Typography variant="h6" data-testid="taskgroup-title">
        {t('MANUFACTURING_TITLE', { stores: storesShortCodes })}
      </Typography>

      <Box sx={{ gap: 1, display: { xs: 'none', md: 'flex' } }}>
        <StoreIcon />
        {currentStore && (
          <Typography variant="subtitle2" data-testid="taskgroup-subtitle">
            {`${currentStore?.name} - ${currentStore?.address}`}
          </Typography>
        )}
      </Box>
    </Box>
  )
}

ManufacturingTitle.propTypes = {
  task: PropTypes.object,
  taskgroup: PropTypes.object,
}

export default ManufacturingTitle
