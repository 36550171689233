/* eslint-disable react/prop-types */
import React from 'react'
import { IMaskInput } from 'react-imask'

const MakeMaskedInput = (mask) =>
  React.forwardRef((props, ref) => {
    const { onChange, ...other } = props
    return (
      <IMaskInput
        {...other}
        mask={mask}
        inputRef={ref}
        onAccept={(value) => onChange(value)}
        overwrite
      />
    )
  })

export default MakeMaskedInput
