import { useEffect, useRef } from 'react'

function useInterval(callback, options = {}) {
  const { delay, immediate } = options
  const savedCallback = useRef()
  const id = useRef()

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  const execute = () => {
    if (delay) {
      id.current = setInterval(() => {
        savedCallback.current()
      }, delay)
    } else {
      if (delay == null) {
        console.warn(
          'Delay passed was null or undefined. Did you mean to pass 0 (zero)?',
        )
      }

      savedCallback.current()
    }
  }

  useEffect(() => {
    if (immediate) {
      execute()
    }

    return () => clearInterval(id.current)
  }, [delay])

  const stopInterval = () => clearInterval(id.current)
  const startInterval = () => execute()

  return [startInterval, stopInterval]
}

export default useInterval
