import React from 'react'
import PropTypes from 'prop-types'

import LinearProgress from '@mui/material/LinearProgress'
import { Button, Box } from '@mui/material'

const LoadingButton = ({ children, isLoading, sx, ...buttonProps }) => {
  return (
    <Box position="relative" sx={sx}>
      <Button {...buttonProps}>{children}</Button>
      {isLoading && (
        <LinearProgress
          color={buttonProps.color || 'primary'}
          sx={{
            position: 'absolute',
            top: 0,
            width: '100%',
            height: '100%',
            opacity: 0.4,
            borderRadius: 4,
          }}
        />
      )}
    </Box>
  )
}

LoadingButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
  ]).isRequired,
  isLoading: PropTypes.bool,
  buttonProps: PropTypes.object,
  sx: PropTypes.object,
}

export default LoadingButton
