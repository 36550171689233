import { httpClient } from '../../App/httpClient'
import { RESSOURCE_ID } from '../taskGroup.service'

import { TASKGROUP_CONFIGURATION } from '../taskgroup.config'

export const parseTourSetsTaskGroup = (taskgroup) => ({
  id: taskgroup._id,
  shareUrl: taskgroup.share_url,
  state: taskgroup.state,
  type: taskgroup.type,
  createdAt: taskgroup.created_at,
  tasks: taskgroup.task_ids,
  currentTaskIndex: taskgroup.current_task_index,
  config: TASKGROUP_CONFIGURATION.TOUR_SETS,
  stores: taskgroup.stores.map((store) => ({
    address: store.address,
    id: store._id,
    name: store.name,
    shortCode: store.short_code,
    warehouseId: store.warehouse_id,
  })),
})

export const sendFeedback = async (taskgroupId, feedback, secret) => {
  const { data, status } = await httpClient.post(
    `/${RESSOURCE_ID}/${taskgroupId}/feedbacks`,
    feedback,
    {
      headers: {
        Authorization: `Bearer ${secret}`,
      },
    },
  )

  if (status === 200 || status === 201) {
    return data
  }

  return null
}
