import React, { useState } from 'react'
import { useMediaQuery } from 'react-responsive'

import { IconButton, Box, Typography } from '@mui/material'
import FeedbackIcon from '@mui/icons-material/Feedback'
import HelpIcon from '@mui/icons-material/Help'
import MenuOpenIcon from '@mui/icons-material/MenuOpen'
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner'

import BarcodeScannerDialog from '../Products/BarcodeScannerDialog'
import Header from '../Layout/Header'
import Task from '../Task/Task'
import TaskProvider from '../Task/task.context'

import TaskGroupProgressBar from './components/TaskGroupProgressBar'
import TaskGroupProvider from './taskGroup.context'

function TaskGroup() {
  const islandscape = useMediaQuery({ query: '(orientation: landscape)' })

  const [barcodeError, setBarcodeError] = useState()

  const HEADER_HEIGHT = '74px'
  const NAVIGATION_SIZE = { xs: '10vh', md: '180px', lg: '280px', xl: '380px' }

  return (
    <Box role="main" height="100%">
      <TaskGroupProvider>
        {({
          taskgroup,
          tasks,

          completedTasksLength,
          progression,

          goToTask,
          menuOpen,
          setMenuOpen,

          helperDrawerOpen,
          setHelperDrawerOpen,

          feedbacktDrawerOpen,
          setFeedbacktDrawerOpen,

          scanDialogOpen,
          setScanDialogOpen,
        }) => (
          <TaskProvider>
            <Box role="app-header" height={HEADER_HEIGHT}>
              <Header
                actions={
                  <Box display="flex" alignItems="center">
                    {taskgroup.config.FeedbackDrawer ? (
                      <IconButton
                        aria-label="feedback button"
                        aria-haspopup="true"
                        data-testid="taskgroup-feedback-button"
                        onClick={() => setFeedbacktDrawerOpen(true)}
                        color="inherit"
                      >
                        <FeedbackIcon />
                      </IconButton>
                    ) : null}

                    {taskgroup.config.HelperDrawer ? (
                      <IconButton
                        aria-label="help button"
                        aria-haspopup="true"
                        data-testid="taskgroup-help-button"
                        onClick={() => setHelperDrawerOpen(true)}
                        color="inherit"
                      >
                        <HelpIcon />
                      </IconButton>
                    ) : null}

                    <Box width="20px" />

                    {taskgroup.config.scanner ? (
                      <IconButton
                        data-testid="taskgroup-scan-button"
                        onClick={() => setScanDialogOpen(true)}
                        color="inherit"
                      >
                        <QrCodeScannerIcon />
                      </IconButton>
                    ) : null}

                    {taskgroup.config.Menu ? (
                      <IconButton
                        aria-label="menu button"
                        aria-haspopup="true"
                        data-testid="taskgroup-menu-button"
                        onClick={() => setMenuOpen(true)}
                        color="inherit"
                      >
                        <MenuOpenIcon />
                      </IconButton>
                    ) : null}
                  </Box>
                }
                progression={
                  <Typography
                    data-testid="taskgroup-progression"
                    role="counter"
                    sx={{ width: 'max-content' }}
                    variant="h6"
                  >
                    {`${completedTasksLength} / ${tasks.length}`}
                  </Typography>
                }
                progressBar={<TaskGroupProgressBar progression={progression} />}
              />
            </Box>

            <Box
              role="app-content"
              display="flex"
              flexDirection={islandscape ? 'row' : 'column'}
              height={`calc(100% - ${HEADER_HEIGHT})`}
              width="100vw"
            >
              <Box flex="1 1 0" padding={{ xs: 1, lg: 2, xl: 3 }}>
                <Task />
              </Box>

              <Box
                minWidth={NAVIGATION_SIZE}
                minHeight={NAVIGATION_SIZE}
                role="app-navigation"
                padding={{ xs: 1, lg: 2, xl: 3 }}
              >
                <taskgroup.config.Navigation />
              </Box>
            </Box>

            {taskgroup.config.scanner ? (
              <BarcodeScannerDialog
                open={scanDialogOpen}
                onClose={() => setScanDialogOpen(false)}
                error={barcodeError}
                onScan={({ value, format }) => {
                  setBarcodeError(null)

                  let foundTask
                  if (format === 'ean_13') {
                    foundTask = tasks.filter(
                      ({ payload: { product } }) => product.barcode === value,
                    )[0]
                  }

                  if (format === 'qr_code') {
                    foundTask = tasks.filter(
                      ({ payload: { product } }) => product.id === value,
                    )[0]
                  }

                  if (foundTask) {
                    setScanDialogOpen(false)
                    goToTask(foundTask.id)
                    setBarcodeError(null)
                  } else {
                    if (format === 'ean_13' && value.length === 13) {
                      setBarcodeError('Aucune tâche correspondante')
                    }

                    if (format === 'qr_code' && value.length === 24) {
                      setBarcodeError('Aucune tâche correspondante')
                    }
                  }
                }}
              />
            ) : null}

            {taskgroup.config.Menu ? (
              <taskgroup.config.Menu
                open={menuOpen}
                tasks={tasks}
                onClose={() => setMenuOpen(false)}
                onSelect={(id) => {
                  setMenuOpen(false)
                  goToTask(id)
                }}
              />
            ) : null}

            {taskgroup.config.HelperDrawer ? (
              <taskgroup.config.HelperDrawer
                isOpen={helperDrawerOpen}
                onClose={() => setHelperDrawerOpen(false)}
              />
            ) : null}

            {taskgroup.config.FeedbackDrawer ? (
              <taskgroup.config.FeedbackDrawer
                isOpen={feedbacktDrawerOpen}
                onClose={() => setFeedbacktDrawerOpen(false)}
              />
            ) : null}
          </TaskProvider>
        )}
      </TaskGroupProvider>
    </Box>
  )
}

export default TaskGroup
