export const stepEnums = {
  initialStep: 'initialStep',
  barCode: 'barCode',
  reasonStep: 'reasonStep',
  feedback: 'feedback',
  taskCompleted: 'taskCompleted',
}

export const actions = {
  clickScan: 'clickScan',
  clickNextBarCode: 'clickNextBarCode',
  setReason: 'setReason',
  setProduct: 'setProduct',
  changeQuantity: 'changeQuantity',
  changeInFullShelfProductCount: 'changeInFullShelfProductCount',
  incrementInFullShelfProductCount: 'incrementInFullShelfProductCount',
  decrementInFullShelfProductCount: 'decrementInFullShelfProductCount',
  changeComment: 'changeComment',
  changeExpirationDate: 'changeExpirationDate',
  clickOther: 'clickOther',
  completed: 'completed',
  reset: 'reset',
  authenticate: 'authenticate',
}

export const defaultState = {
  token: null,
  id: 0,
  reason: '',
  task_id: '',
  barcode: null,
  product: null,
  image_urls: null,
  product_count: '',
  full_shelf_product_count: '',
  expiration_date: null,
  comment: '',
  currentStep: stepEnums.initialStep,
}

export function reducer(state, { action, payload }) {
  switch (action) {
    case actions.clickScan:
      return { ...state, currentStep: stepEnums.barCode }
    case actions.setReason:
      return { ...state, currentStep: stepEnums.feedback, reason: payload }
    case actions.setProduct:
      return { ...state, product: payload, currentStep: stepEnums.reasonStep }
    case actions.changeQuantity:
      return {
        ...state,
        product_count: payload === '' ? '' : parseInt(payload, 10),
      }
    case actions.changeInFullShelfProductCount:
      return {
        ...state,
        full_shelf_product_count: payload === '' ? '' : parseInt(payload, 10),
      }
    case actions.incrementInFullShelfProductCount:
      return {
        ...state,
        full_shelf_product_count: (state.full_shelf_product_count || 0) + 1,
      }
    case actions.decrementInFullShelfProductCount:
      return {
        ...state,
        full_shelf_product_count: (state.full_shelf_product_count || 0) - 1,
      }
    case actions.changeExpirationDate:
      return { ...state, expiration_date: payload }
    case actions.changeComment:
      return { ...state, comment: payload }
    case actions.clickOther:
      return {
        ...state,
        product: payload,
        currentStep: stepEnums.feedback,
        reason: 'other',
      }
    case actions.reset:
      return defaultState
    case actions.authenticate:
      return { ...state, token: payload }
    case actions.completed:
      return { ...state, currentStep: stepEnums.taskCompleted }
    default:
      return state
  }
}
