import React, {
  useContext,
  useState,
  useMemo,
  useCallback,
  useRef,
  useEffect,
} from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useErrorHandler } from 'react-error-boundary'
import { useParams } from 'react-router-dom'
import { useQuery, useMutation } from '@tanstack/react-query'

import { Backdrop, Box, CircularProgress } from '@mui/material'

import useSearchParamsState from '../hooks/useUrlParamsState'

import { ROUTES } from '../App/App'

import {
  completeTaskGroup,
  getMenu,
  getTaskGroupbyId,
} from './taskGroup.service'

const TaskGroupContext = React.createContext()

function TaskGroupProvider({ children }) {
  const handleError = useErrorHandler()
  const history = useHistory()

  const { taskgroupId: id, secret } = useParams()

  const [taskId, setTaskId] = useSearchParamsState('taskId', {
    basePath: `/tasks/${id}/${secret}/t/:taskId`,
  })

  const tasksHistory = useRef([]).current

  const [menuOpen, setMenuOpen] = useState(false)
  const [helperDrawerOpen, setHelperDrawerOpen] = useState(false)
  const [feedbacktDrawerOpen, setFeedbacktDrawerOpen] = useState(false)
  const [scanDialogOpen, setScanDialogOpen] = useState(false)
  const [CustomNextButton, setCustomNextButton] = useState(null)
  const [CustomPreviousButton, setCustomPreviousButton] = useState(null)

  const { isLoading, data: taskgroup } = useQuery(
    ['taskgroup', { id, secret }],
    getTaskGroupbyId,
    { onError: handleError, refetchOnWindowFocus: false },
  )

  const { data: tasks = [], refetch: refreshMenu } = useQuery(
    ['menu', { id, secret }],
    getMenu,
    {
      enabled: false,
      keepPreviousData: true,
      onError: handleError,
      refetchOnWindowFocus: false,
    },
  )

  useEffect(() => refreshMenu(), [])

  const uncompletedTasksIds = useMemo(
    () => tasks.filter((t) => t.state !== 'completed').map(({ id }) => id),
    [tasks],
  )

  const { mutateAsync: complete } = useMutation(() =>
    completeTaskGroup({ id, secret }),
  )

  const completedTasksLength = useMemo(() => {
    return tasks.length - uncompletedTasksIds.length + 1
  }, [tasks, uncompletedTasksIds])

  const progression = useMemo(() => {
    return (100 * completedTasksLength) / tasks.length
  }, [tasks, completedTasksLength])

  const isLastTask = useMemo(() => {
    return uncompletedTasksIds.length === 1 && taskId === uncompletedTasksIds[0]
  }, [uncompletedTasksIds, taskId])

  const goToTask = useCallback(
    (nexTaskId) => {
      tasksHistory.push(taskId)
      setTaskId(nexTaskId)
      setCustomNextButton(null)
    },
    [setTaskId, taskId],
  )

  const gotToNextTask = useCallback(async () => {
    if (isLastTask) {
      await complete()
      history.replace(ROUTES.CONGRATULATION)
      return
    }

    const currentTaskIdIndex = uncompletedTasksIds.findIndex(
      (id) => id === taskId,
    )
    const nextTaskIdIndex = currentTaskIdIndex + 1

    if (uncompletedTasksIds[nextTaskIdIndex]) {
      goToTask(uncompletedTasksIds[nextTaskIdIndex])
    } else {
      goToTask(uncompletedTasksIds[0])
    }

    refreshMenu()
  }, [goToTask, uncompletedTasksIds, taskId, refreshMenu, isLastTask])

  const gotToPreviousTask = useCallback(() => {
    setCustomNextButton(null)
    setTaskId(tasksHistory.pop(taskId))
  }, [setTaskId, taskId, tasksHistory])

  const value = {
    //Taskgroup
    taskgroup,
    tasks,
    secret,

    //Progression
    completedTasksLength,
    progression,
    isLastTask,

    //Navigation
    tasksHistory,
    goToTask,
    gotToNextTask,
    gotToPreviousTask,
    complete,
    CustomNextButton,
    setCustomNextButton,
    CustomPreviousButton,
    setCustomPreviousButton,

    //UI state
    menuOpen,
    setMenuOpen,
    helperDrawerOpen,
    setHelperDrawerOpen,
    feedbacktDrawerOpen,
    setFeedbacktDrawerOpen,
    scanDialogOpen,
    setScanDialogOpen,
  }

  return (
    <TaskGroupContext.Provider value={value}>
      <Backdrop open={isLoading}>
        <Box display="flex" alignItems="center" justifyContent="center">
          <CircularProgress
            role="progressbar"
            aria-busy="true"
            data-testid="taskgroup-progressbar"
          />
        </Box>
      </Backdrop>

      {taskgroup && (
        <Box data-testid="taskgroup-content" height="100%">
          {typeof children === 'function' ? children({ ...value }) : children}
        </Box>
      )}
    </TaskGroupContext.Provider>
  )
}

TaskGroupProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  id: PropTypes.string,
  secret: PropTypes.string,
  handleError: PropTypes.func,
}

function useTaskGroup() {
  const context = useContext(TaskGroupContext)
  if (context === undefined) {
    throw new Error('useTaskGroup must be used within a TaskGroupProvider')
  }
  return context
}

export { TaskGroupProvider, useTaskGroup }

export default TaskGroupProvider
