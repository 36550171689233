import { datatype } from 'faker'

export const price = {
  _id: datatype.uuid(),
  action: 'price',
  comment: 'Comment from admin',
  agent_comment: 'Comment from agent',
  index: 0,
  state: 'created',
  payload: {},
  store_id: '0000000000000000STORE_ID',
  read_only: false,
}

export const makePrice = ({ _id, index, state } = {}) => ({
  ...price,
  _id: _id || price._id,
  index: index || price.index,
  state: state || price.state,
})
