import { datatype } from 'faker'

export const custom = {
  _id: datatype.uuid(),
  action: 'custom',
  comment: 'Comment from admin',
  agent_comment: 'Comment from agent',
  index: 0,
  state: 'created',
  payload: {
    custom_title:
      '**strong** *emphasis* `code` [Link](https://reactjs.org) words words, https://reactjs.org',
    custom_body:
      '# Markdown \n \n # H1 \n ## H2 \n ### H3 \n\n **Bold text** \n\n *Italic text* \n\n > blockquote \n\n `code` \n\n - List \n - Second item \n - Third item \n\n --- \n\n  # GFM flavored \n \n https://reactjs.org and contact@example.com. \n \n ## Strikethrough \n \n ~one~ or ~~two~~ tildes. \n \n ## Table \n \n | a | b  |  c |  d  | \n | - | :- | -: | :-: | \n \n  ## Tasklist \n \n * [ ] to do \n  * [x] done',
  },
}

export const makeCustom = ({ _id, index, state } = {}) => ({
  ...custom,
  _id: _id || custom._id,
  index: index || custom.index,
  state: state || custom.state,
})
