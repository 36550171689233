import React, { useContext } from 'react'

import { makeStyles } from '@mui/styles'
import { Card, CardContent, Typography } from '@mui/material'

import { TaskGroupAPIContext } from '../toursets.context'

import barcode from '../../../assets/barcode.png'
import help from '../../../assets/help.png'

function Step0() {
  const classes = useStyles()

  const dispatch = useContext(TaskGroupAPIContext)

  return (
    <>
      <Card
        role="feedback-scan"
        data-testid="feedback-scan-button"
        onClick={() => dispatch({ action: 'clickScan' })}
        className={classes.cardStyle}
        aria-label="taskGroup scan card"
      >
        <CardContent>
          <Typography className={classes.cardTitle}>Scan</Typography>
          <img src={barcode} alt="barcode" width={150} />
        </CardContent>
      </Card>
      <Card
        role="feedback-other"
        data-testid="feedback-other-button"
        onClick={() => dispatch({ action: 'clickOther' })}
        className={classes.cardStyle}
        aria-label="taskGroup other card"
      >
        <CardContent>
          <Typography className={classes.cardTitle}>Autres</Typography>
          <img src={help} alt="help" width={150} />
        </CardContent>
      </Card>
    </>
  )
}

const useStyles = makeStyles(() => ({
  cardStyle: {
    height: '300px',
    flexBasis: '350px',
    cursor: 'pointer',
    flex: '1',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '20px',
    borderRadius: '50px',
    backgroundColor: '#E6E6E6',
    boxShadow: 'none',
    flexGrow: 0,
    flexShrink: 0,
  },
  cardTitle: {
    marginTop: '40px',
    fontSize: '25px',
  },
}))

export default Step0
