import { useState, useEffect } from 'react'

const useAsyncAction = (action, options = {}) => {
  const [data, setData] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const { immediate = false, onError, onSuccess } = options

  const execute = async (options) => {
    try {
      setIsLoading(true)
      const data = await action(options)
      setData(data)
      if (typeof onSuccess === 'function') {
        onSuccess(data)
      }
    } catch (error) {
      if (typeof onError === 'function') {
        onError(error)
      }
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (immediate) {
      execute()
    }
  }, [])

  return [data, isLoading, execute]
}

export default useAsyncAction
