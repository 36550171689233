import { TASKGROUP_CONFIGURATION } from '../taskgroup.config'

export const parseTestTaskGroup = (taskgroup) => ({
  id: taskgroup._id,
  shareUrl: taskgroup.share_url,
  state: taskgroup.state,
  type: taskgroup.type,
  createdAt: taskgroup.created_at,
  tasks: taskgroup.task_ids,
  currentTaskIndex: taskgroup.current_task_index,
  config: TASKGROUP_CONFIGURATION.TEST,
})
