import { isNil } from 'lodash'

export const parsePayload = (payload) => {
  return {
    customBody: payload.custom_body,
    customTitle: payload.custom_title,
    imageUrl: payload.image_url,
    reason: payload.reason,
    reasonComment: payload.reason_comment,
    reasons: payload.reasons,
    response: isNil(payload.response) ? true : payload.response,
    isSolved: payload.is_solved,
  }
}

export const parseCompletePayload = (payload) => {
  return {
    image_url: payload.imageUrl,
    reason_comment: payload.reasonComment,
    reason: payload.reason,
    response: payload.response,
    is_solved: payload.isSolved,
  }
}

export const parseMenu = parsePayload
