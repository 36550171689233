import { datatype, date } from 'faker'

export const warehouseRestockingFromStoreTask = {
  action: 'warehouse_restocking_from_store',
  comment: 'Comment from admin',
  agent_comment: 'Comment from agent',
  index: 0,
  state: 'created',
  payload: {
    expirations: [
      {
        _id: '00000000000EXPIRATION_ID',
        expiration_date: date.future(),
        product_count: 20,
      },
      {
        _id: '10000000000EXPIRATION_ID',
        expiration_date: date.past(),
        product_count: 20,
      },
    ],
    product: {
      capacity: 304,
      capacity_unit: 'g',
      full_name: 'FERRERO Nutella - Biscuits fourrés',
      name: 'FERRERO Nutella - Biscuits fourrés',
      has_expiration: true,
      image_url:
        'https://storebox-api-public-production.s3.eu-west-3.amazonaws.com/product_images/5ed0bd65495f8b00042ab480.jpg',
      product_image_url:
        'https://storebox-api-public-production.s3.eu-west-3.amazonaws.com/product_images/5ed0bd65495f8b00042ab480.jpg',
    },
    initial_product_count: 40,
    product_count: 40,
    warehouse_shelf: {
      capacity: 42,
      id: 'Z01-A02-S01-L04-C01',
    },
  },
  warehouse_id: '000000000000WAREHOUSE_ID',
  read_only: false,
}

export const makeWarehouseRestockingFromStoreTask = ({ _id, index } = {}) => ({
  ...warehouseRestockingFromStoreTask,
  _id: _id || datatype.uuid(),
  index: index || warehouseRestockingFromStoreTask.index,
})
