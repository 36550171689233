import React from 'react'
import PropTypes from 'prop-types'
import { useErrorHandler } from 'react-error-boundary'

import { Box, Button, Typography } from '@mui/material'

function Test({ setToCompletable, id }) {
  const handleError = useErrorHandler()

  const breakTheApp = () => {
    handleError(new Error('test'))
  }

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Box
        flex={1}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="h6">Task content</Typography>
        <Typography variant="h6">{id}</Typography>
      </Box>

      <Box role="task-actions" display="flex" gap={1}>
        <Button
          data-testid="complete-task-button"
          aria-label="complete task button"
          variant="contained"
          color="success"
          onClick={setToCompletable}
        >
          Complete task
        </Button>
        <Button variant="contained" color="error" onClick={breakTheApp}>
          Make error
        </Button>
      </Box>
    </Box>
  )
}

Test.propTypes = {
  setToCompletable: PropTypes.func,
  id: PropTypes.string.isRequired,
}

export default Test
