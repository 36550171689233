import React from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@mui/styles'
import { Typography } from '@mui/material'

import ExternalReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'

const ReactMarkdown = ({ text = '' }) => {
  const classes = useStyles()

  return (
    <ExternalReactMarkdown
      data-testId="custom_body"
      linkTarget="_blank"
      className={classes.md}
      remarkPlugins={[remarkGfm]}
      rehypePlugins={[rehypeRaw]}
      components={{
        // eslint-disable-next-line react/prop-types
        h1({ children }) {
          return <Typography variant="h4">{children}</Typography>
        },
        // eslint-disable-next-line react/prop-types
        h2({ children }) {
          return <Typography variant="h5">{children}</Typography>
        },
        // eslint-disable-next-line react/prop-types
        h3({ children }) {
          return <Typography variant="h6">{children}</Typography>
        },
        // eslint-disable-next-line react/prop-types
        h4({ children }) {
          return <Typography variant="h6">{children}</Typography>
        },
        // eslint-disable-next-line react/prop-types
        h5({ children }) {
          return <Typography variant="h6">{children}</Typography>
        },
        // eslint-disable-next-line react/prop-types
        h6({ children }) {
          return <Typography variant="h6">{children}</Typography>
        },
        // eslint-disable-next-line react/prop-types
        p({ children }) {
          return <Typography variant="body1">{children}</Typography>
        },
        // eslint-disable-next-line react/prop-types
        li({ children }) {
          return (
            <li>
              <Typography variant="body1">{children}</Typography>
            </li>
          )
        },
      }}
    >
      {text}
    </ExternalReactMarkdown>
  )
}

const useStyles = makeStyles((theme) => ({
  md: {
    '& p': {
      marginBlockStart: '0.5em',
      marginBlockEnd: '0.5em',
      marginInlineStart: '0px',
      marginInlineEnd: '0px',
    },

    '& img[alt=xs]': {
      height: 50,
    },
    '& img[alt=sm]': {
      height: 100,
    },
    '& img[alt=md]': {
      height: 150,
    },
    '& img[alt=lg]': {
      height: 200,
    },
    '& img[alt=xl]': {
      height: 300,
    },

    '& table': {
      tableLayout: 'auto',
      width: '100%',
      margin: ' 5px 0 7px 0',
      borderCollapse: 'collapse',
    },

    '& td,th': {
      border: '1px solid #ddd',
    },

    '& td': {
      height: '1px',
      minWidth: '60px',
      fontFamily: theme.typography.fontFamily,
      fontSize: '10px',
      wordBreak: 'normal',
      padding: theme.spacing(1),
    },

    '& td[added]': {
      backgroundColor: '#EDD362', // yellow
    },
    '& td[moved]': {
      backgroundColor: '#8CC95C', // green
    },
    '& td[removed]': {
      backgroundColor: '#FF274B', // red
    },
    '& td[price-changed]': {
      backgroundColor: '#65C5DA', // blue
    },

    '& th': {
      padding: '2px 5px',
    },

    '& td div': {
      display: 'flex',
      gap: '5px',
      flexDirection: 'column',
      justifyContent: 'space-around',
      alignItems: 'center',
      height: '100%',
    },

    '& td img': {
      maxWidth: '60px',
      maxHeight: '60px',
      flex: 1,
    },
  },
}))

ReactMarkdown.propTypes = {
  text: PropTypes.string,
}

export default ReactMarkdown
