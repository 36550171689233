/* eslint-disable react/prop-types */
import React from 'react'

import { Box, Dialog, Button, Typography } from '@mui/material'

const Menu = ({ open, onClose }) => {
  return (
    <Dialog fullScreen open={open} onClose={onClose}>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        alignItems="center"
        justifyContent="center"
        gap={2}
      >
        <Typography variant="h6">Taskgroup menu</Typography>
        <Button
          data-testid="menu-close-button"
          aria-label="close button"
          variant="contained"
          onClick={onClose}
        >
          Close
        </Button>
      </Box>
    </Dialog>
  )
}

export default Menu
