export const parsePayload = (payload) => {
  return {
    customTitle: payload.custom_title,
    customBody: payload.custom_body,
    imageUrls: payload.image_urls || [],
  }
}

export const parseMenu = parsePayload

export const parseCompletePayload = (payload) => {
  return { image_urls: payload.image_urls }
}
