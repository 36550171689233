import { rest } from 'msw'
import { findIndex } from 'lodash'

import { RESSOURCE_ID } from './task.service'
import { RESSOURCE_ID as TASKGROUP_RESSOURCE_ID } from '../TaskGroup/taskGroup.service'

import { tasks as taskgroupTestTasks } from '../TaskGroup/Test/test.mock'
import { tasks as tourSetsTasks } from '../TaskGroup/TourSets/toursets.mock'
import { tasks as warehouseAuditTasks } from '../TaskGroup/WarehouseAudit/warehouseAudit.mock'
import { tasks as warehouseRestockingTasks } from '../TaskGroup/WarehouseRestocking/warehouseRestocking.mock'
import { tasks as manufacturingTasks } from '../TaskGroup/Manufacturing/manufacturing.mock'
import { tasks as warehouseRestockingFromStoreTasks } from '../TaskGroup/WarehouseRestockingFromStore/warehouseRestockingFromStore.mock'

const { REACT_APP_MOCKED_TASKGROUP_TYPE } = process.env

export let tasks

switch (REACT_APP_MOCKED_TASKGROUP_TYPE) {
  case 'tour_sets':
    tasks = tourSetsTasks
    break

  case 'warehouse_audit':
    tasks = warehouseAuditTasks
    break

  case 'warehouse_restocking':
    tasks = warehouseRestockingTasks
    break

  case 'manufacturing_tasks':
    tasks = manufacturingTasks
    break

  case 'warehouse_restocking_from_store':
    tasks = warehouseRestockingFromStoreTasks
    break

  case 'test':
    tasks = taskgroupTestTasks
    break
}

const findTask = (id) => {
  const index = findIndex(tasks, (t) => t._id === id)

  let task
  if (index !== -1) {
    task = tasks[index]
  } else {
    task = tasks[0]
  }

  return task
}
const getTaskById = (id) => {
  const task = findTask(id)

  if (task.state !== 'completed') {
    task.state = 'in_progress'
  }

  return task
}

const completeTask = (id) => {
  const completedTask = findTask(id)
  completedTask.state = 'completed'

  return completedTask
}

export const handlers = [
  rest.get(
    `http://localhost:3000/${TASKGROUP_RESSOURCE_ID}/:taskgroupId/current_task`,
    (req, res, ctx) => {
      return res(ctx.status(200), ctx.json(getTaskById(null)))
    },
  ),
  rest.get(
    `http://localhost:3000/${TASKGROUP_RESSOURCE_ID}/:taskgroupId/${RESSOURCE_ID}/:taskId`,
    (req, res, ctx) => {
      return res(ctx.status(200), ctx.json(getTaskById(req.params.taskId)))
    },
  ),
  rest.post(
    `http://localhost:3000/${RESSOURCE_ID}/:id/complete`,
    (req, res, ctx) => {
      completeTask(req.params.id)
      return res(ctx.status(200))
    },
  ),
]
