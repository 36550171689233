import React from 'react'
import { Container } from '@mui/material'

import { useTranslation } from 'react-i18next'

function Home() {
  const { t } = useTranslation('HOME')
  return (
    <Container>
      <p>{t('GREETING')}</p>
    </Container>
  )
}

export default Home
