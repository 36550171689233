import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'
import { Typography } from '@mui/material'

import CustomImageCapture from './CustomImageCapture'

const STANDARD_ASPECT_RATIO = 2

const WebCamera = ({
  imageCaptureRef,
  width,
  height,
  aspectRatio = STANDARD_ASPECT_RATIO,
}) => {
  const videoRef = useRef()
  const classes = useStyles({ height })
  const imageCaptureSupported = 'ImageCapture' in window
  const [isReadyToCapture, setReadyToCapture] = useState(false)

  useEffect(async () => {
    if (!imageCaptureSupported) {
      return
    }

    const mediaStream = await navigator.mediaDevices.getUserMedia({
      video: {
        aspectRatio,
        facingMode: 'environment',
      },
    })

    const [track] = mediaStream.getVideoTracks()
    imageCaptureRef.current = new CustomImageCapture(track.clone())
    videoRef.current.srcObject = mediaStream

    // Workaround to reduce the delay of the first photo call
    imageCaptureRef.current.takePhoto()

    setReadyToCapture(true)
  }, [])

  if (!imageCaptureSupported) {
    return (
      <Typography variant="h3" className={classes.unsupportedBrowser}>
        Browser does not support ImageCapture
      </Typography>
    )
  }

  return (
    <video
      autoPlay
      aria-busy={!isReadyToCapture}
      role="camera-viewer"
      ref={videoRef}
      className={classes.cameraViewer}
      width={width}
      height={height}
    />
  )
}

WebCamera.propTypes = {
  aspectRatio: PropTypes.number,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  imageCaptureRef: PropTypes.shape({
    current: PropTypes.instanceOf(CustomImageCapture),
  }),
}

const useStyles = makeStyles((theme) => ({
  unsupportedBrowser: {
    color: theme.palette.common.white,
  },
  cameraViewer: ({ height }) => ({
    ...(!height ? { height: 'min-content' } : {}),
  }),
}))

export default WebCamera
