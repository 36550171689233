import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import JSONPretty from 'react-json-pretty'

import {
  Card,
  CardContent,
  CardActions,
  Button,
  Collapse,
  Typography,
} from '@mui/material'
import { Alert } from '@mui/material'

import Page from '../Layout/Page'

function TaskGroupErrorPage({ error, resetErrorBoundary }) {
  const { t } = useTranslation('TASK_GROUP_ERROR')
  const [expanded, setExpanded] = useState(false)
  const [details, setDetails] = useState(null)
  const [message, setMessage] = useState()

  useEffect(() => {
    if (error.isAxiosError) {
      //Error is a network error
      if (error.response) {
        if (error.response.data) {
          if (
            error.response.data.error &&
            error.response.data.error.code === 'previous_task_incomplete'
          ) {
            setMessage(t('CONFLICT'))
            setDetails(error.toJSON())
          } else if (
            error.response.data.error &&
            error.response.data.error.code === 'task_group_archived'
          ) {
            setMessage(t('ARCHIVED'))
            setDetails(error.toJSON())
          } else {
            setMessage(error.message)
            setDetails(error.toJSON())
          }
        }
      } else if (error.request) {
        setMessage(error.message)
        // The request was made but no response was received
        setDetails(error.toJSON())
      }
    } else {
      setMessage(error.message)
      setDetails(error.stack)
    }
  }, [error])

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  return (
    <Page>
      <Card>
        <CardContent>
          <Alert aria-label="taskGroup error" variant="filled" severity="error">
            <Typography>{message}</Typography>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <CardContent>
                <JSONPretty data={details} />
              </CardContent>
            </Collapse>
          </Alert>
        </CardContent>
        <CardActions>
          <Button size="small" color="primary" onClick={resetErrorBoundary}>
            {t('RETRY')}
          </Button>
          <Button
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show error stack"
          >
            {expanded ? t('HIDE_DETAILS') : t('SHOW_DETAILS')}
          </Button>
        </CardActions>
      </Card>
    </Page>
  )
}

TaskGroupErrorPage.propTypes = {
  error: PropTypes.object.isRequired,
  resetErrorBoundary: PropTypes.func.isRequired,
}

export default TaskGroupErrorPage
