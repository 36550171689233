import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { authenticate } from '../../App/app.service'

import { getProductsFromBarcode } from '../../Products/products.service'
import useAsyncAction from '../../hooks/useAsyncAction'
import { useErrorHandler } from 'react-error-boundary'
import confirmSound from '../../assets/sounds/state-change_confirm-up.wav'
import errorSound from '../../assets/sounds/alert_error-03.wav'
import redundantActionSound from '../../assets/sounds/navigation_hover-tap.wav'

export const CONFIRM_AUDIO_SOUND = new Audio(confirmSound)
export const ERROR_AUDIO_SOUND = new Audio(errorSound)
export const REDUNDANT_ACTION_AUDIO_SOUND = new Audio(redundantActionSound)
const DEFAULT_BARCODE_FORMATS = ['qr_code']
const PRODUCT_NOT_FOUND_ERROR_MESSAGE = 'Product not found'
const SCREENSHOT_DIMENSIONS = { width: 1024, height: 768 }

async function detectBarcode(barcodeDetector, img) {
  try {
    const detectedObjs = await barcodeDetector.detect(img)
    if (!detectedObjs.length) {
      return { value: null }
    }

    const obj = detectedObjs[0]
    if (obj.format === 'qr_code') {
      return { format: obj.format, value: obj.rawValue }
    }

    return { value: obj.rawValue }
  } catch (err) {
    return { value: null }
  }
}

async function capture(webcam, barcodeDetector, token) {
  const imageSrc = webcam?.getScreenshot(SCREENSHOT_DIMENSIONS)
  if (!imageSrc) {
    throw new Error('No picture available')
  }

  return new Promise((resolve, reject) => {
    const img = new Image()
    img.src = imageSrc
    img.onload = async () => {
      const { value } = await detectBarcode(barcodeDetector, img)
      if (!value) {
        return reject(new Error('No code detected'))
      }

      const products = await getProductsFromBarcode(value, token)
      if (!products || products.length !== 1) {
        return reject(new Error(PRODUCT_NOT_FOUND_ERROR_MESSAGE))
      }

      return resolve(products[0])
    }
  })
}

export function useBarCodeScanner({
  formats = DEFAULT_BARCODE_FORMATS,
  token,
  continuousProductScanSuccessHandler,
  continuousProductScanFailureHandler,
  continuousScanInterval,
  isScanMode,
}) {
  const barcodeDetector = useRef(null)
  const webcamRef = useRef(null)

  useEffect(() => {
    if (barcodeDetector.current || !window.BarcodeDetector) {
      return
    }

    barcodeDetector.current = new window.BarcodeDetector({ formats })
  }, [])

  useEffect(() => {
    if (!isScanMode || !token) {
      return
    }

    const intervalId = setInterval(async () => {
      try {
        const product = await capture(
          webcamRef.current,
          barcodeDetector.current,
          token,
        )
        continuousProductScanSuccessHandler(product)
      } catch (err) {
        continuousProductScanFailureHandler(err)
      }
    }, continuousScanInterval)

    return () => {
      if (!intervalId) {
        return
      }

      clearInterval(intervalId)
    }
  }, [isScanMode, token])

  return {
    webcamRef,
  }
}

export function useStockOutTask({ continuousScanInterval }) {
  const { t } = useTranslation('STOCK_OUT')
  const [isScanMode, setScanMode] = useState(false)
  const handleError = useErrorHandler()
  const [{ token } = {}, authenticationInProgress] = useAsyncAction(
    authenticate,
    {
      immediate: true,
      onError: handleError,
    },
  )
  const [products, setProducts] = useState([])
  const [{ scanFailure, scanInProgress }, setScanStatus] = useState({
    scanInProgress: false,
    scanFailure: null,
  })
  const { webcamRef } = useBarCodeScanner({
    token,
    continuousScanInterval,
    continuousProductScanSuccessHandler(product) {
      setProducts((products) => {
        if (products.some(({ _id }) => product._id === _id)) {
          REDUNDANT_ACTION_AUDIO_SOUND.play()
          return products
        }

        CONFIRM_AUDIO_SOUND.play()
        return [product, ...products]
      })
    },
    continuousProductScanFailureHandler(error) {
      if (error.message !== PRODUCT_NOT_FOUND_ERROR_MESSAGE) {
        return
      }

      return ERROR_AUDIO_SOUND.play()
    },
    isScanMode,
  })

  return {
    webcamRef,
    isScanMode,
    setScanMode,
    products,
    scanFailure,
    scanInProgress,
    authenticationInProgress,
    removeProduct(product) {
      setProducts([...products.filter(({ _id }) => product._id !== _id)])
    },
    async addProductByBarCode(code) {
      setScanStatus({ scanInProgress: true, scanFailure: null })
      const searchedProducts = await getProductsFromBarcode(code, token)
      if (searchedProducts?.length !== 1) {
        setScanStatus({
          scanInProgress: false,
          scanFailure: t('NO_PRODUCT_FOUND'),
        })
        return
      }

      if (products.some(({ _id }) => searchedProducts[0]._id === _id)) {
        setScanStatus({
          scanInProgress: false,
          scanFailure: t('PRODUCT_ALREADY_LISTED'),
        })
        return
      }

      setScanStatus({
        scanInProgress: false,
        scanFailure: null,
      })

      setProducts([searchedProducts[0], ...products])
    },
  }
}
