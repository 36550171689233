export const closeForBusiness = {
  action: 'close_for_business',
  comment: 'Comment from admin',
  agent_comment: 'Comment from agent',
  index: 0,
  state: 'created',
  payload: {},
  store_id: '0000000000000000STORE_ID',
  read_only: false,
}

export const makeCloseForBusiness = ({ _id, index, state } = {}) => ({
  ...closeForBusiness,
  _id,
  index: index || closeForBusiness.index,
  state: state || closeForBusiness.state,
})
