import { httpClient } from '../App/httpClient'
import { RESSOURCE_ID as TASK_RESSOURCE_ID } from '../Task/task.service'

export const RESSOURCE_ID = 'stores'

export const getStoreStatus = async (taskId, secret) => {
  const {
    data: {
      is_entry_door_unlocked: isEntryDoorUnlocked,
      is_open_for_business: isOpenForBusiness,
      last_status: lastStatus,
      next_status: nextStatus,
      display: { last_update: lastUpdate },
    },
  } = await httpClient.get(`tasks/${taskId}/store_status`, {
    headers: {
      Authorization: `Bearer ${secret}`,
    },
  })

  return {
    isEntryDoorUnlocked,
    isOpenForBusiness,
    lastStatus,
    nextStatus,
    lastUpdate,
  }
}

const forceExitAllCustomers = async (storeId, secret) => {
  await httpClient.post(
    `${RESSOURCE_ID}/${storeId}/notifications`,
    {
      action: 'exit_all',
    },
    {
      headers: {
        Authorization: `Bearer ${secret}`,
      },
    },
  )
}

const unlockStoreDoor = async (taskId, secret) => {
  await httpClient.post(
    `${TASK_RESSOURCE_ID}/${taskId}/store_status`,
    {
      is_entry_door_unlocked: true,
    },
    {
      headers: {
        Authorization: `Bearer ${secret}`,
      },
    },
  )
}

const lockStoreDoor = async (taskId, secret) => {
  await httpClient.post(
    `${TASK_RESSOURCE_ID}/${taskId}/store_status`,
    {
      is_entry_door_unlocked: false,
    },
    {
      headers: {
        Authorization: `Bearer ${secret}`,
      },
    },
  )
}

export { forceExitAllCustomers, unlockStoreDoor, lockStoreDoor }
