import React from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import { useTranslation } from 'react-i18next'

import { Box, Button } from '@mui/material'

import { ROUTES } from '../../App/App'
import { useConfirmationDialog } from '../../hooks/useConfirmDialog'

import { useTaskGroup } from '../taskGroup.context'

import NavigateNextButton from '../components/NavigationNextButton'
import NavigationPreviousButton from '../components/NavigationPreviousButton'

import { cancelAuditWarehouse } from './warehouseAudit.service'

const Navigation = () => {
  const { t } = useTranslation('NAVIGATION')
  const history = useHistory()
  const { taskgroupId, secret } = useParams()

  const getConfirmation = useConfirmationDialog()

  const islandscape = useMediaQuery({ query: '(orientation: landscape)' })

  const { isLastTask } = useTaskGroup()

  const handleNextButtonClick = async (next) => {
    if (isLastTask) {
      const confirm = await getConfirmation({
        title: t('COMPLETE_TASKGROUP_AUDIT_CONFIRM_TITLE'),
        message: t('COMPLETE_TASKGROUP_CONFIRM_MESSAGE'),
      })

      if (confirm) {
        next()
      }
    } else {
      next()
    }
  }

  const handleCancelButtonClick = async () => {
    await cancelAuditWarehouse(taskgroupId, secret)
    history.replace(ROUTES.CONGRATULATION)
  }

  return (
    <Box
      display="flex"
      height="100%"
      flexDirection={islandscape ? 'column' : 'row'}
      gap={1}
    >
      <Button
        style={{ flexGrow: '1' }}
        variant="contained"
        color="secondary"
        onClick={handleCancelButtonClick}
        data-testid="navigation-cancel-button"
      >
        {t('CANCEL_TASKGROUP_BUTTON')}
      </Button>

      <NavigationPreviousButton sx={{ flexGrow: '2' }} />

      <NavigateNextButton
        onClick={handleNextButtonClick}
        sx={{ flexGrow: '6' }}
      />
    </Box>
  )
}

export default Navigation
