import { date } from 'faker'
import { rest } from 'msw'
import { RESSOURCE_ID } from './stores.service'

const svgPlaceHolder = `<?xml version="1.0" encoding="utf-8"?><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
xmlns:xlink="http://www.w3.org/1999/xlink" height="100%" width="100%" viewBox="0 0 121.17 122.88">
<g>
    <path
        d="M7.23,0h106.7c1.98,0,3.78,0.81,5.1,2.12l0.02,0.02c0.2,0.2,0.39,0.42,0.57,0.64c0.17,0.2,0.32,0.41,0.45,0.64 c0.7,1.11,1.1,2.42,1.1,3.81v108.41c0,1.98-0.81,3.78-2.12,5.1l-0.02,0.02c-1.32,1.31-3.12,2.11-5.09,2.11H7.23 c-1.98,0-3.78-0.81-5.1-2.12l-0.02-0.02c-0.2-0.2-0.39-0.42-0.57-0.64c-0.17-0.2-0.33-0.42-0.45-0.64c-0.69-1.11-1.1-2.42-1.1-3.81 V7.24c0-1.98,0.81-3.78,2.12-5.1l0.02-0.02C3.46,0.81,5.26,0,7.23,0L7.23,0z M86.29,115.24L113.53,88V70.5l-44.74,44.74H86.29 L86.29,115.24z M113.53,98.8l-16.44,16.44h16.44V98.8L113.53,98.8z M57.99,115.24l55.54-55.54V42.2l-73.04,73.04H57.99 L57.99,115.24z M29.68,115.24l83.85-83.85V13.9L12.19,115.24H29.68L29.68,115.24z M7.64,108.98L108.98,7.64h-17.5L7.64,91.48 V108.98L7.64,108.98z M7.64,80.68L80.68,7.64h-17.5L7.64,63.18V80.68L7.64,80.68z M7.64,52.38L52.38,7.64h-17.5L7.64,34.88V52.38 L7.64,52.38z M7.64,24.08L24.08,7.64H7.64V24.08L7.64,24.08z" />
</g>
</svg>`

export let storeStatus = {
  last_status: {
    is_entry_door_unlocked: false,
    is_open_for_business: true,
    store_state: 'open',
  },
  next_status: {
    is_entry_door_unlocked: false,
    is_open_for_business: true,
    store_state: 'open',
  },
  is_entry_door_unlocked: false,
  is_open_for_business: true,
  display: {
    last_update: date.recent(),
  },
}

export const setNextStoreStatus = (newStatus) => {
  storeStatus = {
    last_status: {
      is_entry_door_unlocked: storeStatus.is_entry_door_unlocked,
      is_open_for_business: storeStatus.is_open_for_business,
      store_state: storeStatus.store_state,
    },
    next_status: {
      is_entry_door_unlocked:
        newStatus.is_entry_door_unlocked !== undefined
          ? newStatus.is_entry_door_unlocked
          : storeStatus.is_entry_door_unlocked,
      is_open_for_business:
        newStatus.is_open_for_business !== undefined
          ? newStatus.is_open_for_business
          : storeStatus.is_open_for_business,
      store_state:
        newStatus.store_state !== undefined
          ? newStatus.store_state
          : storeStatus.store_state,
    },
    is_entry_door_unlocked:
      newStatus.is_entry_door_unlocked !== undefined
        ? newStatus.is_entry_door_unlocked
        : storeStatus.is_entry_door_unlocked,
    is_open_for_business:
      newStatus.is_open_for_business !== undefined
        ? newStatus.is_open_for_business
        : storeStatus.is_open_for_business,
    store_state:
      newStatus.store_state !== undefined
        ? newStatus.store_state
        : storeStatus.store_state,
    display: {
      last_update: date.recent(),
    },
  }
}

export const setLastStoreStatus = () => {
  storeStatus.last_status = storeStatus.next_status
}

export const handlers = [
  rest.post(
    `http://localhost:3000/${RESSOURCE_ID}/:id/complete`,
    (req, res, ctx) => {
      return res(ctx.status(200))
    },
  ),
  rest.post(
    `http://localhost:3000/${RESSOURCE_ID}/:id/notifications`,
    (req, res, ctx) => {
      return res(ctx.status(200))
    },
  ),
  rest.get(
    `http://localhost:3000/${RESSOURCE_ID}/:storeId/sections/:section/map.svg`,
    (req, res, ctx) => {
      return res(ctx.status(200), ctx.body(svgPlaceHolder))
    },
  ),
]
