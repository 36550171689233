/* eslint-disable react/prop-types */
import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'

import Webcam from 'react-webcam'

import confirmSound from '../assets/sounds/state-change_confirm-up.wav'

const CONFIRM_AUDIO_SOUND = new Audio(confirmSound)
const FORMATS = ['code_39', 'ean_8', 'ean_13', 'qr_code']
const SCREENSHOT_FORMAT = 'image/jpeg'
const SCREENSHOT_QUALITY = 1
const VIDEO_CONSTRAINTS = {
  facingMode: 'environment',
  aspectRatio: 2,
}
const AUDIO = false

const BarcodeScanner = ({ onScan, ...props }) => {
  const webcamRef = useRef(null)

  const valueRef = useRef()

  const formats = props.formats || FORMATS
  const screenshotFormat = props.screenshotFormat || SCREENSHOT_FORMAT

  let barcodeDetector
  try {
    barcodeDetector = new window.BarcodeDetector({ formats })
  } catch {
    console.error('Barcode detector not available')
  }

  const detectBarcode = async (img) => {
    try {
      const detectedObjs = await barcodeDetector.detect(img)
      if (!detectedObjs.length) {
        return { value: null, format: null }
      }

      const obj = detectedObjs[0]

      return { format: obj.format, value: obj.rawValue }
    } catch (err) {
      return { format: null, value: null }
    }
  }

  const capture = async () => {
    const imageSrc = webcamRef?.current?.getScreenshot()

    if (imageSrc) {
      const img = new Image()
      img.src = imageSrc
      img.onload = async function () {
        const { value, format } = await detectBarcode(img)

        if (value && value !== valueRef.current) {
          CONFIRM_AUDIO_SOUND.play()
          valueRef.current = value
          onScan({ format, value })
        }
      }
    }
  }

  useEffect(() => {
    let interval

    if (barcodeDetector) {
      interval = setInterval(capture, 10)
    }

    return () => clearInterval(interval)
  }, [])

  return (
    <Webcam
      {...props}
      audio={AUDIO}
      videoConstraints={VIDEO_CONSTRAINTS}
      ref={webcamRef}
      screenshotFormat={screenshotFormat}
      screenshotQuality={SCREENSHOT_QUALITY}
    />
  )
}

BarcodeScanner.propTypes = {
  onScan: PropTypes.func,
}

export default BarcodeScanner
