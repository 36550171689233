import React from 'react'
import { useMediaQuery } from 'react-responsive'

import { Box } from '@mui/material'

import NavigationPreviousButton from '../components/NavigationPreviousButton'
import NavigateNextButton from '../components/NavigationNextButton'

const Navigation = () => {
  const islandscape = useMediaQuery({ query: '(orientation: landscape)' })

  return (
    <Box
      display="flex"
      height="100%"
      flexDirection={islandscape ? 'column' : 'row'}
      gap={1}
    >
      <NavigationPreviousButton sx={{ flexGrow: '2' }} />
      <NavigateNextButton sx={{ flexGrow: '6' }} />
    </Box>
  )
}

export default Navigation
