import React from 'react'
import * as Sentry from '@sentry/react'
import { ErrorBoundary } from 'react-error-boundary'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { QueryClientProvider, QueryClient } from '@tanstack/react-query'

import 'fontsource-roboto'
import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from '@mui/material/styles'
import blueGrey from '@mui/material/colors/blueGrey'

import Home from '../Home/Home'
import TaskGroup from '../TaskGroup/TaskGroup'
import Congratulation from '../Congratulation/Congratulation'

import TaskGroupErrorPage from '../TaskGroup/TaskGroupError'

import { ConfirmationDialogProvider } from '../hooks/useConfirmDialog'

export const ROUTES = {
  HOME: '/',
  CONGRATULATION: '/congratulation',
  TASKGROUP: '/tasks/:taskgroupId/:secret/',
  TASKGROUP_WITH_TASK: '/tasks/:taskgroupId/:secret/t/:taskId',
}

let theme = createTheme({
  typography: {
    htmlFontSize: 10,
  },
  palette: {
    text: {
      primary: blueGrey['900'],
    },
    primary: {
      main: '#343285',
    },
    secondary: {
      main: '#e94e6c',
    },
  },
})
theme = responsiveFontSizes(theme)

const queryClient = new QueryClient()

function App() {
  return (
    <Sentry.ErrorBoundary>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <LocalizationProvider
            dateAdapter={AdapterLuxon}
            inputFormat="dd/MM/yyyy"
            adapterLocale="fr"
          >
            <ConfirmationDialogProvider>
              <ErrorBoundary FallbackComponent={TaskGroupErrorPage}>
                <Router>
                  <Switch>
                    <Route exact path={ROUTES.HOME}>
                      <Home />
                    </Route>
                    <Route path={ROUTES.CONGRATULATION}>
                      <Congratulation />
                    </Route>
                    <Route path={ROUTES.TASKGROUP_WITH_TASK}>
                      <TaskGroup />
                    </Route>
                    <Route path={ROUTES.TASKGROUP}>
                      <TaskGroup />
                    </Route>
                  </Switch>
                </Router>
              </ErrorBoundary>
            </ConfirmationDialogProvider>
          </LocalizationProvider>
        </QueryClientProvider>
      </ThemeProvider>
    </Sentry.ErrorBoundary>
  )
}

export default App
