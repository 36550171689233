import React from 'react'
import PropTypes from 'prop-types'
import { useMediaQuery } from 'react-responsive'

import { Typography, Box } from '@mui/material'

function Product({
  capacity,
  capacityUnit,
  imageUrl,
  map,
  name,
  section,
  shelfId,
}) {
  const islandscape = useMediaQuery({ query: '(orientation: landscape)' })

  return (
    <Box
      display="flex"
      flexDirection={islandscape ? 'row' : 'column'}
      height="100%"
    >
      <Box flex={1} display="flex" flexDirection="column">
        <Box
          role="product-image"
          flex="1 1 0"
          display="flex"
          position="relative"
          alignItems="center"
          justifyContent="center"
        >
          <img
            data-testid={`product-image`}
            src={imageUrl}
            title={name}
            alt={name}
            style={{
              margin: 'auto',
              maxHeight: '100%',
              maxWidth: '100%',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
          />
        </Box>

        <Box role="product-info" alignSelf="center">
          <Typography
            aria-label="product name"
            variant="h6"
            data-testid={`product-name`}
          >
            {`${name} `}
            <code sx={{ color: 'grey' }}>
              {capacity && capacityUnit && `${capacity}${capacityUnit}`}
            </code>
          </Typography>
        </Box>
      </Box>

      {map ? (
        <Box flex={1} display="flex" flexDirection="column">
          <Box
            role="product-location-map"
            flex="1 1 0"
            display="flex"
            overflow="hidden"
            alignItems="center"
            justifyContent="center"
            data-testid={`product-map`}
          >
            {map}
          </Box>

          <Box
            role="product-section"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography
              aria-label="product section"
              variant="h6"
              color="textSecondary"
              data-testid={`product-section`}
            >
              {section ? `${shelfId.split('-')[0]} - ${section}` : shelfId}
            </Typography>
          </Box>
        </Box>
      ) : null}
    </Box>
  )
}

Product.propTypes = {
  capacity: PropTypes.number,
  capacityUnit: PropTypes.string,
  name: PropTypes.string,
  section: PropTypes.string,
  shelfId: PropTypes.string,
  imageUrl: PropTypes.string,
  map: PropTypes.element,
}

export default Product
