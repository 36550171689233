import { datatype } from 'faker'

export const customWithPictures = {
  _id: datatype.uuid(),
  action: 'custom_with_pictures',
  comment: 'Comment from admin',
  agent_comment: 'Comment from agent',
  index: 0,
  state: 'created',
  payload: {
    custom_title: 'FOOBAR',
    custom_body:
      '**strong** *emphasis* `code` [Link](https://reactjs.org) words words',
  },
}

export const makeCustomWithPictures = ({ _id, index, state } = {}) => ({
  ...customWithPictures,
  _id: _id || customWithPictures._id,
  index: index || customWithPictures.index,
  state: state || customWithPictures.state,
})
