import React from 'react'

import { Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'

function Step4() {
  const classes = useStyles()

  return (
    <>
      <Typography
        role="feedback-status"
        data-testid="feedback-last-step-label"
        className={classes.taskCompleted}
      >
        Le feedback a été envoyé.
      </Typography>
    </>
  )
}

const useStyles = makeStyles(() => ({
  taskCompleted: {
    color: 'green',
    fontSize: '30px',
  },
}))

export default Step4
