import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import JSONPretty from 'react-json-pretty'

import {
  Card,
  CardContent,
  CardActions,
  Button,
  Collapse,
  Typography,
} from '@mui/material'
import { Alert } from '@mui/material'

export class TaskError extends Error {
  constructor(task, ...params) {
    super(...params)

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, TaskError)
    }

    this.name = 'TaskError'
    this.task = task
  }
}

function TaskErrorCard({ error, resetErrorBoundary }) {
  const { t } = useTranslation('TASK_ERROR')
  const [expanded, setExpanded] = useState(false)
  const [details, setDetails] = useState(null)

  useEffect(() => {
    if (error.isAxiosError) {
      //Error is a network error
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        setDetails(error.response)
      } else if (error.request) {
        // The request was made but no response was received
        setDetails(error.toJSON())
      }
    } else {
      if (error instanceof TaskError) {
        setDetails(error.task)
      } else {
        setDetails(error.stack)
      }
    }
  }, [error])

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  return (
    <Card>
      <CardContent>
        <Alert aria-label="task error" variant="filled" severity="error">
          <Typography>{error.message}</Typography>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>
              <JSONPretty data={details} />
            </CardContent>
          </Collapse>
        </Alert>
      </CardContent>
      <CardActions>
        <Button size="small" color="primary" onClick={resetErrorBoundary}>
          {t('RETRY')}
        </Button>
        <Button
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show error stack"
        >
          {expanded ? t('HIDE_DETAILS') : t('SHOW_DETAILS')}
        </Button>
      </CardActions>
    </Card>
  )
}

TaskErrorCard.propTypes = {
  error: PropTypes.object.isRequired,
  resetErrorBoundary: PropTypes.func.isRequired,
}

export default TaskErrorCard
