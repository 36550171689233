import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'

import { Box } from '@mui/material'

import { useConfirmationDialog } from '../../hooks/useConfirmDialog'

import { useTaskGroup } from '../taskGroup.context'
import NavigationPreviousButton from '../components/NavigationPreviousButton'
import NavigateNextButton from '../components/NavigationNextButton'

const Navigation = () => {
  const { t } = useTranslation('NAVIGATION')
  const getConfirmation = useConfirmationDialog()

  const islandscape = useMediaQuery({ query: '(orientation: landscape)' })

  const { isLastTask } = useTaskGroup()

  const handleNextButtonClick = async (next) => {
    if (isLastTask) {
      const confirm = await getConfirmation({
        title: t('COMPLETE_TASKGROUP_RECEPTION_CONFIRM_TITLE'),
        message: t('COMPLETE_TASKGROUP_CONFIRM_MESSAGE'),
      })

      if (confirm) {
        next()
      }
    } else {
      next()
    }
  }

  return (
    <Box
      display="flex"
      height="100%"
      flexDirection={islandscape ? 'column' : 'row'}
      gap={1}
    >
      <NavigationPreviousButton sx={{ flexGrow: '2' }} />

      <NavigateNextButton
        onClick={handleNextButtonClick}
        sx={{ flexGrow: '6' }}
      />
    </Box>
  )
}

export default Navigation
