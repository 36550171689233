import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import Compressor from 'compressorjs'

import { Box, LinearProgress } from '@mui/material'
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera'

import useElementSize from '../hooks/useElementSize'
import { postImage } from '../TaskGroup/taskGroup.service'
import { useTaskGroup } from '../TaskGroup/taskGroup.context'

const IMAGE_QUALITY = 0.6

const ImageInputUpload = ({
  value,
  onChange,
  onUpload,
  onSuccess,
  onError,
  onSettled,
}) => {
  const { taskgroup, secret } = useTaskGroup()
  const [imgRef, { width: imgWidth }] = useElementSize()

  const [uploading, setUploading] = useState()

  const [img, setImg] = useState(value)

  useEffect(async () => {
    if (img) {
      setUploading(true)
      try {
        onUpload(img)
        const { image_url: imageUrl } = await postImage(
          taskgroup.id,
          img,
          `question_${Date.now()}.jpg`,
          secret,
        )

        onChange(imageUrl)
        onSuccess()
      } catch (error) {
        onError(error)
      } finally {
        setUploading(false)
        onSettled()
      }
    }
  }, [img])

  const onInputChange = useCallback(
    (event) => {
      const [file] = event.target.files

      if (!file) {
        return
      }

      const reader = new FileReader()

      new Compressor(file, {
        quality: IMAGE_QUALITY,
        success: (compressedFile) => {
          reader.readAsDataURL(compressedFile)
        },
      })

      reader.onloadend = () => {
        setImg(reader.result)
      }
    },
    [setImg],
  )

  const responsivePositionStyle = {
    margin: 'auto',
    maxHeight: '100%',
    maxWidth: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  }

  return (
    <Box
      display="flex"
      position="relative"
      height="100%"
      alignItems="center"
      justifyContent="center"
    >
      <label htmlFor="image_url">
        {img ? (
          <img
            data-testid="image-upload-content"
            ref={imgRef}
            src={img}
            style={{
              ...responsivePositionStyle,
              borderRadius: '4px',
            }}
          />
        ) : (
          <PhotoCameraIcon
            data-testid="image-upload-label"
            width="100%"
            height="100%"
            sx={{
              ...responsivePositionStyle,
              color: 'grey',
              width: '85%',
              height: '85%',
            }}
          />
        )}
      </label>

      <input
        data-testid="image-upload-input"
        accept="image/*"
        capture
        style={{ display: 'none' }}
        id="image_url"
        type="file"
        onChange={onInputChange}
        multiple={false}
      />

      {uploading && (
        <LinearProgress
          variant="indeterminate"
          sx={{
            height: '100%',
            width: imgWidth,
            borderRadius: '4px',
            opacity: 0.6,
          }}
        />
      )}
    </Box>
  )
}

ImageInputUpload.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  onError: PropTypes.func,
  onSettled: PropTypes.func,
  onSuccess: PropTypes.func,
  onUpload: PropTypes.func,
}

ImageInputUpload.defaultProps = {
  onChange: () => {},
  onError: () => {},
  onSettled: () => {},
  onSuccess: () => {},
  onUpload: () => {},
}

export default ImageInputUpload
