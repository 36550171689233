import { date, datatype } from 'faker'
import { rest } from 'msw'

import { RESSOURCE_ID } from '../taskGroup.service'

import { makeAudit } from '../../Task/Audit/audit.mock'
import { makeCustom } from '../../Task/Custom/custom.mock'
import { makeExpiration } from '../../Task/Expiration/expiration.mock'
import { makeOpenForBusiness } from '../../Task/OpenCloseStore/OpenForBusiness/openForBusiness.mock'
import { makeCloseForBusiness } from '../../Task/OpenCloseStore/CloseForBusiness/closeForBusiness.mock'
import { makePicking } from '../../Task/Picking/picking.mock'
import { makeReturn } from '../../Task/Return/return.mock'
import { makeRestocking } from '../../Task/Restocking/restocking.mock'
import { makeStockout } from '../../Task/StockOut/stockout.mock'
import { makePrice } from '../../Task/Price/price.mock'
import { makeTargetShelves } from '../../Task/TargetShelves/targetShelf.mock'
import { makeCustomWithPictures } from '../../Task/CustomWithPictures/customWithPictures.mock'

export const tasks = [
  makeCustom({ _id: 'B00000000000000000000001' }),
  makePicking({ _id: 'B00000000000000000000002' }),
  makeCloseForBusiness({ _id: 'B00000000000000000000003' }),
  makeStockout({ _id: 'B00000000000000000000004' }),
  makeExpiration({ _id: 'B00000000000000000000005' }),
  makeRestocking({ _id: 'B00000000000000000000006' }),
  makePrice({ _id: 'B00000000000000000000007' }),
  makeReturn({ _id: 'B0000000000000000000008' }),
  makeTargetShelves({ _id: 'B0000000000000000000009' }),
  makeAudit({ _id: 'B000000000000000000000010' }),
  makeCustomWithPictures({ _id: 'B000000000000000000000011' }),
  makeCustomWithPictures({ _id: 'B000000000000000000000012' }),
  makeOpenForBusiness({ _id: 'B000000000000000000000013' }),
]

export const tourSets = {
  _id: 'A00000000000000000000001',
  share_url: 'share/url',
  state: 'in_progress',
  type: 'tour_sets',
  stores: [
    {
      _id: '0000000000000000STORE_ID',
      address: '9 quai Stalingrad 76350 Oissel',
      name: 'Boxy #42 Oissel',
      short_code: 'OIS',
      warehouse_id: '000000000000WAREHOUSE_ID',
    },
    {
      _id: '1000000000000000STORE_ID',
      address: '47B rue Ernest Renan, Ivry-sur-Seine',
      name: 'Boxy # Ivry',
      short_code: 'IVR',
      warehouse_id: '000000000000WAREHOUSE_ID',
    },
  ],
  created_at: date.past(),
  task_ids: tasks.map((task) => task._id),
  current_task_index: 0,
}

export const makeTourSets = ({
  _id,
  current_task_index,
  state,
  task_ids,
} = {}) => ({
  ...tourSets,
  _id: _id || tourSets._id,
  current_task_index: current_task_index || tourSets.current_task_index,
  state: state || tourSets.state,
  task_ids: task_ids || tourSets.task_ids,
})

export const handlers = [
  rest.post(
    `http://localhost:3000/${RESSOURCE_ID}/:id/feedbacks`,
    (req, res, ctx) => {
      return res(ctx.status(200), ctx.json({ _id: datatype.uuid() }))
    },
  ),
]
