import React from 'react'
import ReactDOM from 'react-dom'

import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

import './i18n'
import App from './App/App.js'

const { NODE_ENV, REACT_APP_RUN_WITH_MOCKS } = process.env

if (NODE_ENV === 'development' && REACT_APP_RUN_WITH_MOCKS) {
  const { worker } = require('./mocks/browser')

  worker.start({
    onUnhandledRequest(req) {
      console.error(
        'Found an unhandled %s request to %s',
        req.method,
        req.url.href,
      )
    },
  })
}

if (NODE_ENV !== 'development') {
  Sentry.init({
    dsn:
      'https://458a0e36a6004746b40e968cf6487ae7@o272795.ingest.sentry.io/5677298',
    integrations: [new Integrations.BrowserTracing()],
  })
}

ReactDOM.render(<App />, document.getElementById('app'))
