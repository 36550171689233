/* eslint-disable react/prop-types */
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react'

import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import SendIcon from '@mui/icons-material/Send'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'

import { makeStyles } from '@mui/styles'
import {
  Button,
  Card,
  CardContent,
  TextField,
  TextareaAutosize,
  Typography,
  Grid,
} from '@mui/material'
import { Alert } from '@mui/material'

import { MobileDatePicker } from '@mui/x-date-pickers'

import WebCamera from '../../../components/WebCamera/WebCamera'

import { postImage } from '../../taskGroup.service'

import { TaskGroupAPIContext, TaskGroupStateContext } from '../toursets.context'
import { sendFeedback } from '../toursets.service'

const IssueType = {
  Expired: 'expired',
  MissingPriceTag: 'missing_price_tag',
  FullShelf: 'full_shelf',
  NotInShelf: 'not_in_shelf',
  Damaged: 'damaged',
  Other: 'other',
}

const EXPIRATION_DATE_VISIBLE_REASONS = new Set([IssueType.Expired])
const QUANTITY_HIDDEN_REASONS = new Set([IssueType.MissingPriceTag])
const COMMENT_HIDDEN_REASONS = new Set([
  IssueType.FullShelf,
  IssueType.MissingPriceTag,
])
const QUANTITY_REQUIRED_REASONS = new Set([
  IssueType.NotInShelf,
  IssueType.FullShelf,
  IssueType.Damaged,
  IssueType.Expired,
])

const PICTURE_REQUIRED_REASONS = new Set([IssueType.Damaged])

function Step3({ task }) {
  const { t } = useTranslation('TASKGROUP_COMMENT_DRAWER')

  const { taskgroupId, secret } = useParams()
  const classes = useStyles()
  const dispatch = useContext(TaskGroupAPIContext)
  const state = useContext(TaskGroupStateContext)

  const imageCaptureRef = useRef(null)

  const [photoContainer, setPhotoContainerVisibility] = useState(false)
  const [commentContainerVisible, setCommentContainerVisible] = useState(false)
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(false)
  const [photo, setPhoto] = useState(null)
  const commentContainerRef = useRef()

  const {
    isExpirationDateVisible,
    isQuantityHidden,
    isCommentHidden,
    isQuantityRequired,
    isInFullShelfProductCountRequired,
    isPhotoRequired,
  } = useMemo(() => {
    return {
      isExpirationDateVisible: EXPIRATION_DATE_VISIBLE_REASONS.has(
        state.reason,
      ),
      isQuantityHidden: QUANTITY_HIDDEN_REASONS.has(state.reason),
      isCommentHidden: COMMENT_HIDDEN_REASONS.has(state.reason),
      isQuantityRequired: QUANTITY_REQUIRED_REASONS.has(state.reason),
      isInFullShelfProductCountRequired: IssueType.FullShelf === state.reason,
      isExpirationDateRequired: EXPIRATION_DATE_VISIBLE_REASONS.has(
        state.reason,
      ),
      isPhotoRequired: PICTURE_REQUIRED_REASONS.has(state.reason),
    }
  }, [state.reason])

  useEffect(() => {
    const {
      reason,
      comment,
      product_count: productCount,
      full_shelf_product_count: inFullShelfProductCount,
      expiration_date: expirationDate,
    } = state

    if (reason === IssueType.Other && !comment) {
      setIsNextButtonDisabled(true)
    } else if (isQuantityRequired && !productCount) {
      setIsNextButtonDisabled(true)
    } else if (isInFullShelfProductCountRequired && !inFullShelfProductCount) {
      setIsNextButtonDisabled(true)
    } else if (isExpirationDateVisible && !expirationDate) {
      setIsNextButtonDisabled(true)
    } else if (isPhotoRequired && !photo) {
      setIsNextButtonDisabled(true)
    } else {
      setIsNextButtonDisabled(false)
    }
  }, [
    state,
    isQuantityRequired,
    isExpirationDateVisible,
    isPhotoRequired,
    photo,
  ])

  const capture = useCallback(async () => {
    const photo = await imageCaptureRef?.current?.takeBase64Photo()
    setPhoto(photo)
  }, [])

  useEffect(() => {
    if (commentContainerVisible) {
      commentContainerRef.current.focus()
    }
  }, [commentContainerVisible])

  const send = async () => {
    if (state.comment === null && state.reason === IssueType.Other) {
      return
    }
    if (!state.expiration_date && state.reason === IssueType.Expired) {
      return
    }
    if (
      state.product_count === null &&
      ![IssueType.Other, IssueType.MissingPriceTag].includes(state.reason)
    ) {
      return
    }

    setIsNextButtonDisabled(true)

    const feedback = {
      task_id: task.id,
      comment: state.comment,
      issue_type: state.reason,
      image_urls: [],
    }

    if (
      (state.reason === IssueType.Other && state.product) ||
      state.reason !== IssueType.Other
    ) {
      feedback.product_id = state.product._id
    }

    if (photo) {
      const { image_url } = await postImage(
        taskgroupId,
        photo,
        `feedback_${Date.now()}.jpg`,
        secret,
      )
      feedback.image_urls.push(image_url)
    }

    if (state.product_count !== '') {
      feedback.product_count = state.product_count
    }

    if (state.full_shelf_product_count !== '') {
      feedback.full_shelf_product_count = state.full_shelf_product_count
    }

    if (state.expiration_date) {
      feedback.expiration_date = state.expiration_date.toJSDate()
    }

    try {
      const res = await sendFeedback(taskgroupId, feedback, secret)

      if (res._id) {
        dispatch({ action: 'completed' })
      }
    } catch (err) {
      console.error(err)
    } finally {
      setIsNextButtonDisabled(false)
    }
  }

  return (
    <>
      <div className={classes.taskContainer}>
        <div className={classes.taskLine}>
          {isQuantityHidden ? (
            <div className={classes.cardPlaceholder}></div>
          ) : (
            <Card
              role="card-quantity"
              elevation={0}
              className={classes.cardStyle}
            >
              <CardContent>
                <div className={classes.itemContainer}>
                  <div>
                    <Typography variant="h5" component="h3">
                      {state.reason === IssueType.FullShelf
                        ? t('IN_EXCESS_QUANTITY')
                        : t('QUANTITY')}
                    </Typography>

                    <Button
                      data-testId="feedback-decrement-quantity-button"
                      disabled={state.product_count <= 0}
                      className={classes.quantityButton}
                      color="primary"
                      variant="contained"
                      onClick={() =>
                        dispatch({
                          action: 'changeQuantity',
                          payload: state.product_count - 1,
                        })
                      }
                    >
                      <RemoveIcon />
                    </Button>
                    <TextField
                      variant="standard"
                      autoComplete="off"
                      placeholder="Quantité"
                      type="number"
                      label=""
                      inputProps={{
                        inputMode: 'numeric',
                        pattern: '[0-9]*',
                        style: { textAlign: 'center' },
                      }}
                      className={classes.quantityInput}
                      value={state.product_count}
                      onChange={(text) =>
                        dispatch({
                          action: 'changeQuantity',
                          payload: text.target.value,
                        })
                      }
                    />

                    <Button
                      data-testId="feedback-increment-quantity-button"
                      className={classes.quantityButton}
                      color="primary"
                      variant="contained"
                      onClick={() =>
                        dispatch({
                          action: 'changeQuantity',
                          payload: state.product_count + 1,
                        })
                      }
                    >
                      <AddIcon />
                    </Button>
                  </div>
                </div>
              </CardContent>
            </Card>
          )}
          <Card
            role="card-image"
            elevation={0}
            className={classes.cardStyle}
            data-testId="feedback-add-picture-button"
            onClick={() => setPhotoContainerVisibility(true)}
          >
            <CardContent>
              <div className={classes.itemContainer}>
                {!photoContainer && (
                  <Typography variant="h5" component="h3">
                    {t('ADD_PHOTO')}
                  </Typography>
                )}
                {photoContainer && !photo && (
                  <div>
                    <div>
                      <WebCamera
                        imageCaptureRef={imageCaptureRef}
                        aspectRatio={2}
                        height={140}
                      />
                    </div>

                    <Button
                      color="primary"
                      variant="contained"
                      onClick={capture}
                    >
                      capture
                    </Button>
                  </div>
                )}
                {photoContainer && photo && (
                  <div>
                    <div>
                      <img src={photo} height="150" />
                    </div>
                    <div>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => {
                          setPhoto(null)
                          setPhotoContainerVisibility(true)
                        }}
                      >
                        Reprendre photo
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </CardContent>
          </Card>
        </div>
        <div className={classes.taskLine}>
          {isExpirationDateVisible ? (
            <Card
              role="card-expiration-date"
              elevation={0}
              className={classes.cardStyle}
            >
              <CardContent>
                <div className={classes.itemContainer}>
                  <div>
                    <Typography variant="h5" component="h3">
                      {t('EXPIRATION_DATE')}
                    </Typography>
                    <MobileDatePicker
                      showToolbar={false}
                      label="Date"
                      value={state.expiration_date}
                      views={['year', 'month', 'day']}
                      openTo="day"
                      onChange={(newValue) => {
                        dispatch({
                          action: 'changeExpirationDate',
                          payload: newValue,
                        })
                      }}
                      renderInput={(props) => (
                        <TextField
                          InputProps={{
                            'data-testid': `feedback-date-input`,
                          }}
                          fullWidth
                          {...props}
                          margin="dense"
                          required
                          size="small"
                        />
                      )}
                    />
                  </div>
                </div>
              </CardContent>
            </Card>
          ) : isInFullShelfProductCountRequired ? (
            <Card
              role="in-full-shelf-product-count"
              elevation={0}
              className={classes.cardStyle}
            >
              <CardContent>
                <div className={classes.itemContainer}>
                  <div>
                    <Typography variant="h5" component="h3">
                      {t('FULL_SHELF_PRODUCT_COUNT')}
                    </Typography>

                    <Button
                      disabled={state.full_shelf_product_count <= 0}
                      className={classes.quantityButton}
                      color="primary"
                      variant="contained"
                      data-testId="feedback-decrement-shelf-product-count-button"
                      onClick={() =>
                        dispatch({
                          action: 'decrementInFullShelfProductCount',
                        })
                      }
                    >
                      <RemoveIcon />
                    </Button>
                    <TextField
                      variant="standard"
                      autoComplete="off"
                      placeholder="In full shelf product count"
                      type="number"
                      label=""
                      inputProps={{
                        inputMode: 'numeric',
                        pattern: '[0-9]*',
                        style: { textAlign: 'center' },
                      }}
                      className={classes.quantityInput}
                      value={state.full_shelf_product_count}
                      onChange={(text) =>
                        dispatch({
                          action: 'changeInFullShelfProductCount',
                          payload: text.target.value,
                        })
                      }
                    />

                    <Button
                      className={classes.quantityButton}
                      color="primary"
                      variant="contained"
                      data-testId="feedback-increment-shelf-product-count-button"
                      onClick={() =>
                        dispatch({
                          action: 'incrementInFullShelfProductCount',
                        })
                      }
                    >
                      <AddIcon />
                    </Button>
                  </div>
                </div>
              </CardContent>
            </Card>
          ) : (
            <div className={classes.cardPlaceholder}></div>
          )}
          {isCommentHidden ? (
            <div className={classes.cardPlaceholder}></div>
          ) : (
            <Card
              role="card-comment"
              elevation={0}
              className={classes.cardStyle}
              onClick={() => setCommentContainerVisible(true)}
            >
              <div className={classes.textAreaContainer}>
                {!commentContainerVisible ? (
                  <Typography variant="h5" component="h3">
                    {t('COMMENT')}
                  </Typography>
                ) : (
                  <TextareaAutosize
                    data-testId="feedback-comment-input"
                    ref={commentContainerRef}
                    autoComplete="off"
                    id="standard-basic"
                    placeholder="Commentaire"
                    value={state.comment}
                    className={classes.textAreaComment}
                    onChange={(text) =>
                      dispatch({
                        action: 'changeComment',
                        payload: text.target.value,
                      })
                    }
                  />
                )}
              </div>
            </Card>
          )}
        </div>
      </div>

      <Grid container>
        <Grid item xs={2}></Grid>
        <Grid item xs={8}>
          {state.reason === IssueType.Damaged && isNextButtonDisabled && (
            <Alert severity="warning">
              Vous devez indiquer la quantité de produits abîmés et les prendre
              en photo pour pouvoir envoyer les informations.
            </Alert>
          )}
        </Grid>
        <Grid item xs={2}>
          <div className={classes.actionContainer}>
            <Button
              data-testid="feedback-next-step-button"
              disabled={isNextButtonDisabled}
              aria-label="taskGroup send button"
              className={classes.button}
              color="primary"
              variant="contained"
              onClick={send}
            >
              <SendIcon />
            </Button>
          </div>
        </Grid>
      </Grid>
    </>
  )
}

const useStyles = makeStyles(() => ({
  cardStyle: {
    height: '200px',
    width: '200px',
    flex: 1,
    margin: '10px',
    textAlign: 'center',
    fontSize: '20px',
    borderRadius: '15px',
    border: '2px solid #808080',
  },
  cardPlaceholder: {
    height: '200px',
    width: '200px',
    flex: 1,
    margin: '10px',
    borderRadius: '15px',
    backgroundColor: 'whitesmoke',
  },
  taskContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '600px',
  },
  taskLine: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-evenly;',
  },
  actionContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
  },
  itemContainer: {
    height: '180px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    color: '#808080',
  },
  textAreaContainer: {
    color: '#808080',
    height: '250px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  textAreaComment: {
    padding: '15px',
    height: '100% !important',
    width: '100%',
    border: 'none',
    boxSizing: 'border-box',
    outline: 'none',
  },
  button: {
    height: '60px',
    width: '125px',
  },
  quantityButton: {
    margin: '0 7px',
    marginBottom: '20px',
    padding: '6px',
    minWidth: '20px',
  },
  quantityInput: {
    textAlign: 'center',
    width: '110px',
  },
}))

export default Step3
