import { httpClient } from '../App/httpClient'

export const RESSOURCE_ID = 'tasks'

import { TASKS_CONFIGURATION } from './task.config'

import { parsePayload as parseAuditPayload } from './Audit/audit.service'
import { parsePayload as parseExpirationPayload } from './Expiration/expiration.service'
import { parsePayload as parseCustomPayload } from './Custom/custom.service'
import { parsePayload as parseCustomWithPicturesPayload } from './CustomWithPictures/customWithPictures.service'
import { parsePayload as parseCloseForBusinessPayload } from './OpenCloseStore/CloseForBusiness/closeForBusiness.service'
import { parsePayload as parseOpenForBusinessPayload } from './OpenCloseStore/OpenForBusiness/openForBusiness.service'
import { parsePayload as parsePickingPayload } from './Picking/picking.service'
import { parsePayload as parsePricePayload } from './Price/price.service'
import { parsePayload as parseRestockingPayload } from './Restocking/restocking.service'
import { parsePayload as parseAuditWarehousePayload } from './AuditWarehouse/auditWarehouse.service'
import { parsePayload as parseReturnTaskPayload } from './Return/return.service'
import { parsePayload as parseTargetShelvesPayload } from './TargetShelves/targetShelves.service'
import { parsePayload as parseRestockingWarehousePayload } from './RestockingWarehouse/restockingWarehouse.service'
import { parsePayload as parseWarehouseRestockingFromStorePayload } from './WarehouseRestockingFromStore/warehouseRestockingFromStore.service'
import { parsePayload as parseQuestionPayload } from './Question/question.service'

import { parseMenu as parseAuditMenu } from './Audit/audit.service'
import { parseMenu as parseExpirationMenu } from './Expiration/expiration.service'
import { parseMenu as parseCustomMenu } from './Custom/custom.service'
import { parseMenu as parseCustomWithPicturesMenu } from './CustomWithPictures/customWithPictures.service'
import { parseMenu as parseCloseForBusinessMenu } from './OpenCloseStore/CloseForBusiness/closeForBusiness.service'
import { parseMenu as parseOpenForBusinessMenu } from './OpenCloseStore/OpenForBusiness/openForBusiness.service'
import { parseMenu as parsePickingMenu } from './Picking/picking.service'
import { parseMenu as parsePriceMenu } from './Price/price.service'
import { parseMenu as parseRestockingMenu } from './Restocking/restocking.service'
import { parseMenu as parseAuditWarehouseMenu } from './AuditWarehouse/auditWarehouse.service'
import { parseMenu as parseReturnTaskMenu } from './Return/return.service'
import { parseMenu as parseTargetShelvesMenu } from './TargetShelves/targetShelves.service'
import { parseMenu as parseRestockingWarehouseMenu } from './RestockingWarehouse/restockingWarehouse.service'
import { parseMenu as parseWarehouseRestockingFromStoreMenu } from './WarehouseRestockingFromStore/warehouseRestockingFromStore.service'
import { parseMenu as parseQuestionMenu } from './Question/question.service'

import { parseCompletePayload as parseCompleteAuditPayload } from './Audit/audit.service'
import { parseCompletePayload as parseCompletePickingPayload } from './Picking/picking.service'
import { parseCompletePayload as parseCompleteExpirationPayload } from './Expiration/expiration.service'
import { parseCompletePayload as parseCompleteAuditWarehousePayload } from './AuditWarehouse/auditWarehouse.service'
import { parseCompletePayload as parseCompleteCustomWithPicturesPayload } from './CustomWithPictures/customWithPictures.service'
import { parseCompletePayload as parseCompleteReturnTaskPayload } from './Return/return.service'
import { parseCompletePayload as parseCompleteStockoutPayload } from './StockOut/stockout.service'
import { parseCompletePayload as parseCompleteRestockingWarehousePayload } from './RestockingWarehouse/restockingWarehouse.service'
import { parseCompletePayload as parseCompleteQuestionPayload } from './Question/question.service'
import { parseCompletePayload as parseCompleteWarehouseRestockingFromStorePayload } from './WarehouseRestockingFromStore/warehouseRestockingFromStore.service'

const parseTaskPayload = (payload, action) => {
  switch (action) {
    case 'audit':
      return parseAuditPayload(payload)
    case 'picking':
      return parsePickingPayload(payload)
    case 'expiration':
      return parseExpirationPayload(payload)
    case 'audit_warehouse_shelf':
      return parseAuditWarehousePayload(payload)
    case 'custom':
      return parseCustomPayload(payload)
    case 'custom_with_pictures':
      return parseCustomWithPicturesPayload(payload)
    case 'close_for_business':
      return parseCloseForBusinessPayload(payload)
    case 'open_for_business':
      return parseOpenForBusinessPayload(payload)
    case 'restocking':
      return parseRestockingPayload(payload)
    case 'price':
      return parsePricePayload(payload)
    case 'return_task':
      return parseReturnTaskPayload(payload)
    case 'target_shelves':
      return parseTargetShelvesPayload(payload)
    case 'restocking_warehouse':
      return parseRestockingWarehousePayload(payload)
    case 'warehouse_restocking_from_store':
      return parseWarehouseRestockingFromStorePayload(payload)
    case 'question':
      return parseQuestionPayload(payload)
    default:
      return {}
  }
}

const parseCompleteTaskPayload = (payload, action) => {
  switch (action) {
    case 'audit':
      return parseCompleteAuditPayload(payload)
    case 'picking':
      return parseCompletePickingPayload(payload)
    case 'expiration':
      return parseCompleteExpirationPayload(payload)
    case 'audit_warehouse_shelf':
      return parseCompleteAuditWarehousePayload(payload)
    case 'custom_with_pictures':
      return parseCompleteCustomWithPicturesPayload(payload)
    case 'stock_out':
      return parseCompleteStockoutPayload(payload)
    case 'return_task':
      return parseCompleteReturnTaskPayload(payload)
    case 'restocking_warehouse':
      return parseCompleteRestockingWarehousePayload(payload)
    case 'question':
      return parseCompleteQuestionPayload(payload)
    case 'warehouse_restocking_from_store':
      return parseCompleteWarehouseRestockingFromStorePayload(payload)
    default:
      return {}
  }
}

const parseTaskMenu = (task) => {
  const config = TASKS_CONFIGURATION[task.action.toUpperCase()]

  return {
    id: task._id,
    action: task.action,
    config,
    payload: parseTaskMenuPayload(task.payload, task.action),
    state: task.state,
  }
}

const parseTaskMenuPayload = (payload, action) => {
  switch (action) {
    case 'audit':
      return parseAuditMenu(payload)
    case 'picking':
      return parsePickingMenu(payload)
    case 'expiration':
      return parseExpirationMenu(payload)
    case 'audit_warehouse_shelf':
      return parseAuditWarehouseMenu(payload)
    case 'custom':
      return parseCustomMenu(payload)
    case 'custom_with_pictures':
      return parseCustomWithPicturesMenu(payload)
    case 'close_for_business':
      return parseCloseForBusinessMenu(payload)
    case 'open_for_business':
      return parseOpenForBusinessMenu(payload)
    case 'restocking':
      return parseRestockingMenu(payload)
    case 'price':
      return parsePriceMenu(payload)
    case 'return_task':
      return parseReturnTaskMenu(payload)
    case 'target_shelves':
      return parseTargetShelvesMenu(payload)
    case 'audit_warehouse':
      return parseAuditWarehouseMenu(payload)
    case 'restocking_warehouse':
      return parseRestockingWarehouseMenu(payload)
    case 'warehouse_restocking_from_store':
      return parseWarehouseRestockingFromStoreMenu(payload)
    case 'question':
      return parseQuestionMenu(payload)
    default:
      return {}
  }
}

const parseTask = (task) => {
  const config = TASKS_CONFIGURATION[task.action.toUpperCase()]

  return {
    action: task.action,
    agentComment: task.agent_comment,
    comment: task.comment,
    config,
    firstProductExpirationDisplayDate:
      task.display?.first_product_expiration_date,
    id: task._id,
    index: task.index,
    isNoNextTaskToComplete: false,
    payload: parseTaskPayload(task.payload, task.action),
    price: task.display?.price,
    readOnly: task.read_only,
    state: task.state,
    storeId: task.store_id,
  }
}

export const getTask = async ({ queryKey }) => {
  const { id, taskgroupId, secret } = queryKey[1]
  let response

  if (id) {
    response = await httpClient.get(`task_groups/${taskgroupId}/tasks/${id}`, {
      headers: {
        Authorization: `Bearer ${secret}`,
      },
    })
  } else {
    response = await httpClient.get(`task_groups/${taskgroupId}/current_task`, {
      headers: {
        Authorization: `Bearer ${secret}`,
      },
    })
  }

  const { data: task } = response

  return parseTask(task)
}

const completeTask = async ({ id, action, payload }, secret) => {
  await httpClient.post(
    `${RESSOURCE_ID}/${id}/complete`,
    parseCompleteTaskPayload(payload, action),
    {
      headers: {
        Authorization: `Bearer ${secret}`,
      },
    },
  )
}

export { completeTask, parseTask, parseTaskMenu }
