import axios from 'axios'

const API_HOST = process.env.REACT_APP_API_HOST
const API_VERSION = '2020-12-25'

function makeHttpClient({ baseURL, timeout = 30000 }) {
  const httpClient = axios.create({
    baseURL,
    timeout,
    headers: { 'Api-Version': API_VERSION },
  })
  return httpClient
}

export const httpClient = makeHttpClient({
  baseURL: API_HOST,
})
