import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { ReactComponent as WarehouseMapSvg } from '../assets/warehouseMap.svg'

function WarehouseMap({ shelfId, ...props }) {
  useEffect(() => {
    try {
      const [sectionId] = shelfId.match(/^(Z[0-9]{2}-A[0-9]{2}-S[0-9]{2})/)

      // This works because SVGs < 10,000KB are inlined by CRA
      // https://create-react-app.dev/docs/adding-images-fonts-and-files
      document
        .querySelectorAll('#warehouse-map .warehouse-section')
        .forEach((el) => {
          if (el) {
            el.classList.remove('active')

            if (el.id === sectionId) {
              el.classList.add('active')
            }
          }
        })
    } catch (err) {
      console.error(err)
    }
  }, [shelfId])

  return <WarehouseMapSvg width="100%" height="100%" {...props} />
}

WarehouseMap.propTypes = {
  shelfId: PropTypes.string,
  warehouseMapUrl: PropTypes.string,
}

export default WarehouseMap
