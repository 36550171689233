export const question = {
  action: 'question',
  comment: 'Comment from admin',
  agent_comment: 'Comment from agent',
  index: 0,
  state: 'created',
  payload: {
    custom_title: 'La LED sur le botier est verte ?',
    custom_body:
      '# Markdown \n \n # H1 \n ## H2 \n ### H3 \n\n **Bold text** \n\n *Italic text* \n\n > blockquote \n\n `code` \n\n - List \n - Second item \n - Third item \n\n --- \n\n  # GFM flavored \n \n https://reactjs.org and contact@example.com. \n \n ## Strikethrough \n \n ~one~ or ~~two~~ tildes. \n \n ## Table \n \n | a | b  |  c |  d  | \n | - | :- | -: | :-: | \n \n  ## Tasklist \n \n * [ ] to do \n  * [x] done',
    reasons: ['LED rouge fixe', 'LED rouge clignotante', 'LED bleue'],
  },
}

export const makeQuestion = ({ _id, index, state } = {}) => ({
  ...question,
  _id,
  index: index || question.index,
  state: state || question.state,
})
