import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'
import { Fab, Paper, Typography } from '@mui/material'
import clsx from 'clsx'
import ClearIcon from '@mui/icons-material/Clear'

const ScannedProduct = ({ product, mode, removeProduct }) => {
  const classes = useStyles({ mode })
  return (
    <Paper
      role="scanned-product"
      elevation={3}
      sx={{ margin: 2 }}
      className={clsx({
        [classes.container]: true,
        [classes.cardContainer]: mode === 'card',
        [classes.lineContainer]: mode === 'line',
      })}
    >
      <div
        className={clsx({
          [classes.cardPictureContainer]: mode === 'card',
          [classes.linePictureContainer]: mode === 'line',
        })}
      >
        <img
          className={classes.image}
          alt={product.name}
          src={product.product_image_url}
        />
      </div>

      <div className={classes.labelContainer}>
        <Typography variant="subtitle1">{product.name}</Typography>
      </div>

      <Fab
        role="remove-product-button"
        size={mode === 'card' ? 'large' : 'small'}
        className={classes.deleteButton}
        color="secondary"
        onClick={() => removeProduct(product)}
      >
        <ClearIcon />
      </Fab>
    </Paper>
  )
}

const useStyles = makeStyles((theme) => ({
  container: () => ({
    position: 'relative',
    padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  }),
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    minWidth: 200,
    maxWidth: 200,
  },
  cardPictureContainer: {
    height: 'auto',
    maxHeight: '50%',
    width: 'auto',
  },
  linePictureContainer: {
    height: 100,
    width: 100,
  },
  labelContainer: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
  },
  lineContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(4),
    alignItems: 'center',
  },
  image: {
    maxWidth: '100%',
    display: 'block',
    width: '100%',
    height: '100%',
    userDrag: 'none',
  },
  deleteButton: {
    position: 'absolute',
    top: 5,
    right: 10,
    transform: 'translate(50%, -50%)',
  },
}))

ScannedProduct.propTypes = {
  mode: PropTypes.oneOf(['card', 'line']).isRequired,
  removeProduct: PropTypes.func.isRequired,
  product: PropTypes.shape({
    name: PropTypes.string.isRequired,
    product_image_url: PropTypes.string.isRequired,
  }).isRequired,
}

export default ScannedProduct
