import React from 'react'
import PropTypes from 'prop-types'

import { LinearProgress, Box } from '@mui/material'

function TaskGroupProgressBar({ progression }) {
  return (
    <Box width="100%">
      <LinearProgress
        sx={{ height: '10px' }}
        role="progressbar"
        aria-label="taskgroup-progression"
        variant="determinate"
        value={progression}
      />
    </Box>
  )
}

TaskGroupProgressBar.propTypes = {
  progression: PropTypes.number.isRequired,
}

export default TaskGroupProgressBar
