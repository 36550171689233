import { datatype } from 'faker'

export const restocking = {
  _id: datatype.uuid(),
  action: 'restocking',
  comment: 'Comment from admin',
  agent_comment: 'Comment from agent',
  index: 0,
  state: 'created',
  payload: {},
  store_id: '0000000000000000STORE_ID',
  read_only: false,
}

export const makeRestocking = ({ _id, index, state } = {}) => ({
  ...restocking,
  _id: _id || restocking._id,
  index: index || restocking.index,
  state: state || restocking.state,
})
