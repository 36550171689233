import { httpClient } from '../App/httpClient'

export const getProductsFromBarcode = async (barcode, token) => {
  if (!token) {
    return null
  }

  const { data } = await httpClient.get(`/products?filter=${barcode}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  return data
}
