import { useState } from 'react'

import useAsyncAction from './useAsyncAction'
import useInterval from './useInterval'

function useAsyncActionInterval(action, options) {
  const { delay, immediate } = options
  const [isIntervaling, setIsIntervaling] = useState()

  const [data, isLoading, execute] = useAsyncAction(action, {
    ...options,
  })
  const [startInterval, stopInterval] = useInterval(execute, {
    delay,
    immediate,
  })

  return [
    data,
    isIntervaling || isLoading,
    {
      startInterval: () => {
        setIsIntervaling(true)
        startInterval()
      },
      stopInterval: () => {
        setIsIntervaling(false)
        stopInterval()
      },
    },
  ]
}

export default useAsyncActionInterval
