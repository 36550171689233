import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { Box } from '@mui/material'

function Restocking({ setToCompletable }) {
  useEffect(() => {
    setToCompletable()
  }, [])

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Box flex={1} role="task-content"></Box>

      <Box role="task-actions"></Box>
    </Box>
  )
}

Restocking.propTypes = {
  setToCompletable: PropTypes.func,
}

export default Restocking
