import React, { useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import { httpClient } from '../App/httpClient'
import { useParams } from 'react-router-dom'

import { Box } from '@mui/material'

function StoreSectionMap({ storeId, section, shelfGroupId }) {
  const { secret } = useParams()
  const [map, setMap] = useState()

  useEffect(async () => {
    // should be in stores service
    const response = await httpClient.get(
      `stores/${storeId}/sections/${section}/map.svg?shelf_group=${shelfGroupId}`,
      {
        headers: {
          Authorization: `Bearer ${secret}`,
        },
      },
    )
    setMap(response.data)
  }, [storeId, section, shelfGroupId])

  return (
    <Box width="100%" height="100%" dangerouslySetInnerHTML={{ __html: map }} />
  )
}

StoreSectionMap.propTypes = {
  storeId: PropTypes.string,
  section: PropTypes.string,
  shelfGroupId: PropTypes.string,
  fillColor: PropTypes.string,
}

export default StoreSectionMap
