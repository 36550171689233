import { datatype } from 'faker'

export const openForBusiness = {
  _id: datatype.uuid(),
  action: 'open_for_business',
  comment: 'Comment from admin',
  agent_comment: 'Comment from agent',
  index: 0,
  state: 'created',
  payload: {},
  store_id: '0000000000000000STORE_ID',
  read_only: false,
}

export const makeOpenForBusiness = ({ _id, index, state } = {}) => ({
  ...openForBusiness,
  _id: _id || openForBusiness._id,
  index: index || openForBusiness.index,
  state: state || openForBusiness.state,
})
