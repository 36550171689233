import React, { useCallback, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@mui/styles'
import CircularProgress from '@mui/material/CircularProgress'
import { LoadingButton } from '@mui/lab'

import useInterval from '../hooks/useInterval'

const DEFAULT_COOLDOWN_DELAY = 30000 // ms

const CooldownButton = ({ children, disabled, delay, ...buttonProps }) => {
  const classes = useStyles()
  const mountedDate = useRef(Date.now())
  const [isEnabled, setIsEnabled] = useState(false)
  const [progress, setProgress] = useState(0)
  const coolDownDelay = delay ?? DEFAULT_COOLDOWN_DELAY

  const [, stopInterval] = useInterval(
    () => {
      if (coolDownDelay <= 0) {
        setIsEnabled(true)
        setProgress(100)
        stopInterval()
        return
      }

      const elapsedTime = Date.now() - mountedDate.current
      setProgress(
        Math.min(100, Math.round((elapsedTime * 100) / coolDownDelay)),
      )

      if (progress >= 100) {
        setIsEnabled(true)
        stopInterval()
      }
    },
    {
      delay: coolDownDelay / 100,
      immediate: true,
    },
  )

  const resetAndClick = useCallback((e) => {
    buttonProps.onClick(e)
  })

  return (
    <LoadingButton
      {...buttonProps}
      onClick={resetAndClick}
      disabled={disabled || !isEnabled}
      aria-label={buttonProps['aria-label'] ?? 'Cooldown button'}
    >
      {isEnabled ? (
        children
      ) : (
        <CircularProgress
          variant="determinate"
          value={progress}
          color={buttonProps.color || 'primary'}
          className={classes.progress}
        />
      )}
    </LoadingButton>
  )
}

CooldownButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  disabled: PropTypes.bool,
  buttonProps: PropTypes.object,
  delay: PropTypes.number,
}

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
  },
}))

export default CooldownButton
