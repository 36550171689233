import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { LoadingButton } from '@mui/lab'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'

import CooldownButton from '../../components/CooldownButton'

import { useTaskGroup } from '../taskGroup.context'
import { useTask } from '../../Task/task.context'

const NavigationNextButton = ({ onClick, ...props }) => {
  const { t } = useTranslation('NAVIGATION')

  const { gotToNextTask, isLastTask, CustomNextButton } = useTaskGroup()

  const { task, isCompletable, isLoading, complete: completeTask } = useTask()

  const handleNextButtonClick = async () => {
    const next = async () => {
      await completeTask()
      await gotToNextTask()
    }

    if (typeof onClick === 'function') {
      return onClick(next)
    }

    await next()
  }

  const disabled = !isCompletable
  const color = isLastTask ? 'success' : 'primary'
  const label = isLastTask ? t('COMPLETE_BUTTON') : t('NEXT_BUTTON')
  const icon = isLastTask ? null : <NavigateNextIcon />
  const dataTestid = isLastTask
    ? 'navigation-complete-button'
    : 'navigation-next-button'

  if (CustomNextButton && React.isValidElement(CustomNextButton)) {
    return React.cloneElement(CustomNextButton, {
      completeTask,
      isCompletable,
      isLoading,
      disabled,
      color,
      label,
      icon,
      next: handleNextButtonClick,
    })
  }

  if (task?.config.coolDownDelay) {
    return (
      <CooldownButton
        color={color}
        data-testid="navigation-next-cooldown-button"
        delay={task.config.coolDownDelay}
        disabled={disabled}
        loading={isLoading}
        onClick={handleNextButtonClick}
        variant="contained"
        {...props}
      >
        {label} <NavigateNextIcon />
      </CooldownButton>
    )
  }

  return (
    <LoadingButton
      color={color}
      data-testid={dataTestid}
      disabled={isLoading || disabled}
      loading={isLoading}
      onClick={handleNextButtonClick}
      variant="contained"
      {...props}
    >
      {label} {icon}
    </LoadingButton>
  )
}

NavigationNextButton.propTypes = {
  nextLabel: PropTypes.string,
  completeLabel: PropTypes.string,
  onClick: PropTypes.func,
}

export default NavigationNextButton
