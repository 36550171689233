import { datatype } from 'faker'

export const returnTask = {
  _id: datatype.uuid(),
  action: 'return_task',
  comment: 'Comment from admin',
  agent_comment: 'Comment from agent',
  index: 0,
  state: 'created',
  payload: {
    initial_product_count_to_return: 12,
    product: {
      store_shelf_group_id: '0000000000SHELF_GROUP_ID',
      section: 'Un petit creux ?',
      shelf_id: 'S2-C1-L7',
      capacity: 450,
      capacity_unit: 'g',
      name: 'Nutella - Biscuits fourrés',
      product_image_url:
        'https://storebox-api-public-production.s3.eu-west-3.amazonaws.com/product_images/5ed0bd65495f8b00042ab480.jpeg',
    },
  },
  warehouse_id: '000000000000WAREHOUSE_ID',
  read_only: false,
  //TODO: price should be in return payload
  display: {
    price: '€ 2.80',
  },
}

export const makeReturn = ({ _id, index, state } = {}) => ({
  ...returnTask,
  _id: _id || returnTask._id,
  index: index || returnTask.index,
  state: state || returnTask.state,
})
